import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GeneralButton from "../../components/button/GeneralButton";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CustomTabList from "../../components/TabList/CustomTabList";
import Layout from "../../components/layout/layout";
import EmployeeBasicDetails from "./employeeBasicDetails";
import { BUTTON_COLOUR } from "../../constants/Constant";
import EmployeeOtherDetails from "./EmployeeOtherDetails";
import PageLoader from "../../components/Loader/PageLoader";
import {
  CreateEmployee,
  GetAssignedDesignation,
  GetDepartment,
  GetDesignation,
  GetGender,
} from "../../api/ApiService";
import NotifyMessage from "../../components/NotifyMessage/NotifyMessage";

const AddEmployee = () => {
  const [selectedTab, setSelectedTab] = useState("basicDetails");
  const [genderData, setGenderData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [assignedDesignationData, setAssignedDesignationData] = useState([]);

  const [genderId, setGenderId] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  // console.log("department id is", departmentId);
  const [designationIds, setDesignationIds] = useState(null);
  // console.log("designation ids is ", designationIds);
  const [assignedDesignationIds, setAssignedDesignationIds] = useState(null);
  // console.log("assignedDesignationIds is ", assignedDesignationIds);
  const [roleIds, setRoleIds] = useState(null);
  // console.log("roleIds is ", roleIds);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    const isValid =
      basicDetails.firstName &&
      basicDetails.lastName &&
      basicDetails.email &&
      basicDetails.mobileNumber &&
      /^[a-zA-Z\s]+$/.test(basicDetails.firstName) &&
      /^[a-zA-Z\s]+$/.test(basicDetails.lastName) &&
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        basicDetails.email
      ) &&
      /^\d{10}$/.test(basicDetails.mobileNumber);

    setIsFormValid(isValid);
  };

  const handleFormValidityChange = (isValid) => {
    setIsFormValid(isValid);
  };

  const [basicDetails, setBasicDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    gender: "",
  });

  useEffect(() => {
    validateForm();
  }, [basicDetails]);
  const [notify, setNotify] = useState({ message: "", type: "" });

  const tabList = [
    {
      label: "Basic Details",
      value: "basicDetails",
    },
    {
      label: "Other Information",
      value: "otherInformation",
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    fetchGenderData();
    fetchDepartmentData();
    fetchDesignationData();
    fetchAssignedDesignationData();
  }, []);

  const fetchGenderData = async () => {
    try {
      const response = await GetGender();
      if (response && response.status === 200) {
        setGenderData(response.data);
      } else {
        console.error("Failed to fetch gender data");
      }
    } catch (err) {
      console.error("Error fetching gender data:", err);
    }
  };

  const fetchDepartmentData = async () => {
    try {
      const response = await GetDepartment();
      if (response && response.status === 200) {
        setDepartmentData(response.data);
      } else {
        console.error("Failed to fetch department data");
      }
    } catch (err) {
      console.error("Error fetching department data:", err);
    }
  };

  const fetchDesignationData = async () => {
    try {
      const response = await GetDesignation();
      if (response && response.status === 200) {
        setDesignationData(response.data);
      } else {
        console.error("Failed to fetch designation data");
      }
    } catch (err) {
      console.error("Error fetching designation data:", err);
    }
  };

  const fetchAssignedDesignationData = async () => {
    try {
      const response = await GetAssignedDesignation();
      if (response && response.status === 200) {
        setAssignedDesignationData(response.data);
      } else {
        console.error("Failed to fetch assigned designation data");
      }
    } catch (err) {
      console.error("Error fetching assigned designation data:", err);
    }
  };

  const createEmployeeHandler = async () => {
    setLoading(true);
    const flattenArray = (arr) => {
      return arr.flat(Infinity).filter(Boolean);
    };

    const employeeData = {
      firstName: basicDetails.firstName,
      lastName: basicDetails.lastName,
      email: basicDetails.email,
      mobileNumber: basicDetails.mobileNumber,
      password: "",
      roleIds: roleIds,
      fieldIds: [
        genderId,
        departmentId,
        ...flattenArray(designationIds),
        ...flattenArray(assignedDesignationIds),
      ],
    };

    try {
      const response = await CreateEmployee(employeeData);
      if (response && response.status === 201) {
        setNotify({
          message: "Employee created successfully",
          type: "success",
        });
        setLoading(false);
        setTimeout(() => navigate("/employeeList"), 2000);
      } else {
        setNotify({ message: "Failed to create employee", type: "error" });
        setLoading(false);
      }
    } catch (err) {
      console.error("Error creating employee:", err);
      setNotify({ message: "Error creating employee", type: "error" });
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const submitBtnProps = {
    name: selectedTab === "basicDetails" ? "Next" : "Save",
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => {
      if (selectedTab === "basicDetails") {
        setSelectedTab("otherInformation");
      } else {
        createEmployeeHandler();
      }
    },
    disabled: selectedTab === "basicDetails" && !isFormValid,
  };

  const cancelBtnProps = {
    name: "Cancel",
    borderRadius: "30px",
    width: "220px",
    height: "48px",
    marginRight: "16px",
    color: "grey",
    border: "1px solid grey",
    buttonHandler: () => {
      navigate("/employeeList");
    },
  };

  return (
    <div>
      <PageLoader loadingStatus={loading} />
      {notify.message && (
        <NotifyMessage message={notify.message} type={notify.type} />
      )}
      <Layout>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "85%",
          }}
        >
          <Box>
            <TabContext value={selectedTab}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
                      borderRadius: "10px",
                      width: "99%",
                      marginBottom: "1rem",
                      marginLeft: "5px",
                      marginRight: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingRight: "16px",
                    }}
                  >
                    <CustomTabList
                      tabs={tabList}
                      onChange={handleTabChange}
                      aria-label="form tabs"
                      variant="scrollable"
                      scrollButtons={false}
                      allowScrollButtonsMobile
                      sx={{
                        display: "flex",
                        flexWrap: "nowrap",
                        "& .MuiTab-root": {
                          minWidth: "auto",
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TabPanel
                      value="basicDetails"
                      sx={{
                        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
                        borderRadius: "25px",
                        width: "96%",
                        height: "63vh",
                        overflowY: "auto",
                      }}
                    >
                      <EmployeeBasicDetails
                        genderData={genderData}
                        setGenderId={setGenderId}
                        setBasicDetails={setBasicDetails}
                        basicDetails={basicDetails}
                        onFormValidityChange={handleFormValidityChange}
                      />
                    </TabPanel>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TabPanel
                      value="otherInformation"
                      sx={{
                        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
                        borderRadius: "25px",
                        width: "96%",
                        height: "63vh",
                        overflowY: "auto",
                      }}
                    >
                      <EmployeeOtherDetails
                        departmentData={departmentData}
                        designationData={designationData}
                        assignedDesignationData={assignedDesignationData}
                        setDepartmentId={setDepartmentId}
                        setDesignationIds={setDesignationIds}
                        setRoleIds={setRoleIds}
                        setAssignedDesignationIds={setAssignedDesignationIds}
                        departmentId={departmentId}
                        designationIds={designationIds}
                        assignedDesignationIds={assignedDesignationIds}
                      />
                    </TabPanel>
                  </div>
                </Box>
              </Box>
            </TabContext>
          </Box>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
            padding: "20px",
            gap: "20px",
          }}
        >
          <GeneralButton {...cancelBtnProps} />
          <GeneralButton {...submitBtnProps} />
        </div>
      </Layout>
    </div>
  );
};

export default AddEmployee;
