export const project_list = {
  data: {
    projects: [
      {
        consultancyNo: "IITP-CS-2024-001",
        Date: "12-06-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Computer Science and Engineering",
        status: "Pending DR(R&D)",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        overheadAmount: "3000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",
        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          instituteShare_1: "40000",
          isGstAvailable: "Yes",
          gstNumber: "24AAACC1206DIZM",
          sanctionAmount: "1200303",
          gst: "7689",

          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
          otherExp: "5689",
        },
        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-Mar-2024",
          deputyRegistrar: "Pending",
          deputyRegistrarDt: null,
          associateDean: null,
          associateDeanDt: null,
          director: null,
          directorDt: null,
        },
      },
      {
        consultancyNo: "IITP-CS-2024-002",
        Date: "23-05-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Electrical Engineering",
        status: "Pending Dean(R&D)",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",

        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          isGstAvailable: "Yes",
          gstNumber: "24AAACC1206DIZM",
          sanctionAmount: "1200303",
          gst: "7689",
          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
        },

        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-Mar-2024",
          deputyRegistrar: "Approved",
          deputyRegistrarDt: "04-Mar-2024",
          associateDean: "Pending",
          associateDeanDt: null,
          director: null,
          directorDt: null,
        },
      },
      {
        consultancyNo: "IITP-CS-2024-003",
        Date: "23-05-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Mechanical Engineering",
        status: "Pending Director",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",

        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          isGstAvailable: "Yes",
          gstNumber: "24AAACC1206DIZM",
          sanctionAmount: "1200303",
          gst: "7689",
          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
        },

        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-Mar-2024",
          deputyRegistrar: "Approved",
          deputyRegistrarDt: "04-Mar-2024",
          associateDean: "Approved",
          associateDeanDt: "05-Mar-2024",
          director: "Pending",
          directorDt: null,
        },
      },
      {
        consultancyNo: "IITP-CS-2024-004",
        Date: "23-05-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Civil and Environmental Engineering",
        status: "Approved",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",

        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          isGstAvailable: "Yes",
          gstNumber: "24AAACC1206DIZM",
          sanctionAmount: "1200303",
          gst: "7689",
          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
        },

        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-Mar-2024",
          deputyRegistrar: "Approved",
          deputyRegistrarDt: "04-Mar-2024",
          associateDean: "Approved",
          associateDeanDt: "05-Mar-2024",
          director: "Approved",
          directorDt: "06-Mar-2024",
        },
      },
      {
        consultancyNo: "IITP-CS-2024-005",
        Date: "23-05-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Chemical and Biochemical Engineering",
        status: "Rejected",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",

        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          isGstAvailable: "Yes",
          gstNumber: "24AAACC1206DIZM",
          sanctionAmount: "1200303",
          gst: "7689",
          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
        },

        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-Mar-2024",
          deputyRegistrar: "Approved",
          deputyRegistrarDt: "04-Mar-2024",
          associateDean: "Approved",
          associateDeanDt: "05-Mar-2024",
          director: "Rejected",
          directorDt: "06-Mar-2024",
          reason: "Due to very high consultancy amount that has been rejected.",
        },
      },
      {
        consultancyNo: "IITP-CS-2024-006",
        Date: "23-05-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Metallurgical and materials engineering",
        status: "Pending DR(R&D)",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",

        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          isGstAvailable: "Yes",
          gstNumber: "24AAACC1206DIZM",
          sanctionAmount: "1200303",
          gst: "7689",
          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
        },

        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-Mar-2024",
          deputyRegistrar: "Pending",
          deputyRegistrarDt: null,
          associateDean: null,
          associateDeanDt: null,
          director: null,
          directorDt: null,
        },
      },
      {
        consultancyNo: "IITP-CS-2024-007",
        Date: "23-05-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Mechanical Engineering",
        status: "Approved",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",

        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          isGstAvailable: "Yes",
          gstNumber: "24AAACC1206DIZM",
          sanctionAmount: "1200303",
          gst: "7689",
          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
        },

        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-Mar-2024",
          deputyRegistrar: "Approved",
          deputyRegistrarDt: "04-Mar-2024",
          associateDean: "Approved",
          associateDeanDt: "05-Mar-2024",
          director: "Approved",
          directorDt: "06-Mar-2024",
        },
      },
      {
        consultancyNo: "IITP-CS-2024-008",
        Date: "23-05-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Mechanical Engineering",
        status: "Rejected",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",

        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          gst: "7689",
          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
        },

        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-Mar-2024",
          deputyRegistrar: "Approved",
          deputyRegistrarDt: "04-Mar-2024",
          associateDean: "Approved",
          associateDeanDt: "05-Mar-2024",
          director: "Rejected",
          directorDt: "06-Mar-2024",
          reason: "Due to very high consultancy amount that has been rejected.",
        },
      },
      {
        consultancyNo: "IITP-CS-2024-009",
        Date: "23-05-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Electrical Engineering",
        status: "Pending DR(R&D)",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",

        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          isGstAvailable: "Yes",
          gstNumber: "24AAACC1206DIZM",
          sanctionAmount: "1200303",
          gst: "7689",
          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
        },

        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-Mar-2024",
          deputyRegistrar: "Pending",
          deputyRegistrarDt: null,
          associateDean: null,
          associateDeanDt: null,
          director: null,
          directorDt: null,
        },
      },
      {
        consultancyNo: "IITP-CS-2024-010",
        Date: "23-05-2024",
        NameOfProject: "Rental car web app",
        GrossAmount: "195000",
        DepartmentName: "Computer Science and Engineering",
        status: "Pending Director",
        nameOfClient: "Ola",
        nameOfConsultantInCharge: "Abhishek",
        typeOfClient: "Gov",
        completionDate: "03-09-2024",
        noInstituteFacilities: true,
        consultancyFee: "150000",
        instituteShare_1: "40000",
        gst: "5000",
        totalExpenditure: "195000",
        recommendation: "true",
        otherConsultant: "shekar",
        upload: "gst.pdf",

        financialDetails: {
          consultancyFee: "678",
          instituteShare_0: "66",
          isGstAvailable: "Yes",
          gstNumber: "24AAACC1206DIZM",
          sanctionAmount: "1200303",
          gst: "7689",
          totalExpenditure: "97799",
          recommendation: true,
          consultancyProject: ["noInstituteFacilities", "instituteFacilities"],
        },

        closure_form_details: {
          closure_details: {
            titleOfConsultancyProject: "noInstituteFacilities",
            nameOfClientAndAddress: "Ola",
            nameOfConsultantInCharge: "Abhishek",
            nameofOtherConsultant: "shekar",
            typeOfClient: "Gov",
            grossConsultantCharges: "97799",
            dateOfCommencementOfWork: "12-06-2024",
            dateOfCompletationOfWork: "03-09-2024",
          },
          account_details: {
            nameOfBank: "SBI",
            nameofAccountHolder: "Abhishek",
            acccountNumber: "367889309009000",
            ifscCode: "SBIN007485",
          },
        },
        approvalComment: {
          principalConsultant: "Abbhishek",
          principalConsultantDt: "02-Mar-2024",
          asstRegistrar: "Approved",
          asstRegistrarDt: "03-05-2024",
          deputyRegistrar: "Approved",
          deputyRegistrarDt: "04-05-2024",
          associateDean: "Approved",
          associateDeanDt: "05-05-2024",
          director: "Pending",
          directorDt: null,
        },
      },
    ],
  },
  page: 0,
  pageSize: 10,
  totalCount: 10,
  totalPages: 1,
  message: "Project list",
};

export const category_wise = [
  {
    label: "Equipment",
    value: "EQUIPMENT",
  },
  {
    label: "Consumables",
    value: "CONSUMABLES",
  },
  {
    label: "Travel",
    value: "TRAVEL",
  },
  {
    label: "Contingency ",
    value: "CONTINGENCY ",
  },
  {
    label: "Institute overhead",
    value: "INSTITUTE_OVERHEAD",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

export const addList = [
  {
    label: "Basic Details",
    value: "basicDetails",
  },
  {
    label: "Financial Details",
    value: "financialDetails",
  },
];

export const fullTabList = [
  {
    label: "Basic Details",
    value: "basicDetails",
  },
  {
    label: "Financial Details",
    value: "financialDetails",
  },
  {
    label: "Approval Comment",
    value: "approvalComment",
  },
];

export const employeeList = {
  data: {
    employeeDetails: [
      {
        EmployeeName: "Suraj Shing",
        Gender: "Male",
        EmailId: "suraj.Shing@iitp.ac.in",
        EmployeeId: "IITP_PATNA_EMP_021",
        MobileNumber: "7878787890",
        BasicDetails: {
          firstName: "Suraj",
          lastName: "Shing",
          gender: "Male",
          email: "suraj.Shing@iitp.ac.in",
          employeeId: "IITP_PATNA_EMP_022",
          mobileNumber: "7878787878",
        },
        officialInformation: {
          departmentName: "Department of EE",
          degree: "B.tech",
          designation: "Associate Prsfessor",
          departmentAllocation: "Hostel Admin",
          hostelName: "Aryabhat Hostel B1",
        },
      },
      {
        EmployeeName: "Suraj Suman",
        Gender: "Male",
        EmailId: "jhon.doe@iitp.ac.in",
        EmployeeId: "IITP_PATNA_EMP_021",
        MobileNumber: "7878787809",
        BasicDetails: {
          firstName: "jhon",
          lastName: "doe",
          gender: "Male",
          email: "suraj.suman@iitp.ac.in",
          employeeId: "IITP_PATNA_EMP_022",
          mobileNumber: "7878787878",
        },
        officialInformation: {
          departmentName: "Department of EE",
          degree: "B.tech",
          designation: "Associate Prsfessor",
          departmentAllocation: "Hostel Admin",
          hostelName: "Aryabhat Hostel B1",
        },
      },
      {
        EmployeeName: "Jhon Doe",
        Gender: "Male",
        EmailId: "suraj.suman@iitp.ac.in",
        EmployeeId: "IITP_PATNA_EMP_022",
        MobileNumber: "7878787878",
        BasicDetails: {
          firstName: "Suraj",
          lastName: "Suman",
          gender: "Male",
          email: "suraj.suman@iitp.ac.in",
          employeeId: "IITP_PATNA_EMP_022",
          mobileNumber: "7878787878",
        },
        officialInformation: {
          departmentName: "Department of EE",
          degree: "B.tech",
          designation: "Associate Prsfessor",
          departmentAllocation: "Hostel Admin",
          hostelName: "Aryabhat Hostel B1",
        },
      },
      {
        EmployeeName: "Foo Bar",
        Gender: "Male",
        EmailId: "foo.bar@iitp.ac.in",
        EmployeeId: "IITP_PATNA_EMP_025",
        MobileNumber: "7878787878",
        BasicDetails: {
          firstName: "Foo",
          lastName: "Bar",
          gender: "Male",
          email: "suraj.suman@iitp.ac.in",
          employeeId: "IITP_PATNA_EMP_022",
          mobileNumber: "7878787878",
        },
        officialInformation: {
          departmentName: "Department of EE",
          degree: "B.tech",
          designation: "Associate Prsfessor",
          departmentAllocation: "Hostel Admin",
          hostelName: "Aryabhat Hostel B1",
        },
      },
      {
        EmployeeName: "Suraj Suman",
        Gender: "Male",
        EmailId: "suraj.suman@iitp.ac.in",
        EmployeeId: "IITP_PATNA_EMP_022",
        MobileNumber: "7878787878",
        BasicDetails: {
          firstName: "Suraj",
          lastName: "Suman",
          gender: "Male",
          email: "suraj.suman@iitp.ac.in",
          employeeId: "IITP_PATNA_EMP_022",
          mobileNumber: "7878787878",
        },
        officialInformation: {
          departmentName: "Department of EE",
          degree: "B.tech",
          designation: "Associate Prsfessor",
          departmentAllocation: "Hostel Admin",
          hostelName: "Aryabhat Hostel B1",
        },
      },
      {
        EmployeeName: "Suraj Suman",
        Gender: "Male",
        EmailId: "suraj.suman@iitp.ac.in",
        EmployeeId: "IITP_PATNA_EMP_022",
        MobileNumber: "7878787878",
        BasicDetails: {
          firstName: "Suraj",
          lastName: "Suman",
          gender: "Male",
          email: "suraj.suman@iitp.ac.in",
          employeeId: "IITP_PATNA_EMP_022",
          mobileNumber: "7878787878",
        },
        officialInformation: {
          departmentName: "Department of EE",
          degree: "B.tech",
          designation: "Associate Prsfessor",
          departmentAllocation: "Hostel Admin",
          hostelName: "Aryabhat Hostel B1",
        },
      },
      {
        EmployeeName: "Suraj Suman",
        Gender: "Male",
        EmailId: "suraj.suman@iitp.ac.in",
        EmployeeId: "IITP_PATNA_EMP_022",
        MobileNumber: "7878787878",
        BasicDetails: {
          firstName: "Suraj",
          lastName: "Suman",
          gender: "Male",
          email: "suraj.suman@iitp.ac.in",
          employeeId: "IITP_PATNA_EMP_022",
          mobileNumber: "7878787878",
        },
        officialInformation: {
          departmentName: "Department of EE",
          degree: "B.tech",
          designation: "Associate Prsfessor",
          departmentAllocation: "Hostel Admin",
          hostelName: "Aryabhat Hostel B1",
        },
      },
      {
        EmployeeName: "Suraj Suman",
        Gender: "Male",
        EmailId: "suraj.suman@iitp.ac.in",
        EmployeeId: "IITP_PATNA_EMP_022",
        MobileNumber: "7878787878",
        BasicDetails: {
          firstName: "Suraj",
          lastName: "Suman",
          gender: "Male",
          email: "suraj.suman@iitp.ac.in",
          employeeId: "IITP_PATNA_EMP_022",
          mobileNumber: "7878787878",
        },
        officialInformation: {
          departmentName: "Department of EE",
          degree: "B.tech",
          designation: "Associate Prsfessor",
          departmentAllocation: "Hostel Admin",
          hostelName: "Aryabhat Hostel B1",
        },
      },
      {
        EmployeeName: "Suraj Suman",
        Gender: "Male",
        EmailId: "suraj.suman@iitp.ac.in",
        EmployeeId: "IITP_PATNA_EMP_022",
        MobileNumber: "7878787878",
        BasicDetails: {
          firstName: "Suraj",
          lastName: "Suman",
          gender: "Male",
          email: "suraj.suman@iitp.ac.in",
          employeeId: "IITP_PATNA_EMP_022",
          mobileNumber: "7878787878",
        },
        officialInformation: {
          departmentName: "Department of EE",
          degree: "B.tech",
          designation: "Associate Prsfessor",
          departmentAllocation: "Hostel Admin",
          hostelName: "Aryabhat Hostel B1",
        },
      },
    ],
  },
  page: 0,
  pageSize: 10,
  totalCount: 10,
  totalPages: 1,
  message: "Employee List",
};

export const GREEN = "#38D06D";
export const BLUE = "#6A71FD";
export const YELLOW = "#DD8118";
export const RED = "#E35082";

export const data = [
  {
    id: 1,
    projectName: "Rentals",
    projectManager: "Abhishek",
    dueDate: "03-05-2025",
    status: "Completed",
    Progress: "",
    colorCode: GREEN,
    progress: [
      { value: 70, name: "70%" },
      { value: 30, name: "30%" },
    ],
  },
  {
    id: 2,
    projectName: "Android development",
    projectManager: "Abhishek",
    dueDate: "03-05-2025",
    status: "On Track",
    Progress: "",
    colorCode: BLUE,
    progress: [
      { value: 85, name: "85%" },
      { value: 15, name: "15%" },
    ],
  },
  {
    id: 3,
    projectName: "Agile Project",
    projectManager: "Abhishek",
    dueDate: "03-05-2025",
    status: "Delayed",
    Progress: "",
    colorCode: YELLOW,
    progress: [
      { value: 50, name: "50%" },
      { value: 50, name: "50%" },
    ],
  },
  {
    id: 4,
    projectName: "Media Channel",
    projectManager: "Abhishek",
    dueDate: "03-05-2025",
    status: "At Risk",
    Progress: "",
    colorCode: RED,
    progress: [
      { value: 30, name: "30%" },
      { value: 70, name: "70%" },
    ],
  },
];

export const mileStoneData = [
  {
    id: "Rentals_1",
    projectName: "Rentals",
    milestone: "Social Media Mark",
    dueDate: "03-05-2025",
    status: "Completed",
    Progress: "",
    colorCode: GREEN,
    progress: [
      { value: 70, name: "70%" },
      { value: 30, name: "30%" },
    ],
  },
  {
    id: "android_development_2",
    projectName: "Android development",
    milestone: "HR Activities",
    dueDate: "03-05-2025",
    status: "On Track",
    Progress: "",
    colorCode: BLUE,
    progress: [
      { value: 85, name: "85%" },
      { value: 15, name: "15%" },
    ],
  },
  {
    id: "agile_project_3",
    projectName: "Agile Project",
    milestone: "Software Architecture",
    dueDate: "03-05-2025",
    status: "Delayed",
    Progress: "",
    colorCode: YELLOW,
    progress: [
      { value: 50, name: "50%" },
      { value: 50, name: "50%" },
    ],
  },
  {
    id: "media_channel_4",
    projectName: "Media Channel",
    milestone: "Media Channel",
    dueDate: "03-05-2025",
    status: "At Risk",
    Progress: "",
    colorCode: RED,
    progress: [
      { value: 30, name: "30%" },
      { value: 70, name: "70%" },
    ],
  },
];
