import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/layout";
import { Box, Grid, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../projectList/projectList";
import { BUTTON_COLOUR } from "../../constants/Constant";
import PageLoader from "../../components/Loader/PageLoader";
import plus from "../../assets/images/plus-sm.png";
import editIcon from "../../assets/images/edit.png";
import deleteIcon from "../../assets/images/delete.png";
import GeneralButton from "../../components/button/GeneralButton";
import DataGridTable from "../../components/DataGridTable";
import view from "../../assets/images/view.png";
import ConfirmationPopUp from "../../deletePopUp/deletePopUp";
import MobileViewTable from "../../components/mobileViewComponent/MobileViewTable";
import { Input } from "antd";
import { DeleteEmployee, GetAllEmployeeList } from "../../api/ApiService";
import NotifyMessage from "../../components/NotifyMessage/NotifyMessage";
import { styled } from "@mui/system";

const NoRowsOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  color: "",
  fontSize: 16,
}));
const EmployeeList = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("jwtToken");
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [notify, setNotify] = useState({ message: "", type: "" });
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    page: 1,
    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (token) {
          const response = await GetAllEmployeeList(token);
          if (response.status === 200) {
            const transformedData = response.data.data.map((item) => ({
              id: item.id,
              employeeName: `${item?.firstName} ${item?.lastName}`,
              email: item?.email,
              role: item?.role?.description,
              roleName: item?.role?.roleName,
              status: item?.isActive ? "Active" : "Inactive",
              createdAt: new Date(item.createdAt).toLocaleDateString(),
            }));
            setEmployeeData(transformedData);
          } else {
            console.error("Failed to fetch employee data");
          }
        } else {
          console.error("Token is missing");
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleEdit = (params) => {
    navigate(`/EditEmployee/${params.row.id}`, {
      state: {
        action: "edit",
        data: params?.row,
      },
    });
  };

  const handleView = (params) => {
    navigate(`/viewEmployee/${params.row.id}`, {
      state: {
        action: "view",
        id: params.row.id,
        data: params?.row,
      },
    });
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedEmployeeId(id);
    setIsOpen(true);
  };

  const handleDelete = async () => {
    if (selectedEmployeeId === null) return;

    try {
      const response = await DeleteEmployee(selectedEmployeeId);
      if (response.status === 200) {
        setNotify({
          message: "Employee deleted successfully",
          type: "success",
        });
        const updatedEmployeeData = employeeData.filter(
          (emp) => emp.id !== selectedEmployeeId
        );
        setEmployeeData(updatedEmployeeData);
      } else {
        setNotify({
          message: "Failed to delete employee",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
      setNotify({
        message: "Error deleting employee",
        type: "error",
      });
    } finally {
      setIsOpen(false);
    }
  };

  const handleAddForm = () => {
    navigate("/employee", {
      state: {
        action: "addForm",
      },
    });
  };

  const columns = [
    {
      field: "employeeName",
      headerName: "Employee Name",
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        const isSuperAdmin = params.row.roleName === "SUPER_ADMIN";

        return (
          <div>
            <IconButton aria-label="edit" onClick={() => handleEdit(params)}>
              <img src={editIcon} alt="Edit" />
            </IconButton>
            <IconButton aria-label="view" onClick={() => handleView(params)}>
              <img src={view} alt="View" />
            </IconButton>
            {!isSuperAdmin && (
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteConfirmation(params.row.id)}
              >
                <img src={deleteIcon} alt="Delete" />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  const mobileProps = {
    data: employeeData,
    handleEdit: handleEdit,
    handleOnView: handleView,
    handleOnDelete: handleDeleteConfirmation,
    role: role,
  };

  return (
    <Layout componentName={"Employee List"}>
      <Box sx={{ height: "88vh", overflow: "auto" }}>
        <PageLoader loadingStatus={loading} />
        {notify.message && (
          <NotifyMessage message={notify.message} type={notify.type} />
        )}
        {!loading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={styles.search_container}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    height: "52px",
                  }}
                >
                  <Input
                    style={{ width: "300px" }}
                    placeholder="Search By Employee Name "
                  />
                  <GeneralButton
                    name={"EMPLOYEE"}
                    type={"submit"}
                    color={"#f8fafc"}
                    borderRadius={"30px"}
                    backgroundColor={BUTTON_COLOUR}
                    icons={plus}
                    width={"158px"}
                    height={"48px"}
                    buttonHandler={() => handleAddForm()}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                  overflowX: "auto",
                  "& .MuiDataGrid-root": {
                    minWidth: "100%",
                    height: "80vh",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "white",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "auto",
                  },
                }}
              >
                {employeeData.length === 0 ? (
                  <span>No results found for PI.</span>
                ) : (
                  <DataGridTable
                    rows={employeeData}
                    columns={columns}
                    showOrHide={false}
                    pageInfo={pageInfo}
                    setPageInfo={setPageInfo}
                    noRowsOverlay={
                      <NoRowsOverlay>
                        No projects have been created
                      </NoRowsOverlay>
                    }
                    itemRender={(_, type, originalElement) => {
                      if (type === "prev") return <a>Previous</a>;
                      if (type === "next") return <a>Next</a>;
                      return originalElement;
                    }}
                  />
                )}
              </Box>
              <ConfirmationPopUp
                title={"Confirmation"}
                content={"Do you really want to Delete?"}
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
                handleYes={handleDelete}
                handleNo={() => setIsOpen(false)}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Layout>
  );
};

export default EmployeeList;
