import React, { useRef } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import Layout from "../../components/layout/layout";
import iitPatnaLogo from "../../../src/assets/images/iit_patna_logo.jpg";
import "./SuccessUi.css";
function SuccessPDF() {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Layout componentName="Consultancy Project Details">
      <Paper elevation={3} sx={{ padding: 3, margin: 2 }} ref={componentRef}>
        <Box textAlign="center" mb={2} display="flex" alignItems="center">
          <Box flexShrink={0} textAlign="left">
            <img
              src={iitPatnaLogo}
              alt="IIT Patna Logo"
              style={{ height: 100 }}
            />
          </Box>
          <Box flexGrow={1} textAlign="center">
            <Typography variant="h6" component="h2">
              INDIAN INSTITUTE OF TECHNOLOGY PATNA
            </Typography>
            <Typography variant="subtitle1">
              Kanpa Road, Bihta, Patna - 801106, India
            </Typography>
            <Typography variant="body2" mt={2}>
              Ref. No.: IITP/CONS/PROJ
            </Typography>
            <Typography variant="body2">Date: 18.04.2024</Typography>
          </Box>
        </Box>
        <Divider />
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            DETAILS OF THE CONSULTANCY PROJECT
          </Typography>
          <List>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>1. Title of the Consultancy Project:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Project Title
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>2. Name of the client</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Client Name, Client Address
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>3. Client Address:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Client Address
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>4. Type of Client (Industry/Government):</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Client Type
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>5. Name(s) of other Consultant(s):</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Consultant Names
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>
                      6. Name(s) of the Department/Center, where research is to
                      be performed:
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Department/Center Names
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>7.Name of the Consultant-in-Charge: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Consultant Name
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>8. Upload Project documents:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Uploaded Document Names
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>9. Date of commencement of Work:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Start Date
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <strong>10. Expected date of completion of Work:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    End Date
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            <strong>
              1. THE CONSULTANCY PROJECT INVOLVES (Tick appropriate one)
            </strong>
          </Typography>
          <List>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    1.1 Use of no Institute facilities
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    1.2 Use of Institute facilities such as Laboratory and
                    computer
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    1.3 Primarily testing & Interpretation
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="body1" gutterBottom>
                <strong>2. Is GST number available?:</strong>
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Typography variant="body1">Yes/No</Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="body1" gutterBottom>
                <strong>3. Total sanction amount:</strong>
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Typography variant="body1">Amount</Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            <strong>4. Consultancy Fee Details:</strong>
          </Typography>
          <List>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">4.1 Consultancy Fee:</Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Amount
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">4.2 Overhead amount:</Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Amount
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    4.3 GST (18% of Consultancy Fee):
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Amount
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    4.4 Gross Consultancy Charges (4.1 + 4.2 + 4.3):
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Amount
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            <strong>5. SHARING OF THE CONSULTANCY FEE:</strong>
          </Typography>
          <List>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    5.1 Project Expenditure:
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Amount
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    5.2 Institute's Share (% of 5.1):
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Percentage
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    5.3 Total Project Expenditure:
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="body1"
                    sx={{
                      marginRight: "-13px",
                    }}
                  >
                    Amount
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box mt={2} mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="body1" gutterBottom>
                <strong>RECOMMENDATION OF THE HOD/HOC/COORDINATOR:</strong>
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Typography variant="body1">Yes / No</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4} width="100%">
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} md={4}>
              <Box ml={{ xs: 0, sm: 2, md: 4, lg: 8 }} mb={4}>
                <Typography variant="body1" gutterBottom align="left">
                  <strong>Principal Consultant:</strong>
                </Typography>
              </Box>
              <Box ml={{ xs: 4, sm: 6, md: 12, lg: 16 }} mb={4}>
                <Typography variant="body1" gutterBottom align="left">
                  <strong>Assistant Registrar:</strong>
                </Typography>
              </Box>
              <Box ml={{ xs: 8, sm: 12, md: 20, lg: 24 }} mb={4}>
                <Typography variant="body1" gutterBottom align="left">
                  <strong>Deputy Registrar:</strong>
                </Typography>
              </Box>
              <Box ml={{ xs: 12, sm: 18, md: 28, lg: 32 }} mb={4}>
                <Typography variant="body1" gutterBottom align="left">
                  <strong>Associate Dean:</strong>
                </Typography>
              </Box>
              <Box ml={{ xs: 16, sm: 30, md: 30, lg: 48 }} mb={4}>
                <Typography variant="body1" gutterBottom align="left">
                  <strong>Director:</strong>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box display="flex" justifyContent="flex-end" marginTop="40px">
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrint}
          sx={{ bottom: 16, right: 16 }}
        >
          Print
        </Button>
      </Box>
    </Layout>
  );
}

export default SuccessPDF;
