import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/layout";
import { Box, Grid, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import closure from "../../assets/images/closure.png";
import styles from "./projectList.css";
import {
  APPROVING_ROLES,
  BUTTON_COLOUR,
  DELETE_ROLES,
} from "../../constants/Constant";
import plus from "../../assets/images/plus-sm.png";
import editIcon from "../../assets/images/edit.png";
import deleteIcon from "../../assets/images/delete.png";
import GeneralButton from "../../components/button/GeneralButton";
import DataGridTable from "../../components/DataGridTable";
import { project_list } from "../../constants/data";
import PageLoader from "../../components/Loader/PageLoader";
import ConfirmationPopUp from "../../components/conifrmationPopUp/confirmationPopUp";
import MobileViewTable from "../../components/mobileViewComponent/MobileViewTable";
import check from "../../assets/images/check.png";
import clipboard from "../../assets/images/clipboard.png";
import eye4 from "../../assets/images/eye4.png";
import {
  DeleteProjectByProjectId,
  GetAllProjectList,
  GetProjectListOnlyForProjectListByPi,
  GetProjectStatus,
  ProjectRejectByProjectId,
} from "../../api/ApiService";
import reject from "../../assets/images/reject.png";
import { GetDecodedToken } from "../../constants/StoredToken";
import RejectedPopUp from "../../components/rejectedPopUp/rejectedPopUp";
import NotifyMessage from "../../components/NotifyMessage/NotifyMessage";
import { styled } from "@mui/system";
import { MarginOutlined } from "@mui/icons-material";

const NoRowsOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  color: "",
  fontSize: 16,
}));

const ProjectList = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const decodeTokenIs = GetDecodedToken();
  // console.log("decode token is :->", decodeTokenIs);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const roleTypeIs = decodeTokenIs.roleType;
  const navigate = useNavigate();
  // console.log("get role in project list----->", localStorage.getItem("role"));
  const role = localStorage.getItem("role");
  const [token, setToken] = useState(localStorage.getItem("jwtToken"));
  const showEditBtn = role === "register" ? true : false;

  const showAddNewForm = role === "register" ? true : false;
  const showDeleteBtn = DELETE_ROLES.includes(role);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState({
    project: false,
  });
  const [showRejected, setShowRejected] = useState(false);
  const [reasonText, setReasonText] = useState();
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    page: 0,
    totalCount: 0,
    totalPages: 0,
  });

  const [notify, setNotify] = useState({ message: "", type: "" });
  const [apiResponse, setApiResponse] = useState([]);
  const shouldRenderButton = ["ARND", "DRND", "DIRECTOR", "DEAN"].includes(
    roleTypeIs
  );
  const [projectListPiData, setProjectListPiData] = useState([]);

  // const handleReject = () => {
  //   setShowRejected(true);
  // };

  const handleCloseRejected = () => {
    setShowRejected(false);
  };

  // const handleYes = () => {
  //   setShowRejected(false);
  //   setReasonText("");
  // };

  // const handleNo = () => {
  //   setShowRejected(false);
  //   setReasonText("");
  // };
  useEffect(() => {
    if (decodeTokenIs.roleType === "PI" && token) {
      fetchProjectListForRolePi();
    }
  }, [page, pageSize, token]);

  const fetchProjectListForRolePi = async (pageNo, size) => {
    setLoading(true);
    try {
      const response = await GetProjectListOnlyForProjectListByPi(
        pageNo || page,
        size || pageSize,
        token
      );

      if (response?.status === 200) {
        console.log("GetProjectListOnlyForProjectListByPi-->>", response);
        setProjectListPiData(response?.data?.data || []);
        setPageInfo({
          ...pageInfo,
          totalCount: response?.data?.totalElements || 0,
          totalPages: Math.ceil(
            (response?.data?.totalElements || 0) / pageSize
          ),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectList();
  }, []);

  const fetchProjectList = async (pageNo, size) => {
    setLoading(true);
    try {
      const response = await GetAllProjectList(
        pageNo || page,
        size || pageSize
      );

      if (response?.status === 200) {
        console.log("project list api response-->>", response);
        setApiResponse(response?.data?.data || []);

        console.log("response?.data?.data--->>", response?.data?.data);
        setPageInfo({
          ...pageInfo,
          totalCount: response?.data?.totalElements || 0,
          totalPages: Math.ceil(
            (response?.data?.totalElements || 0) / pageSize
          ),
        });
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (decodeTokenIs.roleType === "PI") {
      fetchProjectListForRolePi();
    }
  }, [page, pageSize]);

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const handleEdit = (params) => {
    // console.log("data--->", params?.row?.item);
    const projectId = params?.row?.item?.project_id;

    navigate("/edit", {
      state: {
        action: "edit",
        data: params?.row?.item,
        projectId: projectId,
      },
    });
  };

  const handleView = async (params) => {
    try {
      // console.log("data--->", params?.row?.item?.project_id);
      const id = params?.row?.item?.project_id;
      setLoading(true);
      const response = await GetProjectStatus(id);
      // console.log("response--->", response);

      const data = response?.data;
      setLoading(false);
      if (response.status === 200) {
        navigate("/view", {
          state: {
            action: "view",
            data: data,
            projectId: params?.row?.item?.project_id,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      // console.log("error in fetching view status details", error);
    }
  };

  const handleApprove = (params) => {
    navigate("/view", {
      state: {
        action: "approve",
        data: data,
        projectId: params?.row?.item?.project_id,
      },
    });
  };

  const handleReject = (params) => {
    setShowRejected(true);
    setIsOpen({ projectId: params?.row?.item?.project_id });
  };

  const handleDelete = async (projectId) => {
    try {
      const response = await DeleteProjectByProjectId(projectId);
      if (response.status === 200) {
        setNotify({
          message: "Project deleted successfully",
          type: "success",
        });
        fetchProjectList();
      } else {
        setNotify({
          message: "Failed to delete project",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting project:", error);
      setNotify({
        message: "Error deleting project",
        type: "error",
      });
    } finally {
      setIsOpen({ project: false });
    }
  };

  const handleNo = (name) => {
    // console.log("handle delete");
    setIsOpen({ name: false });
  };

  const handleYes = () => {
    setIsOpen({ name: false });
  };

  const handleClosure = (params) => {
    navigate(`/closureForm`, {
      state: {
        action: "approve",
        data: params?.row?.item,
      },
    });
  };

  const columns = [
    {
      field: "consultancyNo",
      headerName: "Consultancy No",
      filterable: false,
      flex: 1,
      minWidth: 150,
      maxWidth: 150,
      sortable: false,
    },
    // {
    //   field: "Date",
    //   headerName: "Date",
    //   flex: 1,
    //   minWidth: 200,
    //   maxWidth: 400,
    //   sortable: false,
    // },
    {
      field: "NameOfProject",
      headerName: "Name Of Project",
      flex: 1,
      minWidth: 200,
      maxWidth: 400,
      sortable: false,
    },
    {
      field: "client_name",
      headerName: "client_name",
      flex: 1,
      minWidth: 200,
      maxWidth: 400,
      sortable: false,
    },
    {
      field: "GrossAmount",
      headerName: "Gross Amount",
      filterable: false,
      flex: 1,
      minWidth: 150,
      maxWidth: 150,
      sortable: false,
    },
    {
      field: "DepartmentName",
      headerName: "Department Name",
      flex: 1,
      minWidth: 200,
      maxWidth: 400,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      sortable: false,
      renderCell: (params) => (
        <div>
          {roleTypeIs !== "PI" && params?.row?.status === "Approved" ? null : (
            <span>{params?.row?.status}</span>
          )}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      flex: 1,
      minWidth: 200,
      maxWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <div>
          {console.log("params value is", params.row)}
          {console.log("params value is", params.row.role_status)}

          {/* Approve Button i have do .....---> */}
          {(roleTypeIs === "ARND" ||
            roleTypeIs === "DRND" ||
            roleTypeIs === "DEAN" ||
            roleTypeIs === "DIRECTOR") && (
            <IconButton
              aria-label="approve"
              onClick={() => handleApprove(params)}
              sx={{
                backgroundColor:
                  params?.row?.role_status !== "Approved" &&
                  params?.row?.role_status !== "Rejected"
                    ? ""
                    : "#7babf7 !important",
              }}
              size={"small"}
              disabled={
                (roleTypeIs === "ARND" &&
                  params?.row?.role_status !== "PENDING_ARND") ||
                (roleTypeIs === "DRND" &&
                  params?.row?.role_status !== "PENDING_DARND") ||
                (roleTypeIs === "DEAN" &&
                  params?.row?.role_status !== "PENDING_DEAN") ||
                (roleTypeIs === "DIRECTOR" &&
                  params?.row?.role_status !== "APPROVED_DEAN")
              }
            >
              <img src={check} alt="approve" height={20} />
            </IconButton>
          )}

          {/* Edit Button ---.>>>> */}
          {roleTypeIs === "PI" ? (
            params.row.role_status === "REJECTED" ? (
              <IconButton
                aria-label="edit"
                onClick={() => handleEdit(params)}
                disabled={true}
              >
                <img src={editIcon} alt="Edit" height={20} />
              </IconButton>
            ) : (
              <IconButton aria-label="edit" onClick={() => handleEdit(params)}>
                <img src={editIcon} alt="Edit" height={20} />
              </IconButton>
            )
          ) : (
            ""
          )}

          {/* View Button --->>> */}
          {roleTypeIs === "PI" ||
          roleTypeIs === "ARND" ||
          roleTypeIs === "DRND" ||
          roleTypeIs === "DEAN" ||
          roleTypeIs === "DIRECTOR" ? (
            <IconButton aria-label="eye" onClick={() => handleView(params)}>
              <img src={eye4} alt="eye" height={20} />
            </IconButton>
          ) : (
            ""
          )}

          {/* Delete Button  --->>>*/}
          {roleTypeIs === "PI" && (
            <IconButton
              aria-label="delete"
              onClick={() => handleDelete(params.row.item.project_id)}
            >
              <img src={deleteIcon} alt="delete" height={18} width={16} />
            </IconButton>
          )}

          {/* Reject Button --->> */}
          {shouldRenderButton && (
            <IconButton
              aria-label="reject"
              sx={{
                backgroundColor:
                  params?.row?.role_status !== "Approved" &&
                  params?.row?.role_status !== "Rejected"
                    ? ""
                    : "#7babf7 !important",
              }}
              size={"small"}
              disabled={
                params?.row?.role_status === "Approved" ||
                params?.row?.role_status === "REJECTED" ||
                (roleTypeIs === "DRND" &&
                  params?.row?.role_status === "PENDING_ARND") ||
                params?.row?.role_status === "PENDING_DEAN" ||
                params?.row?.role_status === "null" ||
                (roleTypeIs === "DEAN" &&
                  params?.row?.role_status !== "APPROVED_DEAN") ||
                params?.row?.role_status === "PENDING_DARND" ||
                (roleTypeIs === "DIRECTOR" &&
                  params?.row?.role_status === "PENDING_ARND") ||
                params?.row?.role_status === "PENDING_DARND" ||
                params?.row?.role_status === "PENDING_DEAN"
              }
              onClick={() => handleReject(params)}
            >
              <img width={20} height={20} src={clipboard} alt="reject" />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  const getStatusMessage = (status) => {
    switch (status) {
      case "PENDING_ARND":
        return "Pending Assistant Registrar";
      case "PENDING_DARND":
        return "Pending Deputy Registrar";
      case "PENDING_DEAN":
        return "Pending Dean";
      case "APPROVED_DIRECTOR":
        return "Approved Director";
      case "APPROVED_DEAN":
        return "Approved Dean";
      case null:
        return "Initialize";
      default:
        return status || "Unknown Status";
    }
  };

  const data = apiResponse.map((item) => ({
    id: item?.project_id,
    consultancyNo: item?.project_id,
    NameOfProject: item?.title,
    client_name: item?.client_name,
    GrossAmount: item?.gross_amount,
    DepartmentName: item?.department,
    // status: item?.approval_status,
    status: getStatusMessage(item?.approval_status),
    item: item,
    role_status: item?.approval_status,
  }));

  const piData = projectListPiData.map((item) => ({
    id: item?.project_id,
    consultancyNo: item?.project_id,
    NameOfProject: item?.title,
    client_name: item?.client_name,
    GrossAmount: item?.gross_amount,
    DepartmentName: item?.department,
    // status: item?.approval_status,
    status: getStatusMessage(item?.approval_status),
    item: item,
  }));

  const handleClose = (name) => {
    setIsOpen({ name: false });
  };

  const handleAddForm = () => {
    navigate("/form", {
      state: {
        action: "addForm",
      },
    });
  };

  const rejectedProps = {
    showRejected: showRejected,
    handleCloseRejected: handleCloseRejected,
    title: "Reject",
    description: "Please enter the reason for rejection?",
    setReasonText: setReasonText,
    reasonText: reasonText,
    handleYes: () => {
      rejectProjectHandler(isOpen.projectId, reasonText);
    },
    handleNo: () => {
      setShowRejected(false);
      setReasonText("");
    },
  };

  const mobileProps = {
    data: data,
    handleEdit: handleEdit,
    handleOnView: handleView,
    handleOnApprove: handleApprove,
    handleOnDelete: handleDelete,
    handleOnReject: handleReject,
    role: role,
    // params:params
  };
  // console.log(isMobile);

  const rejectProjectHandler = async (projectId, reason) => {
    setLoading(true);
    const reqBody = {
      status: "REJECTED",
      approved_by: decodeTokenIs.userId,
      role: decodeTokenIs.roleType,
      reason: reason,
    };
    try {
      let response = await ProjectRejectByProjectId(projectId, reqBody);
      if (response) {
        setNotify({
          message: response.data.message || "Project rejected successfully",
          type: "success",
        });

        fetchProjectList();
        setLoading(false);
      }
    } catch (error) {
      setNotify({
        message: "An error occurred during rejection",
        type: "error",
      });
      setLoading(false);
    }
  };

  return (
    <Layout componentName={"Project form list"}>
      {notify.message && (
        <NotifyMessage message={notify.message} type={notify.type} />
      )}
      <Box sx={{ height: "88vh", overflow: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {showAddNewForm ? (
              <Box className={styles.search_container}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    height: "52px",
                  }}
                >
                  {showAddNewForm && (
                    <GeneralButton
                      name={"Add New"}
                      type={"submit"}
                      color={"#f8fafc"}
                      borderRadius={"30px"}
                      backgroundColor={BUTTON_COLOUR}
                      icons={plus}
                      width={"158px"}
                      height={"48px"}
                      buttonHandler={() => {
                        handleAddForm();
                      }}
                    />
                  )}
                </Box>
              </Box>
            ) : null}
          </Grid>
          <ConfirmationPopUp
            title={"Delete Confirmation"}
            content={`Do you really want to delete project ${"Rental car web app"}?`}
            setIsOpen={setIsOpen}
            name={"project"}
            isOpen={isOpen}
            handleClose={() => handleClose("project")}
            handleYes={handleYes}
            handleNo={() => handleNo("project")}
          />
          <Box
            style={{
              display: "flex",
              width: "100%",
              marginTop: "20px",
              marginLeft: "18px",
            }}
          >
            {loading ? (
              <PageLoader loadingStatus={loading} />
            ) : (
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    marginTop: "20px",
                    marginLeft: "18px",
                  }}
                >
                  {loading ? (
                    <PageLoader loadingStatus={loading} />
                  ) : (
                    <Grid item xs={12} md={12} lg={12}>
                      <Box
                        sx={{
                          width: "100%",
                          borderRadius: "8px",
                          overflowX: "auto",
                          "& .MuiDataGrid-root": {
                            minWidth: "100%",
                            height: "80vh",
                          },
                          "& .MuiDataGrid-cell": {
                            borderBottom: "none !important",
                          },
                          "& .MuiDataGrid-columnHeader": {
                            backgroundColor: "white",
                          },
                          "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                            textAlign: "center",
                            width: "auto",
                          },
                        }}
                      >
                        {decodeTokenIs?.roleType === "PI" ? (
                          <DataGridTable
                            rows={piData}
                            columns={columns}
                            pageSize={pageSize}
                            pageInfo={pageInfo}
                            fetchProjectList={fetchProjectList}
                            noRowsOverlay={
                              <NoRowsOverlay>
                                No projects have been created
                              </NoRowsOverlay>
                            }
                          />
                        ) : (
                          <DataGridTable
                            rows={data}
                            columns={columns}
                            pageSize={pageSize}
                            pageInfo={pageInfo}
                            fetchProjectList={fetchProjectList}
                            noRowsOverlay={
                              <NoRowsOverlay>
                                No projects have been created
                              </NoRowsOverlay>
                            }
                          />
                        )}
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
        <RejectedPopUp {...rejectedProps} />
      </Box>
    </Layout>
  );
};

export default ProjectList;
