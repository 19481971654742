import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import "../projectDetails/projectDetails.css";
import GeneralButton from "../button/GeneralButton";
import { BUTTON_COLOUR } from "../../constants/Constant";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dateRangePickerDayClasses } from "@mui/x-date-pickers-pro";
import { useNavigate } from "react-router-dom";
import ScrollButtonTopAndDown from "../button/ScrollButtonTopAndDown";

const ClosureDetailsForm = ({
  action,
  data,
  readOnlyMode,
  initialData,
  value,
  setValue,
  errors,
  setErrors,
}) => {
  const scrollableRef = useRef(null);

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;
      setIsScrolledToBottom(scrollTop + clientHeight >= scrollHeight);
    }
  };

  useEffect(() => {
    const currentRef = scrollableRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    // Initial check
    handleScroll();

    // Cleanup
    // return () => {
    //   if (currentRef) {
    //     currentRef.removeEventListener('scroll', handleScroll);
    //   }
    // };
  }, []);

  return (
    <div>
      <div className="scroll-container" ref={scrollableRef}>
        {/* {console.log("ui is rendering")} */}
        <Typography
          id="clouserTop"
          sx={{ fontWeight: "bold", marginBottom: "16px" }}
        >
          CONSULTANCY CLOSURE-CUM-DISBURSEMENT
        </Typography>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            1. Title of the Consultancy Project
          </Typography>
          <span className="field_align">:</span>
          <TextField
            name="projectName"
            value={
              initialData.titleOfConsultancyProject
                ? initialData.titleOfConsultancyProject
                : value
            }
            error={!!errors?.projectName}
            helperText={errors?.projectName}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
            disabled={readOnlyMode}
          />
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            2. Name of the client & address
          </Typography>
          <span className="field_align">:</span>
          <TextField
            name="client_address"
            value={
              initialData.nameOfClientAndAddress
                ? initialData.nameOfClientAndAddress
                : value
            }
            error={!!errors?.client_address}
            helperText={errors?.client_address}
            disabled={readOnlyMode}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
          />
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            3. Name of the Consultant-in-Charge{" "}
          </Typography>
          <span className="field_align">:</span>
          <TextField
            name="consultantInCharge"
            value={
              initialData.nameOfConsultantInCharge
                ? initialData.nameOfConsultantInCharge
                : value
            }
            error={!!errors?.consultantInCharge}
            helperText={errors?.consultantInCharge}
            disabled={readOnlyMode}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
          />
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            4. Name(s) of other Consultant(s)
          </Typography>
          <span className="field_align">:</span>
          <TextField
            name="otherConsultant"
            value={
              initialData.nameofOtherConsultant
                ? initialData.nameofOtherConsultant
                : value
            }
            disabled={readOnlyMode}
            error={!!errors?.otherConsultant}
            helperText={errors?.otherConsultant}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
          />
        </div>

        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            5. Type of Client (Industry/Government)
          </Typography>
          <span className="field_align">:</span>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="typeOfConsultancy"
              name="typeOfConsultancy"
              defaultValue={initialData?.typeOfClient}
              disabled={readOnlyMode}
            >
              <FormControlLabel
                value={"Pvt"}
                control={<Radio disabled={readOnlyMode} />}
                label="Pvt"
                sx={{ marginRight: "30px" }}
              />
              <FormControlLabel
                value={"Gov"}
                control={<Radio disabled={readOnlyMode} />}
                label="Gov"
                sx={{ marginRight: "30px" }}
              />
              <FormControlLabel
                value={"PSU"}
                control={<Radio disabled={readOnlyMode} />}
                label="PSU"
              />
            </RadioGroup>
            {errors?.typeOfConsultancy && (
              <FormHelperText error="true">
                {errors.typeOfConsultancy}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            6. Gross Consultancy Charges :
          </Typography>
          <span className="field_align">:</span>
          <TextField
            name="grossConsultancyOfCharges"
            value={
              initialData.grossConsultantCharges
                ? initialData.grossConsultantCharges
                : value
            }
            error={!!errors?.grossConsultancyOfCharges}
            helperText={errors?.grossConsultancyOfCharges}
            disabled={readOnlyMode}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
          />
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            6. Date of commencement of Work
          </Typography>
          <span className="field_align">:</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              name="startDate"
              defaultValue={initialData?.dateOfCommencementOfWork}
              onChange={(newValue) => (value = newValue)}
              value={value}
              slotProps={{
                textField: {
                  sx: {
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  },
                  error: !!errors.startDate,
                  helperText: errors.startDate,
                },
              }}
              disabled={readOnlyMode}
            />
          </LocalizationProvider>
        </div>
        <div className="field_container" id="clouserDown">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            7. Date of completion of Work
          </Typography>
          <span className="field_align">:</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              name="endDate"
              defaultValue={initialData?.dateOfCompletationOfWork}
              onChange={(newValue) => (value = newValue)}
              slotProps={{
                textField: {
                  sx: {
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  },
                  error: !!errors.endDate,
                  helperText: errors.endDate,
                },
              }}
              disabled={readOnlyMode}
            />
          </LocalizationProvider>
        </div>
      </div>
      <Box sx={{ display: "flex", justifyContent: "end", position: "sticky" }}>
        <ScrollButtonTopAndDown
          upId="clouserTop"
          downId="clouserDown"
          isScrolledToBottom={isScrolledToBottom}
        />
      </Box>
    </div>
  );
};

export default ClosureDetailsForm;
