import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Button,
  useMediaQuery,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CancelButton from "../../common/buttons/CancelButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NotifyMessage from "../../common/notify-message-toggle/NotifyMessage";
import { BASIC_API_URL, RESET_PASSWORD } from "../../../constants/Constant";
import { decodeJWT } from "../../../constants/StoredToken";

const inputStyle = { fontSize: "16px" };
const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    width: "80%",
    "&.Mui-focused fieldset": {
      borderColor: "#6200EA",
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#6200EA",
  },
};

const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

function ChangePassword() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disable, setDisable] = useState(true);
  const [notification, setNotification] = useState({ message: "", type: "" });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (setter, value) => {
    setter(value);
  };

  const areFieldsEmpty = () => {
    return !oldPassword || !newPassword || !confirmPassword;
  };

  const handleSubmit = async () => {
    if (areFieldsEmpty()) {
      setNotification({ message: "All fields are required", type: "error" });
      return;
    }

    if (newPassword !== confirmPassword) {
      setNotification({
        message: "New password and confirm password do not match",
        type: "error",
      });
      return;
    }

    try {
      const token = localStorage.getItem("jwtToken");
      if (!token) {
        alert("No JWT token found");
        return;
      }

      const decodedToken = decodeJWT(token);
      const userId = decodedToken?.userId;

      if (!userId) {
        alert("Invalid JWT token");
        return;
      }

      const response = await axios.post(
        `${BASIC_API_URL}${RESET_PASSWORD}`,
        {
          userId,
          oldPassword,
          newPassword,
          confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setNotification({
          message: "The password has been changed successfully!",
          type: "success",
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      }
    } catch (error) {
      console.error("Error changing password:", error);

      let errorMessage = "Failed to change password";
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
      }

      setNotification({ message: errorMessage, type: "error" });
    }
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
      }}
    >
      <Box component="form">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Old Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              sx={textFieldStyle}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: inputStyle,
              }}
              value={oldPassword}
              onChange={(e) =>
                handleInputChange(setOldPassword, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="New Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              sx={textFieldStyle}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: inputStyle,
              }}
              value={newPassword}
              onChange={(e) =>
                handleInputChange(setNewPassword, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Confirm Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              sx={textFieldStyle}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: inputStyle,
              }}
              value={confirmPassword}
              onChange={(e) =>
                handleInputChange(setConfirmPassword, e.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            mt={4}
            display="flex"
            sx={{
              padding: "20px",
              justifyContent: "flex-end",
              gap: "20px",
              marginTop: isMobile ? "440px" : "290px",
            }}
          >
            <CancelButton text="Cancel" onClick={handleCancel} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                borderRadius: "30px",
                color: "#fff",
                display: "flex",
                flexShrink: 0,
                fontSize: "16px",
                gap: "8px",
                height: "50px",
                justifyContent: "center",
                lineHeight: "24px",
                padding: "10px 16px",
                width: "130px",
                // cursor: disable ? "not-allowed" : "pointer",
                transition: "background-color 0.3s, border-color 0.3s",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </Box>
          {notification.message && (
            <NotifyMessage
              messageHandler={() => setNotification({ message: "", type: "" })}
              message={notification.message}
              type={notification.type}
              theme="light"
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default ChangePassword;
