import React from "react";
import iitp_logo from "../../assets/images/iit_patna_logo.jpg";
import "./AuthLayout.css";
import AuthFooter from "./AuthFooter";
import { Hidden } from "@mui/material";

const AuthLayout = ({ children }) => {
  return (
    <div className="layout_container">
      {/* header */}
      <div className="header_container">
        <img src={iitp_logo} alt={"college_logo"} className="college_logo" />
      </div>
      {/* children */}
      <div className="child_container">{children}</div>
      {/* footer */}
      <div>
        <AuthFooter />
      </div>
    </div>
  );
};

export default AuthLayout;
