import React, { useRef, useState } from "react";
import Layout from "../../../components/layout/layout";
import { useLocation, useNavigate } from "react-router-dom";
import GeneralButton from "../../../components/button/GeneralButton";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

import BasicDetails from ".././BasicDetails";
import { Box } from "@mui/system";
import { BUTTON_COLOUR } from "../../../constants/Constant";
import "../../../components/projectDetails/projectDetails.css";
import FinancialDetails from "../FinancialDetails";
import ApprovedComments from "../../../components/approvedComments/approvedComments";

function ViewMain() {
  const location = useLocation();
  const { action, data, projectId } = location.state || {};
  const navigate = useNavigate();
  const basicFormRef = useRef(null);

  // const formTitleValue =  action === "view" ? "View Project" : "Update Project";
  const formTitleValue = "View Project";
  const [tabValue, setTabValue] = useState("basicDetails");
  const [selectRecommendation, setSelectRecommendation] = useState(false);


  const approveRef = useRef(null);
  const rejectRef = useRef(null);

  const setApprove = (handleApprove) => {
    approveRef.current = handleApprove;
  };
  const setTriggerReject = (handleRejectProject) => {
    rejectRef.current = handleRejectProject;
  };

  const clickReject = () => {
    if (rejectRef.current) {
      rejectRef.current();
    }
  };
  const clickApprove = () => {
    if (approveRef.current) {
      approveRef.current();
    }
  };

  const showButton = () => {
    if (action === "view") {
      return "Continue";
    } else if (action === "approve" && tabValue === "basicDetails") {
      return "Continue";
    } else if (action === "approve" && tabValue === "financialDetails") {
      return "Approve";
    }
  };

  // const location = useLocation();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (buttonName) => {
    if (action === "view") {
      if (buttonName === "Continue" && tabValue === "basicDetails") {
        setTabValue("financialDetails");
      } else if (buttonName === "Continue" && tabValue === "financialDetails") {
        setTabValue("approveComments");
      } else if (
        buttonName === "Back to Home" &&
        tabValue === "approveComments"
      ) {
        navigate("/projects");
      }
    }

    if (action === "approve") {
      if (buttonName === "Continue" && tabValue === "basicDetails") {
        setTabValue("financialDetails");
      } else if (buttonName === "Reject") {
        clickReject();
      } else if (buttonName === "Approve") {
        // call approve function
        clickApprove();
      }
    }
  };

  const saveContinueBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick("Continue"),
    name: "Continue",
  };

  const sendApprovalBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick(showButton()),
    name: showButton(),
  };

  const rejectBtnProps = {
    name: "Reject",
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: "var(--Red-rose-600, rgba(225, 29, 72, 1))",
    width: "220px",
    height: "48px",
    marginRight: "16px",
    buttonHandler: () => {
      handleClick("Reject");
    },
  };

  const backToHomeBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick("Back to Home"),
    name: "Back to Home",
  };

  const approveBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick("Approve"),
    name: "Approve",
  };

  return (
    <Layout componentName={formTitleValue}>
      <TabContext value={tabValue}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              width: "99%",
              marginBottom: "1rem",
              marginLeft: "5px",
              marginRight: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: "16px",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="form tabs"
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                "& .MuiTab-root": {
                  minWidth: "auto",
                },
              }}
            >
              <Tab
                label="Basic Details"
                value="basicDetails"
                sx={{ fontWeight: "500" }}
              />
              <Tab
                label="Financial Details"
                value="financialDetails"
                sx={{ fontWeight: "500" }}
              />
              {action === "view" && (
                <Tab
                  label="Approved Comments"
                  value="approveComments"
                  sx={{ fontWeight: "500" }}
                />
              )}
            </TabList>
          </Box>
        </Box>
        <Box>
          <TabPanel
            value="basicDetails"
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              width: "97%",
            }}
          >
            <BasicDetails
              basicFormRef={basicFormRef}
              formTitleValue={formTitleValue}
              id={projectId}
              mode={action}
              data={data}
            />
          </TabPanel>
          <TabPanel
            value="financialDetails"
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              width: "97%",
            }}
          >
            <FinancialDetails
              formTitleValue={formTitleValue}
              id={projectId}
              mode={action}
              triggerReject={setTriggerReject}
              triggerFunction={setApprove}
              selectRecommendation={selectRecommendation}
              setSelectRecommendation={setSelectRecommendation}
            />
          </TabPanel>
          <TabPanel
            value="approveComments"
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              width: "97%",
            }}
          >
            <ApprovedComments data={data} />
          </TabPanel>
        </Box>
      </TabContext>

      <div className="btn_alignment">
        {action === "view" && (
          <Box>
            {tabValue === "basicDetails" && (
              <GeneralButton {...saveContinueBtnProps} />
            )}
            {tabValue === "financialDetails" && (
              <GeneralButton {...saveContinueBtnProps} />
            )}
            {tabValue === "approveComments" && (
              <GeneralButton {...backToHomeBtnProps} />
            )}
          </Box>
        )}
        {action === "approve" && (
          <Box>
            {tabValue === "basicDetails" && (
              <GeneralButton {...saveContinueBtnProps} />
            )}
            {tabValue === "financialDetails" && (
              <GeneralButton {...rejectBtnProps} />
            )}
            {tabValue === "financialDetails" && (
              <GeneralButton {...approveBtnProps} />
            )}
          </Box>
        )}
      </div>
    </Layout>
  );
}

export default ViewMain;
