import { Button, IconButton } from "@mui/material";
import React from "react";

import iconImage from "../../assets/images/icon-removebg-preview.png";
import RejectedReasonPopUp from "../approvedComments/rejectedReasonPopUp";

const RejectedReason = ({ handleInfo}) => {
  return (
    <div>
      <div
      onClick={handleInfo}
      >
        <img height="27px" style={{ borderRadius: "37px" }} src={iconImage} />
      </div>
    </div>
  );
};

export default RejectedReason;
