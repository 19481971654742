import { Typography } from "@mui/material";
import React, { useState } from "react";
import "./approvedComments.css";
import RejectedReason from "../rejectedReason/rejectedReason";
import RejectedReasonPopUp from "./rejectedReasonPopUp";

const ApprovedComments = ({ data }) => {
  console.log("data---->",data);
  const [isOpen, setIsOpen] = useState(false);
  const status = data?.approval_status

  let arndData = {}
  let drndData = {}
  let deanData = {}
  let directorData = {}

  arndData = data && data?.approval_sequence.find((item) => item.role === 'ARND') || {}
  drndData = data && data?.approval_sequence.find((item) => item.role === 'DRND') || {}
  deanData =data && data?.approval_sequence.find((item) => item.role === 'DEAN') || {}
  directorData = data && data?.approval_sequence.find((item) => item.role === 'DIRECTOR') || {}

  const allStatus = [ "PENDING_ARND",
    "PENDING_DARND",
    "PENDING_DEAN",
    "APPROVED_DEAN",
    "APPROVED_DIRECTOR",
    "REJECTED" ]

  const roles= ["ARND",
    "DRND",
    "DIRECTOR",
    "DEAN"
  ]


  
  
  
  const renderMessage = () => {
    switch (status) {
      case "PENDING_ARND":
          arndData.status = "PENDING_ARND"
        return ;
      case "PENDING_DARND":
        arndData.status = "APPROVED"
        drndData.status = "PENDING_DARND"
        return ;
      case "PENDING_DEAN":
        arndData.status = "APPROVED"
        drndData.status = "APPROVED"
        deanData.status = "PENDING_DEAN" 
        return ;
      case "APPROVED_DEAN":
          arndData.status = "APPROVED"
          drndData.status = "APPROVED"
          deanData.status = "APPROVED" 
          return ;
      case "APPROVED_DIRECTOR":
        arndData.status = "APPROVED"
        drndData.status = "APPROVED"
        deanData.status = "APPROVED"
        directorData.status = "APPROVED" 
        return ;
      case "REJECTED":
        const getRole = data?.approval_sequence.find((item) => item?.status === "REJECTED")
        switch(getRole?.role) {
          case "ARND" : 
            arndData.status = "REJECTED"
            return ;
          case "DRND" : 
             arndData.status =  "APPROVED" 
             drndData.status = "REJECTED"
             return ;
          case "DEAN" : 
             arndData.status =  "APPROVED" 
             drndData.status = "APPROVED"
             deanData.status =  "REJECTED" 
             return ;
          case "DIRECTOR" : 
            arndData.status =  "APPROVED" 
            drndData.status = "APPROVED"
            deanData.status =  "APPROVED"
            directorData.status = "REJECTED"
            return ;
        } 

      default:
        return ;
    }
  };  
  

  renderMessage()
  const handleInfo = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const formatDate =(dateString) => {
    if(dateString){
      const date = new Date(dateString);
    
      const day = String(date.getDate()).padStart(2, '0');
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
    
      return `${day}-${month}-${year}`;

    }
  }


  return (
    <div style={{height: '67vh'}}>
      <div className="rows">
        <Typography sx={{ width: "20%", fontWeight: "bold" }}>
          Principal Consultant
        </Typography>
        <span className="space_alignment">:</span>
        <Typography className="description">
          CREATED AT
        </Typography>
        <Typography className="description">
          {data && data?.created_at ? formatDate(data?.created_at) : "" }
        </Typography>
      </div>
      <div className="rows">
        <Typography sx={{ width: "20%", fontWeight: "bold" }}>
          Asst. Registrar (R&D):
        </Typography>
        <span className="space_alignment">:</span>
        <div className="rejected_container">
          <Typography className="rejected_text">
            {arndData && arndData?.status ? arndData?.status : "-"}
          </Typography>
          {arndData && arndData?.status === "REJECTED" && (
            <RejectedReason open={isOpen} handleInfo={handleInfo} />
          )}
        </div>
        <Typography className="description">
          {arndData &&  arndData?.date  ? formatDate(arndData?.date)  : "-"}
        </Typography>
      </div>
      <div className="rows">
        <Typography sx={{ width: "20%", fontWeight: "bold" }}>
          Dy. Registrar (R&D)
        </Typography>
        <span className="space_alignment">:</span>
        <div className="rejected_container">
          <Typography className="rejected_text">
            {drndData && drndData?.status ? drndData?.status  : "-"}
          </Typography>
          {drndData && drndData?.status === "REJECTED" && (
            <RejectedReason open={isOpen} handleInfo={handleInfo} />
          )}
        </div>
        <Typography className="description">
          {drndData && drndData?.date ? formatDate(drndData?.date) : "-"}
        </Typography>
      </div>
      <div className="rows">
        <Typography sx={{ width: "20%", fontWeight: "bold" }}>
          Associate Dean (R&D)
        </Typography>
        <span className="space_alignment">:</span>
        <div className="rejected_container">
          <Typography className="rejected_text">
            {deanData && deanData?.status ? deanData?.status : "-"}
          </Typography>
          {deanData?.status === "REJECTED" && (
            <RejectedReason open={isOpen} handleInfo={handleInfo} />
          )}
          <RejectedReasonPopUp open={isOpen} handleClose={handleClose} />
        </div>
        <Typography className="description">
          {deanData && deanData?.date ?  formatDate(deanData?.date) : "-"}
        </Typography>
      </div>
      <div className="rows">
        <Typography sx={{ width: "20%", fontWeight: "bold" }}>
          Director
        </Typography>
        <span className="space_alignment">:</span>
        <div className="rejected_container">
          <Typography className="rejected_text">
            {directorData && directorData.status ? directorData.status : "-"}
          </Typography>
          {directorData && directorData.status === "REJECTED" && (
            <RejectedReason open={isOpen} handleInfo={handleInfo} />
          )}
        </div>
        <Typography className="description">
          {directorData &&  directorData?.date ? formatDate(directorData?.date) : "-"}
        </Typography>
      </div>
    </div>
  );
};

export default ApprovedComments;
