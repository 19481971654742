import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import "../projectDetails/projectDetails.css";
import GeneralButton from "../button/GeneralButton";
import { BUTTON_COLOUR } from "../../constants/Constant";
import "./accountDetails.css";
import { useNavigate } from "react-router-dom";
import ScrollButtonTopAndDown from "../button/ScrollButtonTopAndDown";

const AccountDetailsForm = ({ action, data }) => {
  const navigate = useNavigate();
  // console.log("get role in project list----->", localStorage.getItem("role"));
  const role = localStorage.getItem("role");
  const initialField = role === "director" ? true : false;
  // console.log(data, action);

  const account_details = data
    ? data.closure_form_details.account_details
      ? data.closure_form_details.account_details
      : ""
    : "";
  // console.log(account_details);
  const NameOfBank = account_details ? account_details.nameOfBank : "";
  const accountHolderName = account_details
    ? account_details.nameofAccountHolder
    : "";
  const ifscCode = account_details ? account_details.ifscCode : "";
  const accountNumber = account_details ? account_details.acccountNumber : "";

  return (
    <div className="form_container">
      <Typography sx={{ fontWeight: "bold", marginBottom: "16px" }}>
        11. Account Details require for disbursement of consultancy fee:
      </Typography>
      <form className="account_details_cont">
        <div>
          <div className="field_container">
            <Typography sx={{ width: "35%", paddingTop: "8px" }}>
              Name of Bank :
            </Typography>
            <span className="field_align">:</span>
            <TextField
              name="nameOfBank"
              value={initialField ? NameOfBank : null}
              disabled={initialField}
              required
              sx={{
                width: "350px",
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  borderRadius: "15px",
                },
              }}
            />
          </div>
          <div className="field_container">
            <Typography sx={{ width: "35%", paddingTop: "8px" }}>
              Name of account holder :
            </Typography>
            <span className="field_align">:</span>
            <TextField
              name="nameOfAccountHolder"
              value={initialField ? accountHolderName : null}
              disabled={initialField}
              required
              sx={{
                width: "350px",
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  borderRadius: "15px",
                },
              }}
            />
          </div>
          <div className="field_container">
            <Typography sx={{ width: "35%", paddingTop: "8px" }}>
              Account Number :
            </Typography>
            <span className="field_align">:</span>
            <TextField
              name="accountNumber"
              value={initialField ? accountNumber : null}
              disabled={initialField}
              required
              sx={{
                width: "350px",
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  borderRadius: "15px",
                },
              }}
            />
          </div>
          <div className="field_container">
            <Typography sx={{ width: "35%", paddingTop: "8px" }}>
              IFSC Code :
            </Typography>
            <span className="field_align">:</span>
            <TextField
              name="IFSCCode"
              value={initialField ? ifscCode : null}
              disabled={initialField}
              required
              sx={{
                width: "350px",
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  borderRadius: "15px",
                },
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountDetailsForm;
