export const validations = {
  firstName: /^[a-zA-Z\s]+$/,
  lastName: /^[a-zA-Z\s]+$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  mobileNumber: /^\d{10}$/,
};

export const isWhitespaceOnly = (value) => !value?.trim().length;

export const validate = (details) => {
  const isValid = {
    firstName:
      validations.firstName.test(details?.firstName ?? "") &&
      !isWhitespaceOnly(details?.firstName ?? ""),
    lastName:
      validations.lastName.test(details?.lastName ?? "") &&
      !isWhitespaceOnly(details?.lastName ?? ""),
    email:
      validations.email.test(details?.email ?? "") &&
      !isWhitespaceOnly(details?.email ?? ""),
    mobileNumber:
      validations.mobileNumber.test(details?.mobileNumber ?? "") &&
      !isWhitespaceOnly(details?.mobileNumber ?? ""),
    gender: details?.gender !== "",
  };

  return isValid;
};
