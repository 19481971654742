import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";

const NoRowsOverlay = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: 16,
  textAlign: "center",
});

const DataGridTable = ({
  rows = [],
  columns = [],
  showOrHide,
  pageInfo,
  setPageInfo,
  itemRender,
  fetchProjectList,
  fetchlist,
  disablePagination = false,
  noRowsOverlay,
}) => {
  const onPageHandler = ({ page, pageSize }) => {
    const newPage = pageSize !== pageInfo.pageSize ? 0 : page;
    setPageInfo({ ...pageInfo, page: newPage });
    fetchProjectList(newPage, pageSize);
  };

  return (
    <div style={{ maxWidth: "1800px", height: "80vh" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationModel={{
          pageSize: pageInfo?.pageSize,
          page: pageInfo?.page,
        }}
        onPaginationModelChange={onPageHandler}
        checkboxSelection={showOrHide}
        paginationMode="server"
        disableColumnMenu={true}
        disableRowSelectionOnClick
        rowCount={pageInfo?.totalCount}
        pageSizeOptions={[10, 25, 50, 100]}
        components={{
          NoRowsOverlay: noRowsOverlay,
        }}
      />
    </div>
  );
};

export default DataGridTable;
