import PopupState, { bindPopover } from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Style from "./header.module.css";
import defaultProfilePhoto from "../../assets/images/defaultProfilePhoto.png";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";

function Header({ componentName }) {
  const headerImage = defaultProfilePhoto;
  const navigate = useNavigate();

  const handleLogout = () => {
    window.location.href = "/";
    localStorage.clear();
  };

  const handleViewProfile = () => {
    navigate("/PersonalInfo");
  };

  return (
    <PopupState variant="popover" popupId="profile-popup-popover">
      {(popupState) => (
        <div className={Style.headermain}>
          <Typography
            variant="h6"
            color={"#312e81"}
            sx={{ fontWeight: "bold" }}
          >
            {componentName}
          </Typography>
          <div onClick={popupState.open} className={Style.popupalignment}>
            <div>
              <img src={headerImage} alt="" className={Style.roundedimage} />
            </div>
            <div className={Style.Usernametext}></div>
          </div>
          <Menu
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleViewProfile}>
              <span className={Style.Iconalgnment}>
                <PersonIcon />
              </span>
              <span>View Profile</span>
              {/* <span>Change Password</span> */}
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <span className={Style.Iconalgnment}>
                {" "}
                <LogoutOutlinedIcon />
              </span>
              <span>Logout</span>
            </MenuItem>
          </Menu>
        </div>
      )}
    </PopupState>
  );
}

export default Header;
