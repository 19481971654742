import React from "react";
import { Flex, Progress } from "antd";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";

const conicColors = {
  "50%": "#24d468",
  "15%": "#face05",
  "10%": "#fb8e04",
};
const MileStonePieChart = () => {
  const [projectStatus, setProjectStatus] = React.useState("");

  const handleChange = (event) => {
    setProjectStatus(event.target.value);
  };

  return (
    <>
      <Flex vertical gap="middle">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "start",
              fontWeight: 700,
              fontSize: 20,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <p>Overall Progress</p>
          </Typography>
          <Box
            sx={{
              minWidth: 90,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">See All</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={projectStatus}
                label="Project Status"
                onChange={handleChange}
              >
                <MenuItem value={24}> Total Projects 24</MenuItem>
                <MenuItem value={15}> Projects Completed 15 </MenuItem>
                <MenuItem value={5}>Projects In Progress 5</MenuItem>
                <MenuItem value={4}> Projects Delayed 4 </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ display: "flex", marginTop: 5 }}>
          <Box sx={{ borderRadius: "70px", backgroundColor: "#c3b5d1" }}>
            <Typography sx={{ p: 1, fontWeight: 800 }}>24</Typography>
          </Box>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 15,
              fontWeight: 800,
              ml: 3,
            }}
          >
            Total MileStone
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // height: "250px",
          }}
        >
          <Box sx={{ display: "block", width: "180px" }}>
            <Typography
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                color={"#fb8e04"}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  fontWeight: 800,
                  fontSize: 20,
                }}
              >
                15
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: 12,
                  fontWeight: 800,
                }}
              >
                {" "}
                Projects Completed
              </Typography>
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                color={"#24d468"}
                sx={{
                  fontWeight: 800,
                  fontSize: 20,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {" "}
                5
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: 12,
                  fontWeight: 800,
                }}
              >
                Projects In Progress
              </Typography>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                color={"#fb8e04"}
                sx={{ fontWeight: 800, fontSize: 20 }}
              >
                4
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 12,
                  fontWeight: 800,
                }}
              >
                Projects Delayed
              </Typography>
            </Typography>
          </Box>
          <Flex
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            wrap
          >
            <Progress
              size={150}
              type="circle"
              percent={75}
              // strokeLinecap="square"
              // strokeWidth={}
              strokeColor={conicColors}
              status="Completed"
              // showInfo={true}
            />
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default MileStonePieChart;
