import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APPROVING_ROLES, BUTTON_COLOUR } from "../../constants/Constant";
import GeneralButton from "../../components/button/GeneralButton";
import { validate } from "../../utils/EmployeeValidation";
import {
  GetGender,
  UpdateEmployee,
  GetAllEmployeeListById,
} from "../../api/ApiService";
import PageLoader from "../../components/Loader/PageLoader";
import NotifyMessage from "../../components/NotifyMessage/NotifyMessage";

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    width: "80%",
  },
};

const inputStyle = { fontSize: "16px" };

const EmployeeBasicDetails = ({ onUpdateSuccess }) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { action } = state || {};
  const role = localStorage.getItem("role");

  const readOnlyMode = action === "view" || APPROVING_ROLES.includes(role);
  const [notify, setNotify] = useState({ message: "", type: "" });

  const [genderId, setGenderId] = useState(null);
  const [genderData, setGenderData] = useState([]);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    mobileNumber: "",
  });
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      setLoading(true);
      try {
        const response = await GetAllEmployeeListById(id);
        const employeeData = response.data;

        let genderFieldValue = "";
        let genderFieldId = null;

        employeeData.userDetails.forEach((item) => {
          if (item.userFieldData.fieldName === "gender") {
            genderFieldValue = item.userFieldData.fieldValue;
            genderFieldId = item.id;
          }
        });

        setFormValues({
          firstName: employeeData.user.firstName,
          lastName: employeeData.user.lastName,
          email: employeeData.user.email,
          mobileNumber: employeeData.user.mobileNumber,
          gender: genderFieldValue,
        });
        setGenderId(genderFieldId);
      } catch (error) {
        console.error("Failed to fetch employee data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeData();
  }, [id]);

  useEffect(() => {
    const fetchGenderData = async () => {
      try {
        const response = await GetGender();
        if (response && response.status === 200) {
          setGenderData(response.data);
        } else {
          console.error("Failed to fetch gender data");
        }
      } catch (err) {
        console.error("Error fetching gender data:", err);
      }
    };

    fetchGenderData();
  }, []);

  useEffect(() => {
    setIsFormValid(validate(formValues));
  }, [formValues]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "gender") {
      const selectedGender = genderData.find(
        (gender) => gender.fieldValue === value
      );
      if (selectedGender) {
        localStorage.setItem("selectedGenderId", selectedGender.id);
      }
    }

    const newErrors = { ...errors };
    if (!validate({ [name]: value })[name]) {
      newErrors[name] = `Invalid ${name}`;
    } else {
      delete newErrors[name];
    }
    setErrors(newErrors);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { firstName, lastName, mobileNumber } = formValues;
      const employeeData = {
        firstName,
        lastName,
        mobileNumber,
        userDetails: [
          {
            userDetailId: genderId,
            filedId: localStorage.getItem("selectedGenderId"),
            status: true,
          },
        ],
      };
      const response = await UpdateEmployee(id, employeeData);
      console.log("API response:", response);

      if (response) {
        setNotify({
          message: "Employee updated successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate("/employeeList");
          onUpdateSuccess();
        }, 2000);
      } else {
        setNotify({
          message: "Employee update error.",
          type: "error",
        });
        console.log("Notify state updated:", notify);
      }
    } catch (err) {
      // console.error("Error updating employee:", err);
      setNotify({
        message: "An error occurred while updating employee.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%", padding: "1rem", marginTop: "0.5rem" }}>
      <PageLoader loadingStatus={loading} />

      {notify.message && (
        <>
          {console.log("NotifyMessage should render now")}
          <NotifyMessage
            message={notify.message}
            type={notify.type}
            theme="light"
          />
        </>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={"First Name"}
              value={formValues.firstName}
              name="firstName"
              onChange={handleChange}
              margin="normal"
              sx={textFieldStyle}
              InputProps={{
                style: inputStyle,
                readOnly: readOnlyMode,
              }}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={"Last Name"}
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
              margin="normal"
              sx={textFieldStyle}
              InputProps={{
                style: inputStyle,
                readOnly: readOnlyMode,
              }}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" sx={textFieldStyle} required>
              <Select
                fullWidth
                id="gender"
                name="gender"
                value={formValues.gender}
                onChange={handleChange}
                variant="outlined"
                sx={textFieldStyle}
                disabled={readOnlyMode}
                error={Boolean(errors.gender)}
              >
                <MenuItem value="">
                  <em>Select Gender</em>
                </MenuItem>
                {genderData.map((gender) => (
                  <MenuItem key={gender.id} value={gender.fieldValue}>
                    {gender.fieldValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={"Email"}
              name="email"
              value={formValues.email}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              sx={textFieldStyle}
              InputProps={{
                style: inputStyle,
                readOnly: true,
              }}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={"Mobile Number"}
              name="mobileNumber"
              value={formValues.mobileNumber}
              onChange={handleChange}
              margin="normal"
              sx={textFieldStyle}
              InputProps={{
                style: inputStyle,
                readOnly: readOnlyMode,
              }}
              error={Boolean(errors.mobileNumber)}
              helperText={errors.mobileNumber}
            />
          </Grid>
        </Grid>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "360px",
            padding: "15px",
            gap: "20px",
          }}
        >
          <GeneralButton
            name="Cancel"
            backgroundColor="#F2F2F2"
            color="#656565"
            borderRadius="30px"
            width="220px"
            height="48px"
            border="2px solid #F2F2F2"
            onClick={() => navigate("/employeeList")}
          />
          <GeneralButton
            type="submit"
            name="Save"
            backgroundColor={`${BUTTON_COLOUR}`}
            color="white"
            borderRadius="30px"
            width="220px"
            height="48px"
            disabled={!isFormValid}
          />
        </Box>
      </form>
    </Box>
  );
};

export default EmployeeBasicDetails;
