import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function MobileViewTable({
  data,
  // handleEdit,
  role,
  // handleEdit,
  // handleOnApprove,
  handleOnDelete,
  // handleOnReject,
}) {
  const navigate = useNavigate();

  // console.log("mobileData", data[1]);
  // console.log("mobileRole------>", role);
  const handleEdit = (index) => {
    navigate("/form", {
      state: {
        action: "edit",
        data: data[index].item,
      },
    });
  };

  const handleView = (index) => {
    navigate("/form", {
      state: {
        action: "view",
        data: data[index].item,
      },
    });
    // console.log(data[index]);
  };

  const handleOnApprove = (index) => {
    navigate("/form", {
      state: {
        action: "approve",
        data: data[index].item,
      },
    });
  };

  const handleOnReject = (index) => {
    navigate("/form", {
      state: {
        action: "reject",
        data: data[index].item,
      },
    });
  };
  // const handleOnDelete = () => {
  //   console.log("handle delete");
  //   // <<<<<<< resolve-branch
  //   setIsOpen({ project: true });
  // };

  return (
    <div style={{ marginBottom: "9em", marginRight: "2.5em" }}>
      {data?.length > 0 && (
        <>
          {data.map((user, index) => {
            return (
              <Accordion
                defaultExpanded={index == 0 ? true : false}
                // sx={{ mr: 5 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div>{`${
                      user.consultancyNo ? user.consultancyNo : null
                    }`}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexDirection: "column",
                      gap: "1em",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography sx={{ width: "9em" }}>
                        Consultancy No
                      </Typography>
                      <Typography style={{ width: "0.5em" }}>:</Typography>
                      <Typography>
                        {" "}
                        {user.consultancyNo ? user.consultancyNo : null}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography sx={{ width: "9em" }}>
                        Name Of Project
                      </Typography>
                      <Typography style={{ width: "0.5em" }}>:</Typography>
                      <Typography>
                        {user.NameOfProject ? user.NameOfProject : null}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography sx={{ width: "9em" }}>
                        Gross Of Amount
                      </Typography>
                      <Typography style={{ width: "0.5em" }}>:</Typography>
                      <Typography>
                        {user.GrossAmount ? user.GrossAmount : null}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography sx={{ width: "9em" }}>
                        Department Name
                      </Typography>
                      <Typography style={{ width: "0.5em" }}>:</Typography>
                      <Typography>
                        {user.DepartmentName ? user.DepartmentName : null}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography sx={{ width: "9em" }}>Status</Typography>
                      <Typography style={{ width: "0.5em" }}>:</Typography>
                      <Typography sx={{ marginLeft: "2px" }}>
                        {user.status}
                      </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
                <AccordionActions sx={{ mr: 2 }}>
                  {role === "register" ? (
                    <Button
                      onClick={() => {
                        handleEdit(index);
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button onClick={() => handleOnApprove(index)}>
                      Approove
                    </Button>
                  )}

                  <Button
                    onClick={() => {
                      handleView(index);
                    }}
                  >
                    View
                  </Button>
                  <Button onClick={() => handleOnReject(index)}>Reject</Button>
                  <Button onClick={() => handleOnDelete(index)}>Delete</Button>
                </AccordionActions>
              </Accordion>
            );
          })}
        </>
      )}
    </div>
  );
}

export default MobileViewTable;
