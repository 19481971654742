export const validateEmail = (value) => {
    if(!value){
      return 'Please enter your email';
    }else if( !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)){
      return "Invalid email format";
    }
    return "";
  }

  export const validateePassword = (value) => {
    if(!value){
      return 'Please enter your password';
    }else if(value.length < 8 || !/[A-Z]/.test(value) || !/[a-z]/.test(value) || !/\d/.test(value) || !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)){
      return "Your password is very simple. Password should be a combination of at least 8 characters, 1 uppercase, 1 lowercase, 1 digit, and 1 special character." ; 
    }
    return "";
  }

  export const validateForm = (values) => {
    const newErrors = {};
    if (!values?.projectName) newErrors.projectName = "Project name is required";
    if (!values?.clientAddress) newErrors.clientAddress = "Client address is required";
    if (!values?.consultantInCharge) newErrors.consultantInCharge = "Consultant in charge is required";
    if (!values?.otherConsultant) newErrors.otherConsultant = "Other consultant is required";
    if (!values?.nameOfDept) newErrors.nameOfDept = "Department name is required";
    if (!values?.typeOfClient) newErrors.typeOfClient = "Type of client is required";
    if (!values?.startDate) newErrors.startDate = "Start date is required";
    if (!values?.endDate) newErrors.endDate = "End date is required";
    return newErrors;
  };


  export const validateFinancialForm = (values) => {
    const newErrors = {};
    if (values?.consultancyProject?.length === 0) newErrors.consultancyProject = "select atleast one option";
    if (!values?.consultancyFee) newErrors.consultancyFee = "Please enter consultant fee";
    // if (!values?.consultantInCharge) newErrors.consultantInCharge = "Consultant in charge is required";
    if (!values?.gst) newErrors.gst = "Please enter GST";
    if (!values?.overheadAmount) newErrors.overheadAmount = "Please enter overhead amount";
    if (!values?.otherExpenditure) newErrors.otherExpenditure = "Please enter overhead amount";
    if (!values?.grossConsultancyOfCharges) newErrors.grossConsultancyOfCharges = "Please enter gross consultancy of charges";
    if (!values?.totalExpenditure) newErrors.totalExpenditure = "Please enter total expenditure";
    if (!values?.instituteShare) newErrors.instituteShare = "Please enter institute share";
    if (!values?.recommendation) newErrors.recommendation = "Please select recommendation";
    if(!values?.isGstNumAvailable) newErrors.isGstNumAvailable = "Please select availability of GST number";
    if(!!values?.isGstNumAvailable && values?.isGstNumAvailable === "yes" && !values?.gstNumber) newErrors.gstNumber = "Please enter GST number";
    if(!values?.totalSanctionAmount) newErrors.totalSanctionAmount = "Please enter total amount";
    return newErrors;
  };


  export const validateClosureForm = (values) => {
    const newErrors = {};
    if (!values?.projectName) newErrors.projectName = "Please enter Project name";
    if (!values?.client_address) newErrors.client_address = "Please enter client address";
    if (!values?.consultantInCharge) newErrors.consultantInCharge = "Please enter Consultant Incharge";
    if (!values?.otherConsultant) newErrors.otherConsultant = "Please enter Other consultant";
    if (!values?.typeOfConsultancy) newErrors.typeOfConsultancy = "Please enter type of consultant";
    if (!values?.grossConsultancyOfCharges) newErrors.grossConsultancyOfCharges = "Please enter gross consultancy of charges";
    if (!values?.startDate || values?.startDate === null) newErrors.startDate = "Please enter commencement of work";
    if (!values?.endDate || values?.endDate === null) newErrors.endDate = "Please enter completion date";
    return newErrors;
  };


export const validateBasicDetailsForm = (values) => {
  const newErrors = {};
  if(!values?.projectInternational) newErrors.projectInternational = "Please select project type";
  if(!values?.isGstNumAvailable) newErrors.isGstNumAvailable = "Please select availability of GST number";
  if(!!values?.isGstNumAvailable && values?.isGstNumAvailable === "yes" && !values?.gstNumber) newErrors.gstNumber = "Please enter GST number";
  if(!values?.totalSanctionAmount) newErrors.totalSanctionAmount = "Please enter total amount";
  if(!values?.scheduledPayment) newErrors.scheduledPayment = "Please enter scheduled payment"
  return newErrors;
}


export const validateClientForm = (values) => {
  const newErrors = {};
  if(!values?.clientName) newErrors.clientName = "Please enter Client name";
  if(!values?.clientAddress) newErrors.clientAddress = "Please enter Address";
  if(!values?.clientType) newErrors.clientType = "Please enter Client type";
  return newErrors
}

export const validateBasicForm = (values) => {
  const newErrors = {files: []};
  if (!values?.projectName) newErrors.projectName = "Project name is required";
  if (!values?.clientName) newErrors.clientName = "Client Name is required";
  if (!values?.clientAddress) newErrors.clientAddress = "Address Name is required";
  if (!values?.consultantInCharge) newErrors.consultantInCharge = "Consultant in charge is required";
  if (!values?.otherConsultant) newErrors.otherConsultant = "Other consultant is required";
  if (!values?.nameOfDept) newErrors.nameOfDept = "Department name is required";
  if (!values?.clientType) newErrors.clientType = "Type of client is required";
  if (!values?.startDate) newErrors.startDate = "Start date is required";
  if (!values?.endDate) newErrors.endDate = "End date is required";
  Object.keys(values).forEach((key) => {
    if (key.startsWith("consultantInCharge_") && !values[key]) {
      newErrors[key] = "Consultant in charge is required";
    }
  });
  Object.keys(values).forEach((key) => {
    if (key.startsWith("otherConsultantName_") && !values[key]) {
      newErrors[key] = "Other consultant is required";
    }
  });

  // values.files.forEach((file, index) => {
  //   if (file === null || file === 0) {
  //     newErrors.files[index]  = "Please select Document"
  //   }else {
  //     newErrors.files[index] = null; 
  //   }
  // })

  if (newErrors.files.every(error => error === null)) {
    delete newErrors.files;
  }
  
  return newErrors;
};