import React from "react";
import Layout from "../../components/layout/layout";
import StatesticCards from "./statesticCards";
import ProjectSummary from "./projectSummary";
import MileStoneProgress from "./mileStoneProgress";
import { Box } from "@mui/material";

const Dashboard = () => {
  return (
    <Layout componentName={"Dashboard"} >
      <Box sx={{  height: '92%', overflowY: 'auto',padding:'1rem' }}>
        {/* statetstic card */}
        <StatesticCards />
        {/* table card */}
        <ProjectSummary />
        <MileStoneProgress />
      </Box>
    </Layout>
  );
};

export default Dashboard;
