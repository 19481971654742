import React, { useState } from "react";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Upload, Button, Input, Modal } from "antd";
import { Box, useMediaQuery } from "@mui/system";
import { IconButton, Popover, Typography, Button as Download } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./Document.module.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import deleteIcon from "../../assets/images/delete.png";
import ConfirmationPopUp from "../conifrmationPopUp/confirmationPopUp";
// import { Modal } from "@mui/base";
import { APPROVING_ROLES } from "../../constants/Constant";
import { FileDownload } from "@mui/icons-material";

const DocumentSelection = ({
  handleFileChangeUpload,
  handleOnCancelIsVisibleUpload,
  handleOkUpload,
  handleCancelUpload,
  addUploadInputUpload,
  documentProps,
  handleFirstDeleteUploadFile,
  isModalVisible,
  isModalVisibleUpload,
  readOnlyMode,
  fileList,
  setIsModalVisible,
  setIsModalVisibleUpload,
  setIsOpen,
  isOpen,
  handleClose,
  handleConfirmationNo,
  errors={files:[]},
  getDownloadFiles,
  mode
}) => {
  const location = useLocation();
  const { action } = location.state || {};
  const role = localStorage.getItem("role");
  // console.log(role);
  // console.log(role, action);
  const disable = APPROVING_ROLES.includes(role);
  // console.log(disable);
  const showDownload = mode === "view"

  const ErrorMsg = () => {
    return (
      <span style={{ color: "red", fontSize: "14px", padding: "10px" }}>
        Please upload the document
      </span>
    );
  };
  console.log(fileList);

  return (
    <>
      <Box sx={{ display: "block" }}>
        {fileList.map((file, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: {
                md: "row",
                lg: "row",
                xl: "row",
                xs: "column",
              },
              paddingTop: "-10px",
              paddingBottom: "10px",
              paddingLeft: "0px",
              paddingRight: "0px",

              gap: "0.5em",
              alignItems: "baseline",
            }}
          >
            <Box
              sx={{
                display:'flex',
                flexDirection:'column',
                minWidth: {
                  md: "355px",
                  xs: "100%",
                },
              }}
            >
              <Input
                value={file ? file.name : ""}
                placeholder={file ? file.name : "Upload Document"}
                className="Adddoc_input_css"
                style={{
                  height: "35px",
                  borderRadius: "15px",
                  maxWidth: "350px",
                  color: "#212529",
                  background: "transparent",
                  minWidth: {
                    md: "495px",
                    lg: "495px",
                    xl: "495px",
                    xs: "50%",
                  },
                }}
                disabled 
                
              />
              {errors?.files && errors?.files?.[index] && <ErrorMsg />}
            </Box>
            <Box
              sx={{
                maxWidth: "5em",
              }}
            >
              {disable || readOnlyMode ? null : (
                <Upload {...documentProps(index)}>
                  <Button disabled={file} icon={<UploadOutlined />}></Button>
                </Upload>
              )}
            </Box>
            {!readOnlyMode && <Box>
              {index === 0 ? (
                file ? (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setIsModalVisible(true);
                      setIsOpen({ ...isOpen, uploadDocument: true });
                    }}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </IconButton>
                ) : null
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setIsModalVisible(true);
                    // setIsOpen({ ...isOpen, addDocument: true });
                    setIsOpen({ ...isOpen, uploadDocument: true });
                  }}
                >
                  <img src={deleteIcon} alt="delete" />
                </IconButton>
              )}
            </Box>}
            {/* <Box>
            {showDownload && <Download
                variant="contained"
                color="info"
                endIcon={<FileDownload />}
                onClick={() => getDownloadFiles(file?.name)}
                download
                >
                Download
              </Download>}
            </Box> */}

            <ConfirmationPopUp
              title={"Delete Confirmation"}
              content={"Do you really want to delete upload document?"}
              setIsOpen={setIsOpen}
              name={"uploadDocument"}
              isOpen={isOpen}
              handleClose={() => handleClose("uploadDocument")}
              handleYes={() =>
                index === 0
                  ? handleFirstDeleteUploadFile(index)
                  : handleOkUpload(index)
              }
              handleNo={() => handleConfirmationNo("uploadDocument")}
            />
          </Box>
        ))}
        {fileList === null ? null : (
          <div className="add_btn_cons">
            <IconButton
              color="primary"
              aria-label="add cons button"
              size="large"
              // onClick={addUploadInput}
              // disabled={readOnlyMode ||  fileList.includes(null) || fileList.includes(0) }
              disabled={readOnlyMode}
              onClick={() => {
                setIsModalVisibleUpload(true);
                setIsOpen({ ...isOpen, addDocument: true });
              }}
              // disabled={readOnlyMode}
            >
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </div>
        )}

        <ConfirmationPopUp
          title={"Confirmation"}
          content={"Do you really want to add document?"}
          setIsOpen={setIsOpen}
          name={"addDocument"}
          isOpen={isOpen}
          handleClose={() => handleClose("addDocument")}
          handleYes={addUploadInputUpload}
          handleNo={() => handleConfirmationNo("addDocument")}
        />
      </Box>
    </>
  );
};

export default DocumentSelection;
