import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import {  Drawer } from 'antd'
import { Link } from 'react-router-dom'
import ShortTextIcon from '@mui/icons-material/ShortText'
import { Dropdown } from 'antd'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import './index.css'
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.png'
import Dashboard from '@mui/icons-material/Dashboard';


function MobileHeader(props) {
    const {
        pathname,
        componentName,

    } = props

    const [visible, setVisible] = useState(false)
    const headerImage = defaultProfilePhoto


    const REGISTER = [
        {
          name: 'Projects',
          icon: <Dashboard />,
          link: '/projects',
          activeLinks: ['projects'],
        },
      ];
      
      
      const navLinks = {
        REGISTER: REGISTER,
      };

    const handleLogout = () => {
        localStorage.clear()
        window.location.href = '/signin'
    }




    const itemsTwo = [
        {
            label: 'Logout',
            key: '2',
            icon: <LogoutOutlinedIcon />,
            onClick: handleLogout,
        },
    ]

    const items = itemsTwo

    const onOpen = () => {
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
    }




 

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    zIndex: 999,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: '10px',
                    borderBottom: '1px solid #b4b4b4',
                    paddingRight: '10px',
                }}
            >
                <ShortTextIcon
                    sx={{ fontSize: 40, color: '#676767' }}
                    onClick={onOpen}
                />
                <Typography variant="h5" color={'#312e81'}>
                    {componentName && componentName.slice(0, 10) + '...'}
                </Typography>
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={['click']}
                >
                    <img
                        src={headerImage}
                        alt=""
                        style={{
                            height: '44px',
                            width: '44px',
                            borderRadius: '50%',
                            marginRight: '0.6em',
                            paddingBottom: '2px',
                            paddingTop: '2px',
                        }}
                        onClick={(e) => e.preventDefault()}
                    />
                </Dropdown>
            </Box>

            <Drawer
                title={
                    <Typography variant="h5" sx={{ color: 'black' }}>
                        Menu
                    </Typography>
                }
                placement="left"
                closable={true}
                onClose={onClose}
                open={visible}
                mask
                style={{
                    backgroundColor: 'rgb(248, 250, 252)',
                    width: '75%',
                }}
                className="closeIcon"
            >
                {navLinks["REGISTER"]?.map((item) => {
                    return (
                        <Link
                            key={item.name}
                            to={item.link}
                            style={{ textDecoration: 'none' }}
                            className="hoverDiv"
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    color: 'white',
                                    textDecoration: 'none',
                                    padding: '8px',
                                }}
                            >
                                {React.cloneElement(item.icon, {
                                    style: {
                                        color: item?.activeLinks.includes(
                                            pathname.split('/')[1]
                                        )
                                            ? '#4F46E5'
                                            : 'black',
                                    },
                                })}

                                <Typography style={{ color: 'black' }}>
                                    {item.name}
                                </Typography>
                            </Box>
                        </Link>
                    )
                })}
            </Drawer>
        </>
    )
}

export default MobileHeader
