import { Button } from "@mui/material";
import { Typography } from "@mui/material";

const GeneralButton = (props) => {
  const {
    name,
    buttonHandler = () => {},
    buttonProps,
    type,
    color,
    backgroundColor,
    width,
    height,
    marginLeft,
    boxShadow,
    borderRadius,
    border,
    icons,
    marginTop,
    buttonLoading = false,
    padding,
    fontSize,
    marginRight, 
    disable = false
  } = props;
  
  console.log("disable---->",disable);
  

  const handleClick = (e) => {
    // console.log("buttonHandler:", buttonHandler);
    if (buttonHandler) {
      buttonHandler();
    } else {
      console.error("buttonHandler is not defined.");
    }
  };

  return (
    <Button
      type={type}
      className="center"
      onClick={handleClick}
      // loading={buttonLoading}
      sx={{
        color: color,
        backgroundColor: backgroundColor,
        width: width,
        height: height,
        boxShadow: boxShadow,
        borderRadius: borderRadius,
        border: border,
        marginLeft: marginLeft,
        marginTop: marginTop,
        marginRight: marginRight,
        '&:hover': {
          backgroundColor: backgroundColor,
          opacity: 0.9,
        },
        '&:focus': {
          backgroundColor: backgroundColor,
        },
        '&:active': {
          backgroundColor: backgroundColor,
        },
      }}
      disabled={disable}
    >
      <Typography variant="body1">{name}</Typography>
      {icons && <img src={icons} style={{ marginLeft: "8px" }} alt="" />}
    </Button>
  );
};

export default GeneralButton;
