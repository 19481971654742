import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import CancelButton from "../../common/buttons/CancelButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NotifyMessage from "../../common/notify-message-toggle/NotifyMessage";
import { BASIC_API_URL } from "../../../constants/Constant";
import { decodeJWT } from "../../../constants/StoredToken";
import { GetAllEmployeeListById } from "../../../api/ApiService";
const inputStyle = { fontSize: "16px" };
const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    width: "80%",
    "&.Mui-focused fieldset": {
      borderColor: "#6200EA",
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#6200EA",
  },
};

const namePattern = /^[a-zA-Z\s]*$/;
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function PersonalInfo() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("jwtToken");
      if (!token) {
        alert("No JWT token found");
        return;
      }
      const decodedToken = decodeJWT(token);
      const userId = decodedToken?.userId;
      if (!userId) {
        alert("Invalid JWT token");
        return;
      }
      try {
        const response = await GetAllEmployeeListById(userId);
        const user = response.data.user;
        setUserData({
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email || "",
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    setDisable(
      !(
        namePattern.test(firstName?.trim()) &&
        namePattern.test(lastName?.trim()) &&
        emailPattern.test(email?.trim())
      )
    );
  }, [firstName, lastName, email]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  const handleSubmit = async () => {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      alert("No JWT token found");
      return;
    }

    const decodedToken = decodeJWT(token);
    const userId = decodedToken?.userId;

    if (!userId) {
      alert("Invalid JWT token");
      return;
    }

    try {
      const response = await axios.put(
        `${BASIC_API_URL}/users/profile/${userId}`,
        {
          firstName: userData.firstName,
          lastName: userData.lastName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setNotification({
        message: "Your profile has been updated successfully",
        type: "success",
      });

      const updatedProfile = await axios.get(
        `${BASIC_API_URL}/users/profile/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedUserData = updatedProfile.data.user;
      setUserData({
        firstName: updatedUserData.firstName,
        lastName: updatedUserData.lastName,
        email: updatedUserData.email,
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Error updating profile");
    }
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 2,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="First Name"
            variant="outlined"
            value={userData.firstName}
            onChange={(e) =>
              setUserData((prevState) => ({
                ...prevState,
                firstName: e.target.value,
              }))
            }
            fullWidth
            sx={textFieldStyle}
            InputProps={{
              style: inputStyle,
            }}
            error={!namePattern.test(userData.firstName)}
            helperText={
              !namePattern.test(userData.firstName) && "Invalid first name"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Last Name"
            variant="outlined"
            value={userData.lastName}
            onChange={(e) =>
              setUserData((prevState) => ({
                ...prevState,
                lastName: e.target.value,
              }))
            }
            fullWidth
            sx={textFieldStyle}
            InputProps={{
              style: inputStyle,
            }}
            error={!namePattern.test(userData.lastName)}
            helperText={
              !namePattern.test(userData.lastName) && "Invalid last name"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            variant="outlined"
            value={userData.email}
            disabled
            fullWidth
            readonly
            InputProps={{
              style: inputStyle,
            }}
            sx={textFieldStyle}
            error={!emailPattern.test(userData.email)}
            helperText={!emailPattern.test(userData.email) && "Invalid email"}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            mt={4}
            display="flex"
            sx={{
              padding: "20px",
              justifyContent: "flex-end",
              gap: "20px",
              marginTop: isMobile ? "290px" : "150px",
            }}
          >
            <CancelButton text="Cancel" onClick={handleCancel} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={disable}
              sx={{
                borderRadius: "30px",
                color: "#fff",
                display: "flex",
                flexShrink: 0,
                fontSize: "16px",
                gap: "8px",
                height: "50px",
                justifyContent: "center",
                lineHeight: "24px",
                padding: "10px 16px",
                width: "130px",
                cursor: "pointer",
                transition: "background-color 0.3s, border-color 0.3s",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
      {notification.message && (
        <NotifyMessage
          messageHandler={() => setNotification({ message: "", type: "" })}
          message={notification.message}
          type={notification.type}
          theme="light"
        />
      )}
    </Box>
  );
}

export default PersonalInfo;
