import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";
  import React from "react";
  
  const ConfirmationPopUp = ({
    title,
    content,
    isOpen,
    handleClose,
    handleYes,
    handleNo,
  }) => {
    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{
          textAlign: "center",
          color: "var(--Red-rose-600, rgba(225, 29, 72, 1))",
          "& .MuiPaper-root": {
            borderRadius: "20px",
            width: "450px",
          },
        }}
      >
        <DialogTitle sx={{ fontSize: "1rem", fontWeight: "bold" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" sx={{ width: "100px" }} onClick={handleYes}>
            Yes
          </Button>
          <Button
            variant="outlined"
            sx={{ width: "100px", borderColor: "primary", color: "black" }}
            onClick={handleNo}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ConfirmationPopUp;
  