import React, { useRef, useState } from "react";
import Layout from "../../components/layout/layout";
import { useLocation, useNavigate } from "react-router-dom";
import GeneralButton from "../../components/button/GeneralButton";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import FinancialDetails from "./FinancialDetails";
import BasicDetails from "./BasicDetails";
import { Box } from "@mui/system";
import { BUTTON_COLOUR } from "../../constants/Constant";
import "../../components/projectDetails/projectDetails.css";
import ApprovedComments from "../../components/approvedComments/approvedComments";

function AddFormMain() {
  const formTitleValue = "Add Project";
  const [tabValue, setTabValue] = useState("basicDetails");
  const navigate = useNavigate();
  const basicFormRef = useRef(null);
  const location = useLocation();
  const { action, data } = location.state || {};
  const [sendAndApprovalButton, setSendAndApprovalButton] = useState("");
  const financialFormRef = useRef(null);
  const [selectRecommendation, setSelectRecommendation] = useState(false);
  // console.log("financialFormRef", financialFormRef);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (buttonName) => {
    if (buttonName === "Send for Approval") {
      if (financialFormRef.current) {
        financialFormRef.current.requestSubmit(); 
      }
      // navigate("/success");
    } else if (buttonName === "Save & Continue") {
      basicFormRef.current.requestSubmit();
    }
  };

  const saveContinueBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick("Save & Continue"),
    name: "Save & Continue",
  };

  const sendApprovalBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick("Send for Approval"),
    name: "Send for Approval",
    disable : !selectRecommendation
  };

  const handleTabChange = (name) => {
    setTabValue(name);
  };

  return (
    <Layout componentName={formTitleValue}>
      <TabContext value={tabValue}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              width: "99%",
              marginBottom: "1rem",
              marginLeft: "5px",
              marginRight: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: "16px",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="form tabs"
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                "& .MuiTab-root": {
                  minWidth: "auto",
                },
              }}
            >
              <Tab
                label="Basic Details"
                value="basicDetails"
                sx={{ fontWeight: "500" }}
              />
              <Tab
                label="Financial Details"
                value="financialDetails"
                sx={{ fontWeight: "500" }}
              />
             
            </TabList>
          </Box>
        </Box>
        <Box>
          <TabPanel
            value="basicDetails"
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              width: "97%",
            }}
          >
            <BasicDetails
              basicFormRef={basicFormRef}
              formTitleValue={formTitleValue}
              handleTabChange={handleTabChange}
              mode ={"add"}
            />
          </TabPanel>
          <TabPanel
            value="financialDetails"
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              width: "97%",
            }}
          >
            <FinancialDetails
              financialFormRef={financialFormRef}
              setSendAndApprovalButton={setSendAndApprovalButton}
              mode ={"add"}
              selectRecommendation = {selectRecommendation}
              setSelectRecommendation = {setSelectRecommendation}
            />
          </TabPanel>
        </Box>
      </TabContext>

      <div className="btn_alignment">
        <Box>
          {tabValue === "basicDetails" && (
            <GeneralButton {...saveContinueBtnProps} />
          )}
          {tabValue === "financialDetails" && (
            <GeneralButton {...sendApprovalBtnProps} />
          )}
        </Box>
      </div>
    </Layout>
  );
}

export default AddFormMain;
