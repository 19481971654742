import React from "react";
import AppRoutes from "./Routes";
import useAuth from "./auth/useAuth";
import Modal from "../src/auth/Modal";

function App() {
  const { isTokenExpired, handleLogout } = useAuth();

  return (
    <>
      {isTokenExpired && (
        <Modal
          title="Session Expired"
          message="Your session has expired. Please log in again."
          onClose={handleLogout}
          show={isTokenExpired}
        />
      )}
      <AppRoutes />
    </>
  );
}

export default App;
