import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React from 'react'

const RejectedPopUp = (props) => {
  const { showRejected,  handleCloseRejected, title, description, setReasonText, reasonText, handleYes, handleNo } = props
  
  return (
    <Dialog
    open={showRejected}
    onClose={handleCloseRejected}
    sx={{
      textAlign: "center",
      color: "var(--Red-rose-600, rgba(225, 29, 72, 1))",
      "& .MuiPaper-root": {
        borderRadius: "20px",
      },
    }}
    fullWidth
  >
    <DialogTitle
      sx={{ color: "var(--Red-rose-600, rgba(225, 29, 72, 1))" }}
    >
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText
        sx={{
          textAlign: "center",
          color: "var(--Teal-teal-600, rgba(13, 148, 136, 1))",
        }}
      >
        { description }
      </DialogContentText>
      <TextField
        autoFocus
        required
        margin="dense"
        id="reason"
        name="reason"
        fullWidth
        variant="outlined"
        multiline
        maxRows={4}
        minRows={4}
        onChange={(e) => setReasonText(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root": {
            overflow: "auto",
          },
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
        }}
      />
    </DialogContent>
    <DialogActions
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Button
        variant="contained"
        sx={{ width: "130px" }}
        onClick={handleYes}
        disabled={!reasonText}
      >
        Yes
      </Button>
      <Button
        variant="outlined"
        sx={{ width: "130px", borderColor: "primary", color: "black" }}
        onClick={handleNo}
      >
        No
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default RejectedPopUp