import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const SummaryFilters = () => {
  const statusData = {
    id: "status",
    label: "Status",
    data: [
      { label: "Completed", value: "Completed" },
      { label: "On Track", value: "On Track" },
      { label: "Delayed", value: "Delayed" },
      { label: "At Risk", value: "At Risk" },
    ],
  };

  const projectData = {
    id: "Project",
    label: "Project",
    data: [
      { label: "Rentals", value: "Rentals" },
      { label: "Android development", value: "Android development" },
      { label: "Agile Project", value: "Agile Project" },
      { label: "Media Channel", value: "Media Channel" },
    ],
  };

  const projectManager = {
    id: "ProjectManager",
    label: "Project Manager",
    data: [
      { label: "Abhishek", value: "Abhishek" },
      { label: "Suresh", value: "Suresh" },
      { label: "Mohnish", value: "Mohnish" },
      { label: "Suman", value: "Suman" },
    ],
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box sx={{ minWidth: 150, marginRight: "1rem" }}>
        <FormControl fullWidth size="small">
          <InputLabel id={`project-summary-${projectData?.id}`}>
            {projectData?.label}
          </InputLabel>
          <Select
            labelId={`project-summary-label-${projectData?.id}`}
            id={`project-summary-Id-${projectData?.id}`}
            label={projectData?.label}
          >
            {projectData?.data.map((item) => (
              <MenuItem value={item?.value}>{item?.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ minWidth: 180, marginRight: "1rem" }}>
        <FormControl fullWidth size="small">
          <InputLabel id={`project-manager-${projectManager?.id}`}>
            {projectManager?.label}
          </InputLabel>
          <Select
            labelId={`project-manager-label-${projectManager?.id}`}
            id={`project-manager--${projectManager?.id}`}
            label={projectManager?.label}
          >
            {projectManager?.data.map((item) => (
              <MenuItem value={item?.value}>{item?.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth size="small">
          <InputLabel id={`project-summary-${statusData?.id}`}>
            {statusData?.label}
          </InputLabel>
          <Select
            labelId={`project-summary-label-${statusData?.id}`}
            id={`project-summary-Id-${statusData?.id}`}
            label="Status"
          >
            {statusData?.data.map((item) => (
              <MenuItem value={item?.value}>{item?.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default SummaryFilters;
