import "./AuthLayout.css";
import iitp_logo from "../../assets/images/iitp_logo.png";
import fb from "../../assets/footer/fb.png";
import linkidin from "../../assets/footer/linkidin.png";
import location from "../../assets/footer/location.png";
import email from "../../assets/footer/email.png";
import phone from "../../assets/footer/phone.png";
import footerCollegelogo from "../../assets/footer/footerCollegelogo.png";
import { Typography } from "@mui/material";

const AuthFooter = () => {
  return (
    <div className="footerMain">
      <div className="firstHalf">
        <div className="footerFIrstCOntent">
          <div className="footerAddress">
            <img src={footerCollegelogo} alt="iitp logo" />

            <Typography variant="subtitle2" className="footer_p_tag_2">
              Privacy Policy | Terms & Conditions
            </Typography>
          </div>
          <div className="footer_social_links">
            <a
              href="https://www.facebook.com/iitp.ac.in/"
              target="_blank"
            >
              <img src={fb} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/school/indian-institute-of-technology-patna/?originalSubdomain=in"
              target="_blank"
            >
              <img src={linkidin} alt="" />
            </a>
          </div>
        </div>
        <div className="footerFIrstCOntent">
          <a
            href="https://www.google.com/maps/place/4th+Cross+Rd,+Aswath+Nagar,+Marathahalli,+Bengaluru,+Karnataka+560037/@12.9585016,77.7021339,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae1232e354bd33:0xb08f8e3b10c63ea3!8m2!3d12.9585016!4d77.7021339!16s%2Fg%2F11thlg9ny9?entry=ttu"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div className="address_card">
              <img src={location} alt="" />
              <Typography variant="caption" className="footer_p_tag">
                4th Cross, Ramanjaneya Layout,
                <br />
                Marathahalli, Bangalore, India.
              </Typography>
            </div>
          </a>

          <div className="address_card">
            <img src={email} alt="" />
            <Typography
              variant="caption"
              className="footer_p_tag"
              style={{ marginTop: 0 }}
            >
              <a
                href="mailto:sales@areteminds.com"
                target="_blank"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                sales@areteminds.com
              </a>
            </Typography>
          </div>

          <a
            href="tel:9663205304"
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <div className="address_card">
              <img src={phone} alt="" />
              <Typography className="footer_p_tag" style={{ marginTop: 0 }}>
                +91 9663205304
              </Typography>
            </div>
          </a>
        </div>
      </div>
      <div className="centeredText">
        <Typography
          variant="caption"
          style={{ color: "#FFF", fontSize: "14px" }}
        >
          Copyright © 2020 | Powered by AreteMinds Technologies Private Limited
        </Typography>
      </div>
    </div>
  );
};

export default AuthFooter;
