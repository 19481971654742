import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { CreateClients } from "../../api/ApiService";
import { validateClientForm } from "../../utils/validations";

export const ClientForm = ({
  isOpen,
  handleClose,
  name,
  setClientName,
  fetchClients,
  clientData,
}) => {
  const formRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({
    clientName: "",
    clientAddress: "",
    clientType: "",
  });

  const addClients = async (reqBody) => {
    try {
      const response = await CreateClients(reqBody);
      console.log("response----->", response);
      if (response?.status === 200) {
        setClientName("");
        handleClose();
        fetchClients();
      } else {
        console.log("error in creating clients");
      }
    } catch (error) {
      console.log("error in creating clients--->", error);
      handleClose();
    }
  };

  const submitClientForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const newErrors = validateClientForm(formValues);

    if (Object.keys(newErrors)?.length > 0) {
      setErrors(newErrors);
    } else {
      const reqBody = {
        ...formValues,
        status: true,
      };

      addClients(reqBody);
    }
  };

  return (
    <Dialog
      open={isOpen[name]}
      onClose={handleClose}
      sx={{
        textAlign: "center",
        color: "var(--Red-rose-600, rgba(225, 29, 72, 1))",
        "& .MuiPaper-root": {
          borderRadius: "20px",
          width: "420px",
        },
      }}
    >
      <DialogTitle sx={{ fontSize: "1rem", fontWeight: "bold" }}>
        Add Client
      </DialogTitle>
      <form onSubmit={submitClientForm}>
        <DialogContent>
          <DialogContentText sx={{ paddingTop: "5px" }}>
            <TextField
              name="clientName"
              label="Client Name"
              size="small"
              value={formValues.clientName}
              onChange={(e) =>
                setFormValues({ ...formValues, clientName: e.target.value })
              }
              error={!!errors?.clientName}
              helperText={errors?.clientName}
              sx={{
                width: "300px",
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                },
              }}
            />
            <TextField
              name="clientAddress"
              label="Address"
              size="small"
              value={formValues.clientAddress}
              onChange={(e) =>
                setFormValues({ ...formValues, clientAddress: e.target.value })
              }
              error={!!errors?.clientAddress}
              helperText={errors?.clientAddress}
              sx={{
                width: "300px",
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                },
              }}
            />
            <Select
              name="clientType"
              label="Client Type"
              size="small"
              value={formValues.clientType}
              onChange={(e) =>
                setFormValues({ ...formValues, clientType: e.target.value })
              }
              error={!!errors?.clientType}
              sx={{
                width: "300px",
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                },
              }}
            >
              <MenuItem value="" disabled>
                Select Client Type
              </MenuItem>
              <MenuItem value="INDUSTRY">INDUSTRY</MenuItem>
              <MenuItem value="ACADEMIC">ACADEMIC</MenuItem>
              <MenuItem value="GOVERNMENT">GOVERNMENT</MenuItem>
              <MenuItem value="OTHER">OTHER</MenuItem>
            </Select>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingBottom: "10px",
          }}
        >
          <Button variant="contained" sx={{ width: "100px" }} type="submit">
            Submit
          </Button>
          <Button
            variant="outlined"
            sx={{ width: "100px", borderColor: "primary", color: "black" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
