import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Typography } from "@mui/material";
import Layout from "../../components/layout/layout";
import EmployeeBasicDetails from "./EmployeeBasicDetails";
import EmployeeOtherDetails from "./EmployeeOtherDetails";
import PageLoader from "../../components/Loader/PageLoader";
import {
  GetAllEmployeeListById,
  GetAssignedDesignation,
  GetDepartment,
  GetGender,
} from "../../api/ApiService";

const EditEmployee = () => {
  const [selectedTab, setSelectedTab] = useState("1");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [basicDetails, setBasicDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    gender: "",
  });
  const [otherDetails, setOtherDetails] = useState({
    department: "",
    designation: "",
    assignedDesignation: "",
  });

  const [genderData, setGenderData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [assignedDesignationData, setAssignedDesignationData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          employeeResponse,
          assignedDesignationResponse,
          departmentResponse,
          genderResponse,
        ] = await Promise.all([
          GetAllEmployeeListById(id),
          GetAssignedDesignation(),
          GetDepartment(),
          GetGender(),
        ]);

        const employeeData = employeeResponse.data;
        const genderField = employeeData.userDetails.find(
          (item) => item.userFieldData.fieldName === "gender"
        );
        const departmentField = employeeData.userDetails.find(
          (item) => item.userFieldData.fieldName === "department"
        );
        const designationField = employeeData.userDetails.find(
          (item) => item.userFieldData.fieldName === "designation"
        );
        const assignedDesignationField = employeeData.userDetails.find(
          (item) => item.userFieldData.fieldName === "Assigned_designation"
        );

        setBasicDetails({
          firstName: employeeData.user.firstName,
          lastName: employeeData.user.lastName,
          email: employeeData.user.email,
          mobileNumber: employeeData.user.mobileNumber,
          gender: genderField?.userFieldData.fieldValue || "",
        });
        setOtherDetails({
          department: departmentField?.userFieldData.fieldValue || "",
          designation: designationField?.userFieldData.fieldValue || "",
          assignedDesignation:
            assignedDesignationField?.userFieldData.fieldValue || "",
        });
        setGenderData(genderResponse.data);
        setDepartmentData(departmentResponse.data);
        setDesignationData(departmentResponse.data);
        setAssignedDesignationData(assignedDesignationResponse.data);
      } catch (error) {
        console.error("Failed to fetch data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUpdateSuccess = (tabValue) => {
    setSelectedTab(tabValue);
  };

  return (
    <div>
      <Layout
        componentName={`${basicDetails.firstName} ${basicDetails.lastName}`}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "85%",
          }}
        >
          <Box>
            <TabContext value={selectedTab}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  sx={{
                    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    width: "99%",
                    marginBottom: "1rem",
                    marginLeft: "5px",
                    marginRight: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingRight: "16px",
                  }}
                >
                  <TabList
                    onChange={handleTabChange}
                    aria-label="Employee Info Tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      label={
                        <Typography fontWeight="bold">Basic Details</Typography>
                      }
                      value="1"
                    />
                    <Tab
                      label={
                        <Typography fontWeight="bold">
                          Other Information
                        </Typography>
                      }
                      value="2"
                    />
                  </TabList>
                </Box>
              </Box>

              <TabPanel value="1">
                <EmployeeBasicDetails
                  onUpdateSuccess={() => handleUpdateSuccess("2")}
                  genderData={genderData}
                />
              </TabPanel>
              <TabPanel value="2">
                <EmployeeOtherDetails
                  data={otherDetails}
                  setOtherDetails={setOtherDetails}
                  departmentData={departmentData}
                  designationData={designationData}
                  assignedDesignationData={assignedDesignationData}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Layout>
      {loading && <PageLoader />}
    </div>
  );
};

export default EditEmployee;
