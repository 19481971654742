import {
  IconButton,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import deleteIcon from "../../assets/images/delete.png";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationPopUp from "../../components/conifrmationPopUp/confirmationPopUp";
import DocumentSelection from "../../components/documentUplode/DocumentSelection";
import ScrollButtonTopAndDown from "../../components/button/ScrollButtonTopAndDown";
import Autocomplete from "@mui/material/Autocomplete";
import { AddProject, DownloadFiles, GetClients, GetDepartment, GetProjectById, saveConsultant, updateBasicForm } from "../../api/ApiService";
import { ClientForm } from "./clientForm";
import { decodeJWT } from "../../constants/StoredToken";
import Dropdown from "../../components/dropdown/dropdown";
import { minHeight, minWidth } from "@mui/system";
import { validateBasicForm } from "../../utils/validations";
import PageLoader from "../../components/Loader/PageLoader";
import "../../components/projectDetails/projectDetails.css";

const styleProps = {
  minWidth: "350px",
  minHeight: "35px",
  borderRadius: "15px",
  marginBottom: "16px",
};

function BasicDetails(props) {
  const { basicFormRef, formTitleValue,handleTabChange, id, mode } = props;
  
  const location = useLocation();
  const { action, data } = location.state || {};
  const [selectedTab, setSelectedTab] = useState(
    action === "reject" ? "financialDetails" : "basicDetails"
  );

  console.log("formTitleValue---->",formTitleValue);
  

  const [fileList, setFileList] = useState([0]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleUpload, setIsModalVisibleUpload] = useState(false);
  const role = localStorage.getItem("role");

  const readOnlyMode = mode === "view" || mode === "approve" ;
  console.log("readOnlyMode--->",readOnlyMode);
  
  const initialValues = {
    projectName: data?.NameOfProject || "",
    clientAddress: data?.nameOfClient || "",
    consultantInCharge: data?.nameOfConsultantInCharge || "",
    otherConsultant: data?.otherConsultant || "",
    nameOfDept: data?.DepartmentName || "",
    typeOfClient: data?.typeOfClient || "",
    startDate: data?.Date ? dayjs(data.Date) : null,
    endDate: data?.completionDate ? dayjs(data.completionDate) : null,
  };
  const [formDatas, setFormDatas] = useState({});
  // const [errors, setErrors] = useState({});
  const [addField, setAddField] = useState([]);
  const [addConsultantInChargeField, setAddConsultantInChargeField] = useState(
    []
  );
  const [consultantType, setConsultantType] = useState("Internal");
  const [isOpen, setIsOpen] = useState({
    approveConfirmation: false,
    successPopUp: false,
    consultantType: false,
    deleteConsultantInCharge: false,
    otherConsultant: false,
    deleteExtraExp: false,
    showExpForm: false,
    consultantInCharge: false,
    showDelConfirmPopUp: false,
    addDocument: false,
    sendForApproval: false,
    addBudgetDetails: false,
    showDeleteAddBudget: false,
    showClientForm: false,
  });

  const deleteIndexRef = useRef(null);
  const deleteConsultantIndex = useRef(null);
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectRecommendation, setSelectRecommendation] = useState(
    data?.financialDetails?.recommendation
      ? data?.financialDetails?.recommendation
      : false
  );

  const initialFinanceValues = {
    totalExpenditure: data?.financialDetails?.totalExpenditure || "",
    gst: data?.financialDetails?.gst || "",
    consultancyFee: data?.financialDetails?.consultancyFee || "",
  };

  const [financeformValues, setFinanceFormValues] =
    useState(initialFinanceValues);

  const [extraExpDetails, setExtraExpDetails] = useState({
    id: 2,
    expenditureName: "",
    amount: "",
  });
  
  const [totalExp, setTotalExp] = useState(0);

  const [otherExpenses, setOtherExpenses] = useState([]);
  const [clientData, setClientData] = useState([]);
  const navigate = useNavigate();
  const [clientName, setClientName] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedClientData, setSelectedClientData] = useState({});
  const [departmentData, setDepartmentData] = useState([]);
  const [selectDept, setSelectDept] = useState();
  const token = localStorage.getItem("jwtToken");
  const userDetails = decodeJWT(token)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ formValues, setFormValues ] = useState({})
  const [ isEdit, setIsEdit ] = useState({
    consultantInCharge : true ,
    otherConsultantIncharge: true
  })
  const [documents , setDocuments] = useState("")


  const handleChange = (event, newValue) => {
    setClientName(newValue);
    const clientExists = clientData.some((option) => {
      if (option.clientName === newValue) {
        setSelectedClientData(option);
        return true;
      }
    });
    if (!clientExists && newValue) {
      setErrors({
        ...errors,
        clientName: "Client doesn't exist, Please add Client",
      });
    } else {
      setErrors({ ...errors, clientName: "" });
    }
  };

  // const initializeValue = () => {
  //   if (data?.financialDetails?.consultancyProject?.length > 0) {
  //     setSelectedOptions(data?.financialDetails?.consultancyProject);
  //   }
  // };

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const fetchClients =async() => {
    try{
      setIsLoading(true)
      const response = await GetClients();
      if(response?.data){
        setClientData(response?.data)
        setIsLoading(false)
      }
      console.log("setClientData response---->",response);
    }catch(error){
      setClientData([])
      setIsLoading(false)
      console.log("error in fetching clients :",error);
    }
  };

  const fetchDepartmentData = async () => {
    try {
      setIsLoading(true)
      const response = await GetDepartment();
      if (response && response?.status === 200) {
        const data = response?.data;
        const modfiedData = data.map((item) => {
          return {
            label: item?.fieldValue,
            value: item?.id,
          };
        });
        setDepartmentData(modfiedData);
      } else {
        console.error("Failed to fetch department data");
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.error("Error fetching department data:", err);
    }
  };

  const fetchFormDetails =async () => {
    setIsLoading(true)
    const response = await  GetProjectById(id)
    console.log("response---->",response);

    if(response?.status === 200){
      const details = response?.data?.project
      const existingDocuments = response?.data?.files.map((item) => {
        return {
          name : item?.documentName ,
          path : item?.documentPath
        }
      }) 
      setFileList(existingDocuments)

      setSelectedClientData(details?.clientId)
      setClientName(details?.clientId?.clientName);
      const projRevenueId = details?.projectRevenue?.id
      if(projRevenueId){
        localStorage.setItem("projRevenueId",projRevenueId)
      }
      const consultant_pi = details?.projectConsultants.find(item => item.consultantType === 'PI') || {} ;
      const consultant_others = details?.projectConsultants.filter(item => 
        item?.consultantType == 'Internal' || item?.consultantType == 'External' ) || []
  
      const consultant_co_pi =  details?.projectConsultants.filter(item => item?.consultantType == 'CoPI' ) || []
  
      console.log("consultant_pi--->",consultant_pi);
      console.log("consultant_others---->",consultant_others);
      console.log("consultant_co_pi---->",consultant_co_pi);
      
      setAddConsultantInChargeField(consultant_co_pi.map((item) => {
        return {
          id: item?.id, 
          consultantInCharge: item?.consultantName,
          isEdit: true,
        }
      }))

      setConsultantType(consultant_others[0]?.consultantType || "")
      
      if(consultant_others?.length > 1){
        const updatedData = consultant_others.slice(1)
        
        updatedData.map((item) => {
          
          addField.push({
            otherConsultantName : item?.consultantName , 
            consultantType : item?.consultantType ,
            id: item?.id,
            isEdit: true,
          })
        })
      }
      
      const clientId = details?.clientId?.clientId
      const clientDetails = clientData.find((item) => item?.clientId === clientId)
  
      console.log("clientDetails---->",clientDetails);
      // setClientName(clientDetails?.clientName || "")
      setFormDatas({
        projectName: details?.title,
        clientName: clientDetails?.clientName ,
        clientAddress: clientDetails?.clientAddress , 
        clientType: clientDetails?.clientType ,
        nameOfDept: details?.userFieldData?.id, 
        projectId: details?.projectId ,
        startDate: dayjs(details?.startDate) , 
        endDate: dayjs(details?.endDate),
        consultantInCharge : consultant_pi?.consultantName ,
        consultantPiId: consultant_pi?.id ,
        otherConsultant : consultant_others[0]?.consultantName ,
        otherConsultantId:  consultant_others[0]?.id,
        consultantDetails: details?.projectConsultants,
      })
      setIsLoading(false)
    }
  }

  const callApis = async() => {
    await fetchClients();
    await fetchDepartmentData();
    if(mode === "edit" || mode === "view" || mode === "approve"){
      await fetchFormDetails()
    }
  }

  useEffect(() => {
    callApis()
  }, []);

  const calculateTotal = () => {
    const form = document.querySelector("form");
    const formData = new FormData(form);
    const amount = parseFloat(formData.get("otherExpenditure"));
    const value = otherExpenses.reduce(
      (total, expenditure) => total + parseFloat(expenditure.amount),
      0
    );

    const totalValue = value + amount;
    setTotalExp(totalValue);
    return value;
  };


  const addConsultant = () => {
    setIsOpen({ otherConsultant: true });
  };

  const handleConsultantType = (e, index) => {
    const value = e.target.value;
    if (consultantType !== value) {
      setIsOpen({ consultantType: true });
    }
  };

  const handleClose = (name) => {
    setIsOpen({ name: false });
  };

  const handleConfirmationYes = () => {
    const maxId = addField.reduce(
      (max, field) => (field.id > max ? field.id : max),
      1
    );
    const newId = maxId + 1;    
    setAddField([
      ...addField,
      { id: newId, otherConsultantName: "", consultantType: "Internal"},
    ]);
    setIsOpen({ otherConsultant: false });
  };

  const handleConfirmationConsultantInCharge = () => {
    const maxId = addConsultantInChargeField.reduce(
      (max, field) => (field.id > max ? field.id : max),
      1
    );
    const newId = maxId + 1;
    setAddConsultantInChargeField([
      ...addConsultantInChargeField,
      { id: newId, consultantInCharge: "", isEdit: false },
    ]);
    setIsOpen({ consultantIncharge: false });
  };

  const handleConfirmationNo = (name) => {
    setIsOpen({ name: false });
  };

  const handleConfConsultantType = () => {
    setConsultantType((prevType) =>
      prevType === "Internal" ? "External" : "Internal"
    );
    setIsOpen({ name: false });
  };

  const handleDelete = (index) => {
    deleteIndexRef.current = index;
    setIsOpen({ deleteConsultantInCharge: true });
  };

  const [showRejected, setShowRejected] = useState(false);

  const handleDeleteOtherConsultantIncharge = (index) => {
    deleteConsultantIndex.current = index;
    setIsOpen({ showDelConfirmPopUp: true });
  };

  const handleDleteConsultantInCharge = (index) => {
    const updatedTextFields = [...addField];
    updatedTextFields.splice(index, 1);
    setAddField([...updatedTextFields]);

    setIsOpen({ deleteConsultantInCharge: false });
  };

  const handleDeleteOtherConsIncharge = (index) => {
    const updatedTextFields = [...addConsultantInChargeField];
    updatedTextFields.splice(index, 1);
    setAddConsultantInChargeField([...updatedTextFields]);

    setIsOpen({ showDelConfirmPopUp: false });
  };

  const handleOtherConsultant = (e, index) => {
    const value = e.target.value;
    console.log("value--->",value);

    const updatedValue = addField
    updatedValue[index].otherConsultantName = value
    setAddField([...updatedValue]);
  };

  const handleOtherConsultantIncharge = (e, index) => {
    const value = e.target.value;
    addConsultantInChargeField[index].consultantInCharge = value;
    setAddConsultantInChargeField([...addConsultantInChargeField]);
  };

  const handleOtherConsultantType = (e, index, current) => {
    const value = e.target.value;
    addField[index]["consultantType"] = value;
    setAddField([...addField]);
  };

  const addConsultantInCharge = () => {
    setIsOpen({ consultantInCharge: true });
  };

  const handleFileChangeUpload = (file, index) => {
    const newFileList = [...fileList];
    newFileList[index] = file;
    setFileList(newFileList);
  };

  const handleOnCancelIsVisibleUpload = () => {
    setIsModalVisibleUpload(false);
  };

  const handleOkUpload = (index) => {
    setIsModalVisible(false);
    setIsOpen({ ...isOpen, uploadDocument: false });

    const newFileList = [...fileList];
    newFileList.splice(index);
    setFileList(newFileList);
  };

  const handleCancelUpload = () => {
    setIsModalVisible(false);
  };

  const addUploadInputUpload = () => {
    setFileList([...fileList, null]);
    setIsModalVisibleUpload(false);
    setIsOpen({ ...isOpen, addDocument: false });
  };

  const documentProps = (index) => ({
    name: "file",
    fileList: [],
    beforeUpload: (file) => {
      handleFileChangeUpload(file, index);
      return false;
    },

    accept: ".pdf,.PDF,application/pdf",
    showUploadList: false,
  });

  const handleFirstDeleteUploadFile = (index) => {
    setIsModalVisible(false);
    setIsOpen({ ...isOpen, uploadDocument: false });
    setFileList([0]);
  };

  const handleConfirmationApproval = () => {
    setIsOpen({ ...isOpen, sendForApproval: false });
    navigate("/projects");
  };

  const addClient = () => {
    setIsOpen({ ...isOpen, showClientForm: true });
  };

  const convertDateFormat = (value) => {
    const date = value.replace(/\//g, "-");
    const [day, month, year] = date.split("-");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const updateFormHandler = async(e) => {
    try{
      console.log("update form");
      const formData = new FormData(e.target);
      const formValues = Object.fromEntries(formData.entries());
      console.log("formValues---->",formValues);
      
      const addedValues = {...formValues,clientAddress: selectedClientData?.clientAddress ,clientType: selectedClientData?.clientType, consultantInCharge: formDatas?.consultantInCharge, otherConsultant: formDatas?.otherConsultant , consultantType: consultantType }    
  
      console.log("added values----->",addedValues);
      
  
      const isValid = validateBasicForm(addedValues)
      console.log("isValid---->",isValid);
      setErrors(isValid);


      console.log(Object.keys(isValid).length);
      
      if (Object.keys(isValid).length === 0){
        console.log("inisde condition", addedValues);
        const projectId = formDatas?.projectId
        
        const reqParams = {
          title: addedValues?.projectName,
          clientId: selectedClientData?.clientId,
          departmentId: addedValues?.nameOfDept ,
          startDate: "2024-08-21",
          endDate: "2024-08-25" ,
          projectOwnerId: userDetails?.userId,
          isActive: true,
          consultantName: addedValues?.consultantInCharge ,
          consultantType: "PI" ,
        }


        console.log("reqParams---->",reqParams);
        
        const queryParams = new URLSearchParams(reqParams).toString();
        console.log("queryParams---->",queryParams);
        

        let modifiedUrl = queryParams
    
        console.log("addConsultantInChargeField---->",addConsultantInChargeField);
        
        modifiedUrl = modifiedUrl + `&consultantName=${addedValues?.otherConsultant}&consultantType=${addedValues?.consultantType}`
          if(addConsultantInChargeField?.length > 0){
            addConsultantInChargeField.forEach(item => {
              modifiedUrl = modifiedUrl + `&consultantName=${item?.consultantInCharge}&consultantType=CoPI`;
          });
          }
      
          console.log("addField--->",addField);
          
          if(addField?.length > 0){
            addField.forEach(item => {
              modifiedUrl = modifiedUrl + `&consultantName=${item?.otherConsultantName}&consultantType=${item?.consultantType}`;
            })
          }

          modifiedUrl = `${projectId}?` + modifiedUrl 

          console.log("modifiedUrl---->",modifiedUrl);
          
          const formData = new FormData();

          console.log("file list---->",fileList);

          // const backendData = [];
          // const frontendFiles = [];

          // fileList.forEach(item => {
          //   if (item instanceof File) {
          //     frontendFiles.push(item);
          //   } else {
          //     backendData.push(item);
          //   }
          // });

          // const combinedPayload = frontendFiles.map(file => {
          //   const backendFile = backendData.find(doc => doc.name === file.name);
            
          //   return {
          //     uid: file.uid,
          //     name: file.name,
          //     path: backendFile?.path || '',  
          //     lastModified: file.lastModified,
          //     lastModifiedDate: file.lastModifiedDate,
          //     size: file.size,
          //     type: file.type
          //   };
          // });
          
          // console.log("combinedPayload----->",combinedPayload);
          
          

          fileList.map((item) => {
            formData.append("files", new File([''], item?.name))
          })

          const response = await updateBasicForm(modifiedUrl, formData);
          console.log("response---->",response);
          
          if(response?.status === 200){

            const projectId = response?.data?.project?.projectId;
            localStorage.setItem("projectId", projectId);
           handleTabChange("financialDetails")
            console.log("Updated basic form successfully");
          }
      }
    }catch(error){
      console.log("error ");
      
    }
  }

  const createFormHandler = async(e) => {
    console.log("create form");
    
    try{
      const formData = new FormData(e.target);
      const formValues = Object.fromEntries(formData.entries());
      console.log("formValues---->",formValues);
      
      const addedValues = {...formValues,clientAddress: selectedClientData?.clientAddress ,clientType: selectedClientData?.clientType, files: fileList}    
  
      const isValid = validateBasicForm(addedValues)
      console.log("isValid---->",isValid);
      setErrors(isValid);
  
      if (Object.keys(isValid).length === 0){
        const consultantInChargeKeys = Object.keys(formValues).filter(key => key.startsWith('consultantInCharge_'));
        const consultantInChargeValues = consultantInChargeKeys.map(key => formValues[key]);
    
        const startDt = convertDateFormat(formValues?.startDate) ;
        const endDt =  convertDateFormat(formValues?.endDate);
        
        const reqParams = {
          title: addedValues?.projectName,
          clientId: selectedClientData?.clientId,
          address: addedValues?.clientAddress,
          clientType: addedValues?.clientType ,
          departmentId: addedValues?.nameOfDept ,
          startDate: startDt,
          endDate: endDt ,
          projectOwnerId: userDetails?.userId,
          isActive: true,
          consultantTypeRequestDTOList : []
        }
  
        reqParams.consultantTypeRequestDTOList.push({
          consultantName : formValues?.consultantInCharge , 
          consultantType : "PI"
        })
  
        reqParams.consultantTypeRequestDTOList.push({
          consultantName : formValues?.otherConsultant , 
          consultantType : consultantType
        })
  
        if(consultantInChargeValues?.length > 0){
          consultantInChargeValues.map((item) => {
            reqParams.consultantTypeRequestDTOList.push({
              consultantName : item , 
              consultantType : "CoPI"
            })
          })
        }
  
  
        if(addField?.length > 0){ 
          addField.map((item) => {
            reqParams.consultantTypeRequestDTOList.push({
              consultantName : item?.otherConsultantName , 
              consultantType : item?.consultantType
            })
          })
        }
        
        console.log("fileList---->",fileList);
        const reqBody = JSON.stringify(reqParams);
        const formData = new FormData();
        const blob = new Blob([reqBody], { type: 'application/json' });
        formData.append("projectRequest", blob, 'projectRequest.json' );
        fileList.map((item) => {
          formData.append("files", item)
        })
        const response = await AddProject(formData);
        console.log("response--->",response);
        
          console.log("response--->",response);    
          if(response?.status === 200){
            const projectId = response.data.project.projectId;
            console.log("Project ID:", projectId);
            localStorage.setItem("projectId", projectId);
            console.log("Project created successfully");
            handleTabChange("financialDetails")
          }
     }}catch(error){
      console.log("error in creating submitting project", error);
     }
  }

  const goToNextTab = ()=>{

  }
  const submitHandler = (e) => {
    e.preventDefault()
    if(mode === "add"){
      createFormHandler(e)
    }else if(mode === "edit"){
      updateFormHandler(e)
    }else if(mode ==="Continue"){
      goToNextTab()
    }
  }
  

  const handleSelectDept = (e, newValue) => {
    setSelectDept(newValue)
  }
  
  const validateAddConsIncharge = () => {
    const emptyConsultant = addConsultantInChargeField.find(item => item.consultantInCharge === "")
    return emptyConsultant
  }
  

  const renderCond = () => {
    if(mode === "edit" || mode === "view" || mode ==="approve"){
      if(formDatas && Object.keys(formDatas).length > 0){
        return true
      }
    }else if(!isLoading && mode === "add"){
      return true
    }
  }
  

  const handleAction = (name , role, index, value, otherRoles) => {
    if(name === "edit" && role === "PI"){
      setIsEdit({...isEdit, consultantInCharge: false })
    }else if(name === "save" && role === "PI"){      
      const id =  formDatas?.consultantPiId 
      const projectId = formDatas?.projectId
      const consultantIncharge = formDatas?.consultantInCharge
      updateConsultant(id, projectId, consultantIncharge, role)
      setIsEdit({...isEdit, consultantInCharge: true })
    }else if(name === "edit" && role === 'CoPI'){
      const updateField = addConsultantInChargeField
      updateField[index].isEdit = false
      setAddConsultantInChargeField([...updateField])
    }else if(name === "save" && role === 'CoPI'){
      const updateField = addConsultantInChargeField
      updateField[index].isEdit = true
      setAddConsultantInChargeField([...updateField])


      const id =  addConsultantInChargeField[index]?.id
      const projectId = formDatas?.projectId
      const consultantIncharge = addConsultantInChargeField[index]?.consultantInCharge

      updateConsultant(id, projectId, consultantIncharge, role)
    }else if(name === "edit" && role !== 'CoPI' && role !== 'PI' && otherRoles !== "otherConsultant"){
      setIsEdit({...isEdit, otherConsultantIncharge: false })
    }else if(name === "save" && role !== 'CoPI' && role !== 'PI' && otherRoles !== "otherConsultant" ){
      const projectId = formDatas?.projectId
      const otherConsultant =  formDatas?.otherConsultant
      const id = formDatas?.otherConsultantId
      updateConsultant(id, projectId, otherConsultant, role)
      setIsEdit({...isEdit, otherConsultantIncharge: true })
    }else if( name === "edit" && role !== 'CoPI' && role !== 'PI' && otherRoles === "otherConsultant" ){
      console.log("clicked on edit");

      const updateField = addField
      updateField[index].isEdit = false
      setAddField([...updateField])
    }else if( name === "save" && role !== 'CoPI' && role !== 'PI' && otherRoles === "otherConsultant" ){

      const projectId = formDatas?.projectId
      const otherConsultant =  addField[index]?.otherConsultantName
      const id = addField[index]?.id      
      updateConsultant(id, projectId, otherConsultant, role)
      const updateField = addField
      updateField[index].isEdit = true
      setAddField([...updateField])
    }
  };


  const handleConsInCharge = (e) => {
    const value = e.target.value 
    setFormDatas({...formDatas, consultantInCharge : value })
  }


  const handleConsOtherCharge = (e) => {
    const value = e.target.value 
    setFormDatas({...formDatas, otherConsultant : value })
  }


const updateConsultant = async(id, projectId, consultantIncharge, role) => {
  try{
    console.log("id---->",id);
    console.log("projectId----->",projectId);
    console.log("consultantIncharge---->",consultantIncharge);
    const reqBody = {
      projectId: projectId,
      consultantName: consultantIncharge,
      consultantType: role
    }
    console.log("reqBody---->",reqBody);
    const respone = await saveConsultant(id, reqBody)
    console.log("save response--->",respone);
  }catch(error){
    console.log("error in updating consultant--->",error);
  }
}

  
  
  console.log("addConsultantInChargeField---->",addConsultantInChargeField);

  console.log("addfiled00000---->",addField);


  const getDownloadFiles = async(name) => {
    try{
      const response = await DownloadFiles(name)
      console.log("files response--->",response);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documents);
      document.body.appendChild(link);
      link.click();
      

    }catch(error){
      console.log("error in downloading files", error);
    }
  }
  
  
  
  return (

    <form ref={basicFormRef}  onSubmit={submitHandler} >
      {isLoading && <PageLoader loadingStatus={isLoading} />}
      {renderCond() &&
      <>
      <Box className="form_container scroll-container">
        <Typography
          sx={{ fontWeight: "bold", marginBottom: "16px" }}
          id="basicDetailTop"
        >
          DETAILS OF THE CONSULTANCY PROJECT
        </Typography>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            1. Title of the Consultancy Project
          </Typography>
          <span className="field_align">:</span>
          {console.log("proj name-->",formDatas?.projectName)}
          <TextField
            name="projectName"
            defaultValue={formDatas?.projectName}
            error={!!errors?.projectName}
            helperText={errors?.projectName}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
            disabled={readOnlyMode}
          />
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            2. Name of the Client
          </Typography>
          <span className="field_align">:</span>
          <div style={{display:"flex", flexDirection:'row', position:'relative' }}>
            {console.log("clientName--->", formDatas?.clientName)}
          <Autocomplete
            id="clientName"
            freeSolo
            defaultValue={clientName}
            options={clientData && clientData?.length > 0 && clientData.map((option) => option?.clientName)}
            value={clientName}
            onInputChange={handleChange}
            disabled={readOnlyMode}
            renderInput={(params) =>
              <TextField 
               {...params} 
               name="clientName"
               error={!!errors.clientName}
               helperText={errors.clientName}
               sx={{
                 width: "350px",
                 marginBottom: "16px",
                 "& .MuiInputBase-root": {
                   height: "35px",
                   borderRadius: "15px",
                 },
               }}
              />
          }
          />
            {!readOnlyMode && <IconButton
                color="primary"
                aria-label="add client"
                size="large"
                onClick={addClient}
                sx={{
                  // padding:'0px',
                  // margin:'0px',
                  position: "absolute",
                  top: -10,
                  left: 350,
                }}
              >
                <AddCircleIcon fontSize="large" />
              </IconButton>
            }
            {/* show client form */}
            {isOpen?.showClientForm && (
              <ClientForm
                isOpen={isOpen}
                handleClose={handleClose}
                name={"showClientForm"}
                setClientName={setClientName}
                fetchClients={fetchClients}
                clientData={clientData}
              />
            )}
          </div>
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            3. Address
          </Typography>
          <span className="field_align">:</span>
          {console.log("clientAddress---->",formDatas?.clientAddress)}
          <TextField
            name="clientAddress"
            defaultValue={formDatas?.clientAddress}
            value={selectedClientData?.clientAddress}
            error={!!errors?.clientAddress}
            helperText={errors?.clientAddress}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
            disabled={true}
          />
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            4. Type of Client (Industry/Government)
          </Typography>
          <span className="field_align">:</span>
          <TextField
            name="clientType"
            defaultValue={formDatas?.clientType}
            value={selectedClientData?.clientType}
            error={!!errors.clientType}
            helperText={errors.clientType}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
            disabled={true}
          />
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            5. Name of the Consultant-in-Charge{" "}
          </Typography>
          <span className="field_align">:</span>
          <div className="cons_in_charge_container">
            <div className="cons_field">
              <TextField
                name="consultantInCharge"
                defaultValue={formDatas?.consultantInCharge}
                error={!!errors.consultantInCharge}
                helperText={errors.consultantInCharge}
                onChange={(e) => {handleConsInCharge(e)}}
                sx={{
                  width: "350px",
                  marginBottom: "16px",
                  "& .MuiInputBase-root": {
                    height: "35px",
                    borderRadius: "15px",
                  },
                }}
                disabled={isEdit?.consultantInCharge && mode !== 'add'}
              />
            {mode !== 'add' && !readOnlyMode ?
              isEdit?.consultantInCharge ?
                <Button variant="outlined" size="small" onClick={()=>handleAction("edit", "PI",null,formDatas?.consultantInCharge)} sx={{height:'30px'}}>
                  Edit
                </Button> :
                <Button variant="outlined" size="small" onClick={() => handleAction("save" ,"PI")} sx={{height:'30px'}}>
                  Save
                </Button> : ""
            }
            </div>
            {addConsultantInChargeField?.length > 0 &&
              addConsultantInChargeField.map((item, index) => (
                <div key={`consultantInCharge_${index}`}>
                  {console.log("cons in charge index--->", item)}
                  <TextField
                    name={`consultantInCharge_${index}`}
                    value={item?.consultantInCharge}
                    error={!!errors[`consultantInCharge_${index}`]}
                    helperText={errors[`consultantInCharge_${index}`]}
                    onChange={(e) => {
                      handleOtherConsultantIncharge(e, index);
                    }}
                    defaultValue={item?.consultantInCharge}
                    sx={{
                      width: "350px",
                      marginBottom: "16px",
                      "& .MuiInputBase-root": {
                        height: "35px",
                        borderRadius: "15px",
                      },
                    }}
                    disabled={item?.isEdit && mode !== 'add'}
                  />
                  {!readOnlyMode && <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteOtherConsultantIncharge(index)}
                    sx={{ marginLeft: "1rem" }}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </IconButton>}
                  {mode !== 'add' && !readOnlyMode ?
                  item?.isEdit ?
                    <Button variant="outlined" size="small" onClick={()=>handleAction("edit", "CoPi", index)} sx={{marginLeft: '20px'}}>
                    Edit
                    </Button> :
                    <Button variant="outlined" size="small" onClick={() => handleAction("save", "CoPi", index)} sx={{marginLeft: '20px'}}>
                      Save
                    </Button> : ""
                  }
                  {isOpen?.showDelConfirmPopUp &&
                    deleteConsultantIndex?.current === index && (
                      <ConfirmationPopUp
                        title={"Confirmation"}
                        content={"Do you really want to Delete?"}
                        setIsOpen={setIsOpen}
                        name={"showDelConfirmPopUp"}
                        isOpen={isOpen}
                        handleClose={() => handleClose("showDelConfirmPopUp")}
                        handleYes={() => handleDeleteOtherConsIncharge(index)}
                        handleNo={() =>
                          handleConfirmationNo("showDelConfirmPopUp")
                        }
                      />
                    )}
                </div>
              ))}
            <div className="add_btn_cons">
              <IconButton
                color="primary"
                aria-label="add cons button"
                size="large"
                onClick={addConsultantInCharge}
                disabled={readOnlyMode}
              >
                <AddCircleIcon fontSize="large" />
              </IconButton>
            </div>
            <ConfirmationPopUp
              title={"Confirmation"}
              content={"Do you really want to add other consultant In-Charge?"}
              setIsOpen={setIsOpen}
              name={"consultantInCharge"}
              isOpen={isOpen}
              handleClose={() => handleClose("consultantInCharge")}
              handleYes={handleConfirmationConsultantInCharge}
              handleNo={() => handleConfirmationNo("consultantInCharge")}
            />
          </div>
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            6. Name(s) of other Consultant(s)
          </Typography>
          <span className="field_align">:</span>
          <div className="cons_in_charge_container">
            <div>
              <TextField
                name="otherConsultant"
                defaultValue={formDatas?.otherConsultant}
                onChange={(e) => {handleConsOtherCharge(e)}}
                error={!!errors.otherConsultant}
                helperText={errors.otherConsultant}
                sx={{
                  width: "350px",
                  marginBottom: "16px",
                  "& .MuiInputBase-root": {
                    height: "35px",
                    borderRadius: "15px",
                  },
                }}
                disabled={isEdit?.otherConsultantIncharge && mode !== 'add'}
              />
              <ToggleButtonGroup
                color="primary"
                defaultValue={consultantType}
                value={consultantType}
                exclusive
                onChange={handleConsultantType}
                aria-label="ConsultantType"
                size="small"
                sx={{
                  height: "35px",
                  marginLeft: "1rem",
                }}
                disabled={isEdit?.otherConsultantIncharge && mode !== 'add'}
              >
                <ToggleButton
                  value="Internal"
                  sx={{
                    "& .Mui-selected": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  Inside
                </ToggleButton>
                <ToggleButton value="External">Outside</ToggleButton>
              </ToggleButtonGroup>
              {mode !== 'add' && !readOnlyMode?
              isEdit?.otherConsultantIncharge ?
                <Button variant="outlined" size="small" onClick={()=>handleAction("edit", consultantType,null,formDatas?.otherConsultant)} sx={{height:'30px', marginLeft:'3.8rem'}}>
                  Edit
                </Button> :
                <Button variant="outlined" size="small" onClick={() => handleAction("save" ,consultantType)} sx={{height:'30px',marginLeft:'3.8rem'}}>
                  Save
                </Button>: ""
            }
            </div>
            {/* adding other fields */}
            {addField?.length > 0 &&
              addField.map((item, index) => (
                <div key={`other_consultant_${index}`}>
                  <TextField
                    name={`otherConsultantName_${index}`}
                    value={item?.otherConsultantName}
                    // defaultValue={initialValues.otherConsultant}
                    error={!!errors[`otherConsultantName_${index}`]}
                    helperText={errors[`otherConsultantName_${index}`]}
                    onChange={(e) => {
                      handleOtherConsultant(e, index);
                    }}
                    defaultValue={item?.consultantName}
                    sx={{
                      width: "350px",
                      marginBottom: "16px",
                      "& .MuiInputBase-root": {
                        height: "35px",
                        borderRadius: "15px",
                      },
                    }}
                    disabled={item?.isEdit && mode !== 'add'}
                  />
                  <ToggleButtonGroup
                    key={`toggle_consultant_${index}`}
                    name={`ConsultantType_${index}`}
                    color="primary"
                    value={item?.consultantType}
                    exclusive
                    onChange={(e) => handleOtherConsultantType(e, index)}
                    aria-label="ConsultantType"
                    size="small"
                    sx={{
                      height: "35px",
                      marginLeft: "1rem",
                    }}
                    disabled={item?.isEdit && mode !== 'add' && readOnlyMode}
                  >
                    <ToggleButton
                      value="Internal"
                      sx={{
                        "& .Mui-selected": {
                          backgroundColor: "black",
                        },
                      }}
                    >
                      Inside
                    </ToggleButton>
                    <ToggleButton value="External">Outside</ToggleButton>
                  </ToggleButtonGroup>

                  {isOpen?.deleteConsultantInCharge &&
                    deleteIndexRef?.current === index && (
                      <ConfirmationPopUp
                        title={"Delete Confirmation"}
                        content={"Do you really want to delete consultant ?"}
                        setIsOpen={setIsOpen}
                        name={"deleteConsultantInCharge"}
                        isOpen={isOpen}
                        handleClose={() =>
                          handleClose("deleteConsultantInCharge")
                        }
                        handleYes={() => handleDleteConsultantInCharge(index)}
                        handleNo={() =>
                          handleConfirmationNo("deleteConsultantInCharge")
                        }
                      />
                    )}
                    {!readOnlyMode && <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete(index)}
                      sx={{ marginLeft: "1rem" }}
                    >
                      <img src={deleteIcon} alt="delete" />
                    </IconButton>}
                    {mode !== 'add' && !readOnlyMode  ?
                      item?.isEdit ?
                        <Button variant="outlined" size="small" onClick={()=>handleAction("edit", consultantType,index,formDatas?.otherConsultant, "otherConsultant")} sx={{height:'30px', marginLeft:'1rem'}}>
                          Edit
                        </Button> :
                        <Button variant="outlined" size="small" onClick={() => handleAction("save" ,consultantType,index,formDatas?.otherConsultant, "otherConsultant")} sx={{height:'30px',marginLeft:'1rem'}}>
                          Save
                        </Button> : ""
                    }
                </div>
              ))}

            <div className="add_btn_cons">
              <IconButton
                color="primary"
                aria-label="add cons button"
                size="large"
                onClick={addConsultant}
                disabled={readOnlyMode}
              >
                <AddCircleIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
        </div>
        <ConfirmationPopUp
          title={"Confirmation"}
          content={"Do you really want to add other consultant ?"}
          setIsOpen={setIsOpen}
          name={"otherConsultant"}
          isOpen={isOpen}
          handleClose={() => handleClose("otherConsultant")}
          handleYes={handleConfirmationYes}
          handleNo={() => handleConfirmationNo("otherConsultant")}
        />
        <ConfirmationPopUp
          title={"Confirmation"}
          content={`Do you really want to change consultant type to ${
            consultantType === "Internal" ? "External" : "Internal"
          }?`}
          setIsOpen={setIsOpen}
          name={"consultantType"}
          isOpen={isOpen}
          handleClose={handleClose}
          handleYes={handleConfConsultantType}
          handleNo={() => handleConfirmationNo("consultantType")}
        />
        <div className="field_container">
          <Typography sx={{ width: "35%" }}>
            7. Name(s) of the Department/Center, where research is to be
            performed{" "}
          </Typography>
          <span className="field_align">:</span>
          <FormControl sx={{ minWidth: 350, borderRadius:'12px', height:'35px',marginBottom:'16px',
           }} error={!!errors.nameOfDept}  >
          <Select
            sx={{borderRadius:'16px', height:'35px'}}
            labelId="select category"
            id="select category"
            name="nameOfDept"
            defaultValue={formDatas?.nameOfDept}
            disabled = {readOnlyMode}
          >{departmentData && departmentData?.length > 0 &&
            departmentData.map((item) => <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>)
          }
          </Select>
          {errors?.nameOfDept && <FormHelperText>{errors?.nameOfDept}</FormHelperText>}
        </FormControl>
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            8. Upload Project documents{" "}
          </Typography>
          <span className="field_align">:</span>
          <DocumentSelection
            handleFileChangeUpload={handleFileChangeUpload}
            handleOnCancelIsVisibleUpload={handleOnCancelIsVisibleUpload}
            handleOkUpload={handleOkUpload}
            handleCancelUpload={handleCancelUpload}
            addUploadInputUpload={addUploadInputUpload}
            documentProps={documentProps}
            handleFirstDeleteUploadFile={handleFirstDeleteUploadFile}
            isModalVisible={isModalVisible}
            isModalVisibleUpload={isModalVisibleUpload}
            readOnlyMode={readOnlyMode}
            fileList={fileList}
            setIsModalVisible={setIsModalVisible}
            setIsModalVisibleUpload={setIsModalVisibleUpload}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            handleClose={handleClose}
            handleConfirmationNo={handleConfirmationNo}
            errors={errors}
            getDownloadFiles={getDownloadFiles}
            mode = {mode}
          />
        </div>
        <div className="field_container">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            9. Date of commencement of Work
          </Typography>
          <span className="field_align">:</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              name="startDate"
              defaultValue={formDatas?.startDate}
              onChange={(newValue) => (formDatas.startDate = newValue)}
              slotProps={{
                textField: {
                  sx: {
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  },
                  error: !!errors.startDate,
                  helperText: errors.startDate,
                },
              }}
              disabled={readOnlyMode}
            />
          </LocalizationProvider>
        </div>
        <div className="field_container" id="basicDetailDown">
          <Typography sx={{ width: "35%", paddingTop: "8px" }}>
            10. Expected date of completion of Work
          </Typography>
          <span className="field_align">:</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              name="endDate"
              defaultValue={formDatas?.endDate}
              onChange={(newValue) => (formDatas.endDate = newValue)}
              slotProps={{
                textField: {
                  sx: {
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  },
                  error: !!errors.endDate,
                  helperText: errors.endDate,
                },
              }}
              disabled={readOnlyMode}
            />
          </LocalizationProvider>
        </div>
      </Box>
      <Box
        sx={{ position: "sticky", display: "flex", justifyContent: "right" }}
      >
        <ScrollButtonTopAndDown
          upId="basicDetailTop"
          downId="basicDetailDown"
          isScrolledToBottom={isScrolledToBottom}
        />
      </Box>
      </>
       }
    </form>
  );
}

export default BasicDetails;
