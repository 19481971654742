import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Typography from "@mui/material/Typography";
import Layout from "../../../components/layout/layout";
import PersonalInfo from "../PersonalInfoPage/PersonalInfo";
import ChangePassword from "../PersonalInfoPage/ChangePassword";
import { decodeJWT } from "../../../constants/StoredToken";
function PersonalInfoTab() {
  const token = localStorage.getItem("jwtToken");

  const decodedToken = decodeJWT(token);
  const SUPER_ADMIN = decodedToken?.roleType === "SUPER_ADMIN";

  // const [selectedTab, setSelectedTab] = useState(SUPER_ADMIN ? "2" : "1");
  const [selectedTab, setSelectedTab] = useState("1");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Layout componentName="Personal Info">
      {/* <Layout componentName="Change Password"> */}
      <Box
        sx={{
          width: "97%",
          paddingTop: "10px",
          padding: "1rem",
          height: "100%",
        }}
      >
        <TabContext value={selectedTab}>
          <Box>
            <TabList
              onChange={handleTabChange}
              aria-label="Personal Info Tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Typography fontWeight="bold">
                    Personal Information
                  </Typography>
                }
                value="1"
              />

              <Tab
                label={
                  <Typography fontWeight="bold">Change Password</Typography>
                }
                value="2"
              />
            </TabList>
          </Box>

          <Box sx={{ height: "70%" }}>
            <TabPanel value="1" style={{ padding: "0px", height: "100%" }}>
              {/* {SUPER_ADMIN ? "" : <PersonalInfo />} */}
              <PersonalInfo />
            </TabPanel>
            <TabPanel value="2" style={{ padding: "0px", height: "100%" }}>
              <ChangePassword />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Layout>
  );
}

export default PersonalInfoTab;
