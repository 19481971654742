import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { APPROVING_ROLES } from "../../constants/Constant";
import { validate } from "../../utils/EmployeeValidation";

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    width: "80%",
  },
};

const inputStyle = { fontSize: "16px" };

const EmployeeBasicDetails = ({
  genderData,
  setGenderId,
  setBasicDetails,
  basicDetails,
  onFormValidityChange,
}) => {
  const location = useLocation();
  const { state } = location;
  const { action, data } = state || {};
  const role = localStorage.getItem("role");

  const readOnlyMode = action === "view" || APPROVING_ROLES.includes(role);

  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    mobileNumber: false,
    gender: false,
  });

  useEffect(() => {
    if (data) {
      setBasicDetails({
        firstName: data?.EmployeeName || "",
        lastName: data?.EmployeeName || "",
        gender: data?.Gender || "",
        email: data?.EmailId || "",
        mobileNumber: data?.MobileNumber || "",
      });
    }
  }, [data, setBasicDetails]);

  useEffect(() => {
    const isValid = validate(basicDetails);
    const isFormValid =
      isValid.firstName &&
      isValid.lastName &&
      isValid.email &&
      isValid.mobileNumber &&
      isValid.gender;

    onFormValidityChange(isFormValid);
  }, [basicDetails, onFormValidityChange]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setTouched((prev) => ({ ...prev, [name]: true }));

    setBasicDetails((prev) => ({ ...prev, [name]: value }));

    if (name === "gender") {
      const selectedGender = genderData.find(
        (gender) => gender?.fieldValue === value
      );
      setGenderId(selectedGender?.id || null);
    }
  };

  const isValid = validate(basicDetails);

  return (
    <Box sx={{ width: "100%", padding: "1rem", marginTop: "0.5rem" }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={readOnlyMode ? "" : "First Name"}
            value={basicDetails?.firstName}
            name="firstName"
            margin="normal"
            onChange={handleOnChange}
            sx={textFieldStyle}
            InputProps={{
              style: inputStyle,
            }}
            disabled={readOnlyMode}
            required
            error={touched.firstName && !isValid.firstName}
            helperText={
              touched.firstName &&
              !isValid.firstName &&
              "Only alphabets and spaces are allowed. Cannot be empty or whitespace."
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={basicDetails?.lastName}
            margin="normal"
            onChange={handleOnChange}
            sx={textFieldStyle}
            InputProps={{
              style: inputStyle,
            }}
            disabled={readOnlyMode}
            required
            error={touched.lastName && !isValid.lastName}
            helperText={
              touched.lastName &&
              !isValid.lastName &&
              "Only alphabets and spaces are allowed. Cannot be empty or whitespace."
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            margin="normal"
            sx={textFieldStyle}
            required
            error={touched.gender && !isValid.gender}
          >
            <Select
              fullWidth
              id="gender"
              name="gender"
              value={basicDetails?.gender}
              onChange={handleOnChange}
              variant="outlined"
              displayEmpty
              sx={textFieldStyle}
              disabled={readOnlyMode}
            >
              <MenuItem value="">
                <em>Select Gender</em>
              </MenuItem>
              {genderData.map((gender) => (
                <MenuItem key={gender.id} value={gender.fieldValue}>
                  {gender.fieldValue}
                </MenuItem>
              ))}
            </Select>
            {touched.gender && !isValid.gender && (
              <span style={{ color: "red", fontSize: "12px" }}>
                Gender is required.
              </span>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={readOnlyMode ? "" : "Email"}
            name="email"
            value={basicDetails?.email}
            onChange={handleOnChange}
            variant="outlined"
            margin="normal"
            sx={textFieldStyle}
            InputProps={{
              style: inputStyle,
            }}
            disabled={readOnlyMode}
            required
            error={touched.email && !isValid.email}
            helperText={
              touched.email &&
              !isValid.email &&
              "Please enter a valid email address. Cannot be empty or whitespace."
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={readOnlyMode ? "" : "Mobile Number"}
            name="mobileNumber"
            value={basicDetails?.mobileNumber}
            onChange={handleOnChange}
            variant="outlined"
            margin="normal"
            sx={textFieldStyle}
            InputProps={{
              style: inputStyle,
            }}
            disabled={readOnlyMode}
            required
            error={touched.mobileNumber && !isValid.mobileNumber}
            helperText={
              touched.mobileNumber &&
              !isValid.mobileNumber &&
              "Please enter a valid 10-digit mobile number. Cannot be empty or whitespace."
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeBasicDetails;
