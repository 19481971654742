import React, { useEffect } from "react";
import * as echarts from "echarts";

const TablePieChart = ({ id, data, color }) => {

  useEffect(() => {
    const myChart = echarts.init(document.getElementById(id));

    const option = {
      tooltip: {
        trigger: "item",
      },
      title: {
        text: `${data[0]?.value}%`,
        left: '7%',
        top: '45%',
        textAlign: 'center',
        textVerticalAlign: 'middle',
        textStyle: {
          fontSize: '9',
          fontWeight: 'bold',
        },
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["45%", "70%"],
          center: ['10%', '50%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          labelLine: {
            show: false,
          },
          data: data.map((item, index) => ({
            ...item,
            itemStyle: {
              color: index === 0 ? color : '#e4e5e4'
            }
          })),
          label: {
            show: false,
          },
        },
      ],
    };

    myChart.setOption(option);
    return () => {
      myChart.dispose();
    };
  }, []);

  return <div id={id} style={{ width: "100%", height: "100%" }} />;
};

export default TablePieChart;
