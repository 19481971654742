import React, { useRef, useState } from "react";
import Layout from "../../../components/layout/layout";
import { useLocation, useNavigate } from "react-router-dom";
import GeneralButton from "../../../components/button/GeneralButton";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

import BasicDetails from ".././BasicDetails";
import { Box } from "@mui/system";
import { BUTTON_COLOUR } from "../../../constants/Constant";
import "../../../components/projectDetails/projectDetails.css";
import FinancialDetails from ".././FinancialDetails";
import ApprovedComments from "../../../components/approvedComments/approvedComments";

function ApproveProjectMain(props) {
  const location = useLocation();
  const { action, data, projectId } = location.state || {};

  const formTitleValue = "approval";
  const [tabValue, setTabValue] = useState("1");
  const basicFormRef = useRef(null);
  const editBasicFormRef = useRef(null);
  const financialFormRejectRef = useRef(null);

  console.log("approve props----->", projectId);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (buttonName) => {
    // if (buttonName === "Approval") {
    //   if (financialFormRef.current) {
    //     financialFormRef.current.requestSubmit();
    //   }
    // } else
     if (buttonName === "Continue") {
      basicFormRef.current.requestSubmit();
    } else if (buttonName === "Reject") {
      financialFormRejectRef.current.requestSubmit();
    }
  };

  const saveContinueBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick("Continue"),
    name: "Continue",
  };

  const sendApprovalBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick("Send for Approval"),
    name: "Send for Approval",
  };

  const rejectBtnProps = {
    name: "Reject",
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: "var(--Red-rose-600, rgba(225, 29, 72, 1))",
    width: "220px",
    height: "48px",
    marginRight: "16px",
    buttonHandler: () => {
      handleClick("Reject");
    },
  };

  return (
    <Layout componentName={formTitleValue}>
      <TabContext value={tabValue}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              width: "99%",
              marginBottom: "1rem",
              marginLeft: "5px",
              marginRight: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: "16px",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="form tabs"
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                "& .MuiTab-root": {
                  minWidth: "auto",
                },
              }}
            >
              <Tab label="Basic Details" value="1" sx={{ fontWeight: "500" }} />
              <Tab
                label="Financial Details"
                value="2"
                sx={{ fontWeight: "500" }}
              />
            </TabList>
          </Box>
        </Box>
        <Box>
          <TabPanel
            value="1"
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              width: "97%",
            }}
          >
            <BasicDetails
              basicFormRef={basicFormRef}
              formTitleValue={formTitleValue}
              id={projectId}
              mode={action}
              data={data}
            />
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              width: "97%",
            }}
          >
            <FinancialDetails
              formTitleValue={formTitleValue}
              id={projectId}
              mode={action}
              rejectBtnProps={rejectBtnProps}
            />
          </TabPanel>
        </Box>
      </TabContext>

      <div className="btn_alignment">
        <Box>
          {tabValue === "1" && <GeneralButton {...saveContinueBtnProps} />}
          {tabValue === "2" && <GeneralButton {...rejectBtnProps} />}
          {tabValue === "3" && <GeneralButton {...sendApprovalBtnProps} />}
        </Box>
      </div>
    </Layout>
  );
}

export default ApproveProjectMain;
