import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import AuthLayout from "../../../components/authlayout/AuthLayout";
import Submit from "../../../components/button/submit";
import PageLoader from "../../../components/Loader/PageLoader";
import NotifyMessage from "../../../components/NotifyMessage/NotifyMessage";

import * as constants from "../../../constants/Constant";
import { validateEmail, validatePassword } from "../../../utils/validations";
import { GetDecodedToken } from "../../../constants/StoredToken";
import { SignInApiService } from "../../../api/ApiService";

import "./login.css";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [validations, setValidations] = useState({
    email: { isValid: true, errorMsg: "" },
    password: { isValid: true, errorMsg: "" },
  });
  const [notify, setNotify] = useState({ message: "", type: "" });

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues((prevValues) => ({
      ...prevValues,
      showPassword: !prevValues.showPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const setSessionToken = (token) => {
  //   localStorage.setItem("jwtToken", token);
  // };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await SignInApiService({
        email: values.email,
        password: values.password,
      });

      const data = response.data;

      if (response.status === 200) {
        const jwtToken = data.data.jwtToken;
        // if (jwtToken) {
        localStorage.setItem("jwtToken", jwtToken);
        // setSessionToken(jwtToken);
        const decodedToken = GetDecodedToken();
        const roleType = decodedToken?.roleType;
        setNotify({ message: "Login successful", type: "success" });
        setTimeout(() => {
          if (roleType === "SUPER_ADMIN") {
            navigate("/employeeList");
          } else if (roleType === "PI") {
            navigate("/projects");
          } else if (
            roleType === "HOSTEL_ADMIN" ||
            roleType === "ARND" ||
            roleType === "DRND" ||
            roleType === "DEAN" ||
            roleType === "DIRECTOR"
          ) {
            navigate("/dashboard");
          } else {
            setNotify({
              message: "Unauthorized role type",
              type: "error",
            });
          }
        }, 2000);
        // }
      } else {
        setNotify({ message: data.message, type: "error" });
      }
    } catch (error) {
      console.error("Error:", error);
      setNotify({
        message: "An error occurred while signing in.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      {notify.message && (
        <NotifyMessage message={notify.message} type={notify.type} />
      )}

      {loading ? (
        <PageLoader loadingStatus={loading} />
      ) : (
        <>
          <div className="title">
            <Typography variant="h3" gutterBottom sx={{ fontWeight: "bold" }}>
              Sign In
            </Typography>
          </div>

          <form
            className="signin-form-css"
            autoComplete="off"
            onSubmit={submitHandler}
          >
            <TextField
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              error={!validations.email.isValid}
              helperText={validations.email.errorMsg}
              required
              className="signin_input_css"
              placeholder="Email"
              sx={{ marginBottom: "16px" }}
            />
            <TextField
              label="Password"
              name="password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange}
              error={!validations.password.isValid}
              helperText={validations.password.errorMsg}
              required
              className="signin_input_css password_input"
              placeholder="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ borderRadius: "50px", marginBottom: "16px" }}
            />
            <Submit
              backgroundColor={constants.BUTTON_COLOUR}
              buttonLoading={buttonLoading}
              btnText="Sign In"
            />
          </form>
        </>
      )}
    </AuthLayout>
  );
};

export default Login;
