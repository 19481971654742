import { Box, Hidden } from "@mui/material";
import { sideBar } from "./Sidibar";
import iitp_logo from "../../assets/images/iit_patna_logo.jpg";
import { Link, useLocation } from "react-router-dom";
import MobileHeader from "./MobileHeader";

function Sidebar({ componentName }) {
  const { pathname } = useLocation();
  // console.log("pathname---->", pathname);

  const mobileHeaderProps = {
    pathname: pathname,
    componentName: componentName,
  };

  return (
    <>
      <Hidden smDown>
        <Box
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: {
              xs: "row",
              lg: "column",
            },
            alignItems: "cente",
            justifyContent: "space-between",
            width: {
              sm: "100%",
              lg: 200,
            },
            backgroundColor: "rgb(248, 250, 252)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "row",
                lg: "column",
              },
              gap: 5,
              alignItems: {
                xs: "center",
                ls: "start",
              },
              width: "100%",
            }}
          >
            <Hidden lgDown>
              <Link to="">
                <img src={iitp_logo} alt="" width={210} height={70} />
              </Link>
            </Hidden>
            <Box
              sx={{
                py: "16px",
                display: "flex",
                flexDirection: "column",
                gap: 4,
                width: "100%",
              }}
            >
              {sideBar(pathname)}
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Hidden smUp>
        <MobileHeader {...mobileHeaderProps} />
      </Hidden>
    </>
  );
}

export default Sidebar;
