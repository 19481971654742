import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  IconButton,
  FormHelperText,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpenditureForm from "../../components/expenditureForm/expenditureForm";
import Layout from "../../components/layout/layout";
import deleteIcon from "../../assets/images/delete.png";
import ScrollButtonTopAndDown from "../../components/button/ScrollButtonTopAndDown";
import { useLocation } from "react-router-dom";

const FinancialDetails = ({
  financeformValues,
  totalExp,
  selectGstNum,
  errors,
  // handleChange,
  handleGstAvailability,
  handleValidateGstNum,
  // addBudgetExpendature,
  setExtraExpDetails,
  extraExpDetails,
  setOtherExpenses,
  otherExpenses,
  selectCategory,
  setSelectCategory,
  handleSelectCategory,
  // setIsModalVisible,
  // setIsOpen,
  readOnlyMode,
  // selectRecommendation,
  // getTotalPercentage
}) => {
  // const [isOpen, setIsOpenState] = useState({});
  const location = useLocation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [calOverHeadAmount, setCalOverHeadAmount] = useState(0); // Placeholder for calculation logic
  const [calcGst, setCalcGst] = useState(0); // Placeholder for calculation logic
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [addBudgetExpendature, setAddBudgetExpendature] = useState([0]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { action, data } = location.state || {};
  const [isOpen, setIsOpen] = useState({
    approveConfirmation: false,
    successPopUp: false,
    consultantType: false,
    deleteConsultantInCharge: false,
    otherConsultant: false,
    deleteExtraExp: false,
    showExpForm: false,
    consultantInCharge: false,
    showDelConfirmPopUp: false,
    addDocument: false,
    sendForApproval: false,
    addBudgetDetails: false,
    showDeleteAddBudget: false,
  });

  const [selectRecommendation, setSelectRecommendation] = useState(
    data?.financialDetails?.recommendation
      ? data?.financialDetails?.recommendation
      : false
  );

  const addBudgetInput = () => {
    setAddBudgetExpendature([...addBudgetExpendature, Date.now()]);
  };
  const deleteBudgetInput = (index) => {
    setAddBudgetExpendature((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleSelectRecommendation = (event) => {
    setSelectRecommendation(!selectRecommendation);
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value]
    );

    const prevValue = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];

    const totalPercentage = getTotalPercentage(prevValue);
    // console.log("totalPercentage--->", totalPercentage);
    const calValue = totalPercentage * 0.1 * totalExp;
    setCalOverHeadAmount(calValue);
  };

  const getTotalPercentage = (data) => {
    const percentageMap = {
      noInstituteFacilities: 25,
      instituteFacilities: 30,
      testingAndInterpretation: 30,
    };

    return data.reduce((total, item) => {
      return total + (percentageMap[item] || 0);
    }, 0);
  };
  return (
    <Box className="form_container scroll-container">
      <Typography
        sx={{
          width: "auto",
          paddingTop: "8px",
          fontWeight: "700",
          fontSize: "16px",
        }}
        id="top"
      >
        1. THE CONSULTANCY PROJECT INVOLVES (Tick appropriate one)
      </Typography>
      <Box style={{ marginLeft: "16px" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography
            sx={{
              width: "45.5%",
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "10px",
            }}
          >
            1.1 Use of no Institute facilities
          </Typography>
          <FormControlLabel
            name="noInstituteFacilities"
            value="noInstituteFacilities"
            control={
              <Radio
                value="noInstituteFacilities"
                checked={selectedOptions.includes("noInstituteFacilities")}
                onClick={handleChange}
                disabled={readOnlyMode}
              />
            }
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography
            sx={{
              width: "45.5%",
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "10px",
            }}
          >
            1.2 Use of Institute facilities such as Laboratory and computer
          </Typography>
          <FormControlLabel
            name="instituteFacilities"
            value="instituteFacilities"
            control={
              <Radio
                value="instituteFacilities"
                checked={selectedOptions.includes("instituteFacilities")}
                onClick={handleChange}
                disabled={readOnlyMode}
              />
            }
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography
            sx={{
              width: "45.5%",
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "10px",
            }}
          >
            1.3 Primarily testing & Interpretation
          </Typography>
          <FormControlLabel
            name="testingAndInterpretation"
            value="testingAndInterpretation"
            control={
              <Radio
                value="testingAndInterpretation"
                checked={selectedOptions.includes("testingAndInterpretation")}
                onClick={handleChange}
                disabled={readOnlyMode}
              />
            }
          />
        </Box>
        {errors?.consultancyProject && (
          <FormHelperText
            error="true"
            sx={{ width: "57%", display: "flex", justifyContent: "flex-end" }}
          >
            {errors?.consultancyProject}
          </FormHelperText>
        )}
      </Box>
      <Box className="field_container">
        <Typography sx={{ width: "36%", paddingTop: "8px" }}>
          <span style={{ fontWeight: "bold" }}>2.</span> Is GST number available
          ?
        </Typography>
        <span className="field_align">:</span>
        <span className="field_align"></span>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="is-gst-num-available"
            name="isGstNumAvailable"
            onChange={handleGstAvailability}
            value={selectGstNum}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label="Yes"
              disabled={readOnlyMode}
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label="No"
              disabled={readOnlyMode}
            />
          </RadioGroup>
          {errors?.isGstNumAvailable && (
            <FormHelperText error="true">
              {errors?.isGstNumAvailable}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      {selectGstNum === "yes" && (
        <div className="field_container">
          <Typography sx={{ width: "36%", paddingTop: "8px" }}>
            {"\u00A0\u00A0\u00A0\u00A0\u00A0"}
            {"2.1. GST number"}
          </Typography>
          <span className="field_align">:</span>
          <span className="field_align"></span>
          <TextField
            name="gstNumber"
            error={!!errors?.gstNumber}
            helperText={errors?.gstNumber}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
            type="number"
            onBlur={handleValidateGstNum}
            disabled={readOnlyMode}
          />
        </div>
      )}
      <div className="field_container">
        <Typography sx={{ width: "36%", paddingTop: "8px" }}>
          <span style={{ fontWeight: "bold" }}>3.</span> Total sanction amount
        </Typography>
        <span className="field_align">:</span>
        <span className="field_align">Rs</span>
        <TextField
          name="totalSanctionAmount"
          error={!!errors?.totalSanctionAmount}
          helperText={errors?.totalSanctionAmount}
          sx={{
            width: "350px",
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              height: "35px",
              borderRadius: "15px",
            },
          }}
          type="number"
          disabled={readOnlyMode}
        />
      </div>
      <div>
        <div className="field_container">
          <Typography
            sx={{
              width: "36%",
              paddingTop: "8px",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            <span style={{ fontWeight: "bold" }}>4.</span>
            {"   4.1 Consultancy Fee"}
          </Typography>
          <span className="field_align">:</span>
          <span className="field_align">Rs</span>
          <TextField
            name="consultancyFee"
            type="number"
            defaultValue={financeformValues?.consultancyFee}
            value={totalExp}
            error={!!errors?.consultancyFee}
            helperText={errors?.consultancyFee}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
            disabled={readOnlyMode}
          />
        </div>
      </div>
      <div style={{ marginLeft: "24px" }}>
        <div className="field_container">
          <Typography
            sx={{
              width: "35%",
              paddingTop: "8px",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {"4.2 Overhead amount"}
          </Typography>
          <span className="field_align">:</span>
          <span className="field_align">Rs</span>
          <TextField
            name="overheadAmount"
            type="number"
            defaultValue={financeformValues?.overheadAmount}
            value={totalExp}
            error={!!errors?.overheadAmount}
            helperText={errors?.overheadAmount}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
            disabled={readOnlyMode}
          />
        </div>
      </div>
      <div style={{ marginLeft: "24px" }}>
        <div className="field_container">
          <Typography
            sx={{
              width: "35%",
              paddingTop: "8px",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {"4.3 GST(18% of (a) above)"}
            <br />
            {"whenever application (see reverse page for details)"}
          </Typography>
          <span className="field_align">:</span>
          <span className="field_align">Rs</span>
          <TextField
            name="gst"
            type="number"
            defaultValue={financeformValues?.gst}
            value={totalExp}
            error={!!errors?.gst}
            helperText={errors?.gst}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
            disabled={readOnlyMode}
          />
        </div>
      </div>
      <div style={{ marginLeft: "24px" }}>
        <div className="field_container">
          <Typography
            sx={{
              width: "35%",
              paddingTop: "8px",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {"4.4 Gross Consultancy of Charges ( 4.1 + 4.2 + 4.3 )"}
          </Typography>
          <span className="field_align">:</span>
          <span className="field_align">Rs</span>
          <TextField
            name="gst"
            type="number"
            defaultValue={financeformValues?.gst}
            value={totalExp}
            error={!!errors?.gst}
            helperText={errors?.gst}
            sx={{
              width: "350px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                height: "35px",
                borderRadius: "15px",
              },
            }}
            disabled={readOnlyMode}
          />
        </div>
      </div>
      <div>
        <Typography sx={{ width: "35%", fontWeight: "700", fontSize: "16px" }}>
          5. SHARING OF THE CONSULTANCY FEE
        </Typography>
        <div style={{ marginLeft: "16px" }}>
          <Box>
            {/* <DialogTitle>Add Other Expenditure</DialogTitle> */}

            <Typography
              sx={{ width: "35%", fontWeight: "500", paddingTop: "8px" }}
            >
              Add Budget Expenditure
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <div className="field_container">
                <Typography sx={{ ml: 1 }}>Category</Typography>
                <Typography sx={{ ml: "370px" }}>Description</Typography>
                <Typography sx={{ ml: "370px" }}>Amount</Typography>
              </div>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "block" }}>
                  {addBudgetExpendature?.map((file, index) => (
                    <>
                      <Box sx={{ display: "flex" }} key={index}>
                        <ExpenditureForm
                          setExtraExpDetails={setExtraExpDetails}
                          extraExpDetails={extraExpDetails}
                          setOtherExpenses={setOtherExpenses}
                          otherExpenses={otherExpenses}
                          selectCategory={selectCategory}
                          setSelectCategory={setSelectCategory}
                          handleSelectCategory={handleSelectCategory}
                          // selectCategoryOption={selectCategoryOption}
                          // handleSelectCategoryfuction={
                          //   handleSelectCategoryfuction
                          // }
                        />

                        {index > 0 ? (
                          <IconButton
                            aria-label="delete"
                            // onClick={() => {
                            //   setIsOpen({
                            //     ...isOpen,
                            //     showDeleteAddBudget: true,
                            //   });
                            //   setIsOpen({ ...isOpen, uploadDocument: true });
                            // }}
                            onClick={() => deleteBudgetInput(index)}
                          >
                            <img src={deleteIcon} alt="delete" />
                          </IconButton>
                        ) : null}
                      </Box>
                    </>
                  ))}
                </Box>
              </Box>
              <Box>
                {addBudgetExpendature?.length > 0 ? (
                  <div className="add_btn">
                    <IconButton
                      color="primary"
                      aria-label="institute share"
                      size="large"
                      // onClick={addBudgetInput}
                      onClick={() => {
                        addBudgetInput();
                        setIsModalVisible(true);
                        setIsOpen({
                          ...isOpen,
                          addBudgetDetails: true,
                        });
                        setIsOpen({ ...isOpen, uploadDocument: true });
                      }}
                      disabled={readOnlyMode}
                    >
                      <AddCircleIcon fontSize="large" />
                    </IconButton>
                  </div>
                ) : null}
              </Box>
            </Box>
          </Box>
        </div>

        <div style={{ marginLeft: "16px" }}>
          <div className="field_container">
            <Typography sx={{ width: "35%", paddingTop: "8px" }}>
              5.1 Project Expendature
            </Typography>
            <span className="field_align">:</span>
            <span className="field_align">Rs</span>
            <TextField
              name="totalExpenditure"
              type="number"
              defaultValue={financeformValues?.totalExpenditure}
              value={totalExp}
              error={!!errors?.totalExpenditure}
              helperText={errors?.totalExpenditure}
              sx={{
                width: "350px",
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  borderRadius: "15px",
                },
              }}
              disabled={readOnlyMode}
            />
          </div>
        </div>
        <div style={{ marginLeft: "16px" }}>
          <div className="field_container">
            <Typography sx={{ width: "35%", paddingTop: "8px" }}>
              5.2 Institute's Share(% of 5.1)
              <br />
              (25% for 1.1, 30% for 1.2 and 35% for 1.3) <br />
              <span className={"caption"}>
                The above proposal may please be approved
              </span>
            </Typography>
            <span className="field_align">:</span>
            <span className="field_align">Rs</span>
            <TextField
              name="instituteShare"
              type="number"
              defaultValue={financeformValues?.instituteShare}
              error={!!errors?.instituteShare}
              helperText={errors?.instituteShare}
              sx={{
                width: "350px",
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  borderRadius: "15px",
                },
              }}
              disabled={readOnlyMode}
            />
          </div>
        </div>
        <div style={{ marginLeft: "16px" }}>
          <div className="field_container">
            <Typography
              sx={{
                width: "35%",
                paddingTop: "8px",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {"\u00A0\u00A0\u00A0\u00A0\u00A0"}
              {"5.3 Total Project Expenditure"}
            </Typography>
            <span className="field_align">:</span>
            <span className="field_align">Rs</span>
            <div className="share_container">
              <TextField
                name="otherExpenditure"
                type="number"
                defaultValue={financeformValues?.otherExpenditure}
                error={!!errors?.otherExpenditure}
                helperText={errors?.otherExpenditure}
                sx={{
                  width: "350px",
                  marginBottom: "16px",
                  "& .MuiInputBase-root": {
                    height: "35px",
                    borderRadius: "15px",
                  },
                }}
                disabled={readOnlyMode}
              />
            </div>
          </div>
        </div>

        <div id="down">
          <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
            RECOMMENDATION OF THE HOD/HOC/COORDINATOR
          </Typography>

          <Box
            className="recommendation_cont"
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Radio
              checked={!!selectRecommendation}
              onClick={handleSelectRecommendation}
              value={selectRecommendation}
              name="recommendation"
              inputProps={{ "aria-label": "A" }}
              sx={{ width: "8%" }}
              disabled={readOnlyMode}
            />
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", width: "70vw" }}
            >
              This is to recommend that the consultancy Project belongs to the
              category 1.1/1.2/1.3 (please strike the ones that are not
              applicable) and Consultant(s) would be given necessary facilities
              including leave of one day / week provided the Consultant(s)
              make(s) necessary arrangement for the class loads assigned to them
            </Typography>
          </Box>
          {errors?.recommendation && (
            <FormHelperText error="true">
              {errors?.recommendation}
            </FormHelperText>
          )}
        </div>
      </div>
      <div>
        {extraExpDetails?.map((expenditure, index) => (
          <ExpenditureForm
            key={index}
            index={index}
            expenditure={expenditure}
            extraExpDetails={extraExpDetails}
            setExtraExpDetails={setExtraExpDetails}
            handleSelectCategory={handleSelectCategory}
            selectCategory={selectCategory}
            setIsModalVisible={setIsModalVisible}
            setIsOpen={setIsOpen}
            setOtherExpenses={setOtherExpenses}
            otherExpenses={otherExpenses}
            readOnlyMode={readOnlyMode}
          />
        ))}
      </div>

      <Box
        sx={{
          position: "sticky",
          display: "flex",
          justifyContent: "right",
        }}
      >
        <ScrollButtonTopAndDown
          upId="top"
          downId="down"
          isScrolledToBottom={isScrolledToBottom}
        />
      </Box>
    </Box>
  );
};

export default FinancialDetails;
