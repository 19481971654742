import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { APPROVING_ROLES } from "../../constants/Constant";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const textFieldStyle = {
  color: "black",
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    color: "black",
    width: "80%",
  },
};

const inputStyle = { fontSize: "16px" };

function getStyles(name, selectedValues, theme) {
  return {
    fontWeight:
      selectedValues.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const EmployeeOtherDetails = ({
  departmentData,
  designationData,
  assignedDesignationData,
  setDepartmentId,
  setDesignationIds,
  setAssignedDesignationIds,
  setRoleIds,
}) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [assignDepartment, setAssignDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const theme = useTheme();
  const location = useLocation();
  const { action, data } = location.state || {};

  const role = localStorage.getItem("role");
  const readOnlyMode = action === "view";
  const editable = action === "edit";

  const initialValue = {
    departmentName: data?.officialInformation?.departmentName,
    designation: data?.officialInformation?.designation,
    departmentAllocation: data?.officialInformation?.departmentAllocation,
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const selectedDesignations = designationData.filter((designation) =>
      value.includes(designation.fieldValue)
    );
    setSelectedValue(typeof value === "string" ? value.split(",") : value);
    setDesignationIds(
      selectedDesignations.map((designation) => designation.id)
    );
  };

  const handleChangeInAssignDepartment = (e) => {
    const { value } = e.target;
    const selectedValues = typeof value === "string" ? value.split(",") : value;

    setAssignDepartment(selectedValues);

    const selectedAssignedDesignations = assignedDesignationData
      .filter((assigned) => selectedValues.includes(assigned.fieldValue))
      .map((assigned) => assigned.fieldCode);

    setAssignedDesignationIds(selectedAssignedDesignations);
    setRoleIds(selectedAssignedDesignations);
  };

  const handleDepartmentChange = (e) => {
    const selectedDepartment = departmentData.find(
      (department) => department.fieldValue === e.target.value
    );
    setSelectedDepartment(e.target.value);
    setDepartmentId(selectedDepartment?.id || null);
  };

  const handleDelete = (value) => {
    setSelectedValue((prev) => prev.filter((item) => item !== value));
  };

  useEffect(() => {
    if (editable || readOnlyMode) {
      setSelectedValue([initialValue.designation]);
      setAssignDepartment([initialValue.departmentAllocation]);
      setSelectedDepartment(initialValue.departmentName);
    }
  }, [
    editable,
    readOnlyMode,
    initialValue.designation,
    initialValue.departmentAllocation,
    initialValue.departmentName,
  ]);

  return (
    <Box sx={{ width: "100%", padding: "1rem", marginTop: "0.5rem" }}>
      <form className="form">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" sx={textFieldStyle}>
              <InputLabel id="department-label">Department Name</InputLabel>
              <Select
                labelId="department-label"
                id="department-select"
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                input={<OutlinedInput label="Department Name" />}
                MenuProps={MenuProps}
                disabled={readOnlyMode}
              >
                {departmentData.map((item) => (
                  <MenuItem key={item.id} value={item.fieldValue}>
                    {item.fieldValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" sx={textFieldStyle}>
              <InputLabel id="designation-label">Designation</InputLabel>
              <Select
                labelId="designation-label"
                id="designation-select"
                value={selectedValue}
                onChange={handleChange}
                input={<OutlinedInput label="Designation" />}
                MenuProps={MenuProps}
                disabled={readOnlyMode}
              >
                {designationData.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.fieldValue}
                    style={getStyles(item.fieldValue, [selectedValue], theme)}
                  >
                    {item.fieldValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" sx={textFieldStyle}>
              <InputLabel id="assign-department-label">
                Assign Department
              </InputLabel>
              <Select
                labelId="assign-department-label"
                id="assign-department-select"
                value={assignDepartment}
                onChange={handleChangeInAssignDepartment}
                input={<OutlinedInput label="Assign Department" />}
                MenuProps={MenuProps}
                disabled={readOnlyMode}
              >
                {assignedDesignationData.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.fieldValue}
                    style={getStyles(
                      item.fieldValue,
                      [assignDepartment],
                      theme
                    )}
                  >
                    {item.fieldValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EmployeeOtherDetails;
