import axios from "axios";
import * as constantUrl from "../../src/constants/Constant.jsx";
import { Rule } from "@mui/icons-material";

// const token = localStorage.getItem("jwtToken");

// console.log(token);

export async function SignInApiService(data) {
  try {
    let url = constantUrl.BASIC_API_URL + constantUrl.SIGNIN_ENDPOINT;
    let response = await axios.post(url, data);
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function ChangePasswordAPI(data) {
  try {
    let url = constantUrl.BASIC_API_URL + constantUrl.RESET_PASSWORD;
    let response = await axios.post(url, data);
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function GetAllEmployeeList(token) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = constantUrl.BASIC_API_URL + constantUrl.EMPLOYEE;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function CreateEmployee(employeeData) {
  try {
    const token = localStorage.getItem("jwtToken");
    let url = constantUrl.BASIC_API_URL + constantUrl.EMPLOYEE;
    let response = await axios.post(url, employeeData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function UpdateEmployee(employeeId, employeeData) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.EMPLOYEE}/${employeeId}`;
    const response = await axios.put(url, employeeData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    console.error("Error updating employee:", err);
    return err.response ? err.response.data : "An error occurred";
  }
}

export async function DeleteEmployee(id) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.EMPLOYEE}/${id}`;
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err.response || { status: 500 };
  }
}

export async function GetAllEmployeeListById(id) {
  try {
    const token = localStorage.getItem("jwtToken");
    let url = `${constantUrl.BASIC_API_URL}${constantUrl.EMPLOYEE}/${id}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function GetGender() {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.GENDER}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function GetDepartment() {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.DEPARTMENT}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function GetDesignation() {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.DESIGNATION}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function GetAssignedDesignation() {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.AssignedDesignation}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function GetClients() {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = constantUrl.GET_CLIENTS;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response---->", response);

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function CreateClients(reqBody) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = constantUrl.GET_CLIENTS;
    let response = await axios.post(url, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response---->", response);

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function AddProject(formData) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}/projects`;
    let response = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("project api run suuccessfully");
    console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function CreateProjectByProjectId(reqBody) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.CREATE_FINANCIAL}`;
    let response = await axios.post(url, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function GetProjectById(id) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.GET_PROJECT_BY_ID}${id}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response---->", response);

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function GetAllProjectList(pageNo, pageSize) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.PROJECT}?pageNo=${pageNo}&pageSize=${pageSize}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    console.error("Error in GetAllProjectList:", err);
    throw err;
  }
}

export async function saveConsultant(id, reqBody) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.SAVE_CONSULTANTS}${id}`;
    let response = await axios.put(url, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
}

export async function GetFinancialDetailsByProjectId(projectId) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.GET_FINANCIAL_BY_ID}${projectId}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
}

export async function ProjectApproveByProjectId(projectId, reqBody) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.PROJECT_APPROVAL}${projectId}`;
    const response = await axios.put(url, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateBasicForm(reqParams, formData) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.UPDATE_PROJECT}${reqParams}`;
    let response = await axios.put(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    return err;
  }
}

export async function GetProjectStatus(id) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.GET_PROJECT_STATUS}${id}${constantUrl.STATUS}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
}
export async function ProjectRejectByProjectId(projectId, reqBody) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.PROJECT_REJECT}${projectId}`;
    let response = await axios.put(url, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function DeleteProjectByProjectId(projectId) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.DELETE_PROJECT}${projectId}`;
    let response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function UpdateProjectByProjectId(reqBody) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.CREATE_FINANCIAL}`;
    let response = await axios.put(url, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function GetProjectListOnlyForProjectListByPi(pageNo, pageSize) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.BASIC_API_URL}${constantUrl.PROJECT_LIST_FOR_PI}?pageNo=${pageNo}&pageSize=${pageSize}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function DownloadFiles(name) {
  try {
    const token = localStorage.getItem("jwtToken");
    const url = `${constantUrl.DOWNLOAD_FILES}${name}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("files response----->",response);
    
    return response;
  } catch (error) {
    return error;
  }
}





