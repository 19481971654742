import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Hidden,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Dashboard from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import { GetDecodedToken } from "../../constants/StoredToken";

const REGISTER = [
  {
    name: "Dashboard",
    icon: <Dashboard />,
    link: "/dashboard",
    activeLinks: ["dashboard"],
  },
  {
    name: "New Project",
    icon: <AddCircleIcon />,
    link: "/form",
    activeLinks: ["form"],
  },
  {
    name: "Projects",
    icon: <BusinessIcon />,
    link: "/projects",
    activeLinks: ["projects"],
  },
  {
    name: "Employees",
    icon: <PeopleIcon />,
    link: "/employeeList",
    activeLinks: ["employeeList"],
  },
  {
    name: "Add Employee",
    icon: <AddCircleIcon />,
    link: "/employee",
    activeLinks: ["addemp"],
  },
];

export const sideBar = (pathname) => {
  const getDecodedToken = GetDecodedToken();
  const decodeTokenRole = getDecodedToken.roleType;

  const filteredRegister = (() => {
    switch (decodeTokenRole) {
      case "SUPER_ADMIN":
        return REGISTER.filter(
          (item) =>
            item.name === "Employees" ||
            item.name === "Add Employee" ||
            item.name === "Dashboard"
        );
      case "PI":
        return REGISTER.filter((item) =>
          ["New Project", "Projects", "Dashboard"].includes(item.name)
        );
      default:
        return REGISTER.filter(
          (item) => item.name == "Projects" || item.name == "Dashboard"
        );
    }
  })();

  return (
    <Accordion
      sx={{
        bgcolor: "rgb(248, 250, 252)",
        borderRadius: "none",
        border: "none",
      }}
    >
      {/* <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "&.MuiPaper-root-MuiAccordion-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Typography>Overview</Typography>
      </AccordionSummary> */}
      <AccordionDetails>
        {filteredRegister.map((item) => (
          <Link
            key={item.name}
            to={item.link}
            style={{ textDecoration: "none" }}
            className="hoverDiv"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                color: "white",
                textDecoration: "none",
                padding: "8px",
                border: "none",
              }}
            >
              {React.cloneElement(item.icon, {
                style: {
                  color: item?.activeLinks.includes(pathname.split("/")[1])
                    ? "#4F46E5"
                    : "black",
                },
              })}
              <Hidden mdDown>
                <Typography sx={{ color: "black" }}>{item.name}</Typography>
              </Hidden>
            </Box>
          </Link>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
