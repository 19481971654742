import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';

const StatesticCards = () => {

  const statsData = [
    {
        id: 1 ,
        title: "Projects",
        data: "850",
        totalData : "1000",
        subDescription: "10% increase in last month",
        Icon: <BusinessCenterOutlinedIcon  fontSize="large" sx={{color:"#67b592" }} />,
        backgroundColor: "#edfef4"
    },
    {
        id: 2 ,
        title: "Tasks",
        data: "100",
        totalData : "110",
        subDescription: "5% Decrease from last month",
        Icon: <AssignmentTurnedInOutlinedIcon  fontSize="large" sx={{color:"#DA928F" }} />,
        backgroundColor: "#ffedec"
    },
    {
        id: 3 ,
        title: "Resources",
        data: "85",
        totalData : "90",
        subDescription: "5% increase in last month",
        Icon: <PersonOutlineOutlinedIcon  fontSize="large" sx={{color:"#6D68AA" }} />,
        backgroundColor: "#EAEDFF"
    },
    {
        id: 4 ,
        title: "Time Spent",
        data: "850",
        totalData : "1000",
        subDescription: "10% increase in last month",
        Icon: <WatchLaterOutlinedIcon  fontSize="large" sx={{color:"#E2B557" }} />,
        backgroundColor: "#FBF4E0"
    }
]
    
  return (
    <Grid container  sx={{display: 'flex', flexDirection:'row', justifyContent:'space-between'}}>
     {statsData?.length > 0  && statsData.map((data, index) =>   
      <Grid item md={2.5} key={index}>
        <Box
          sx={{
            height: "90px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "1rem",
            borderRadius: "10px",
          }}
        >
          <Box>
            {/* content */}
            <Typography variant="subtitle1" sx={{ color: '#3E3C48'}}>{data?.title}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="h6" sx={{fontWeight: 'bold'}}>{data?.data}</Typography>
              <Typography
                variant="overline"
                sx={{ marginTop: "3px", marginLeft: "5px" }}
              >
                {`/ ${data?.totalData}`}
              </Typography>
            </Box>
            <Typography variant="caption" display="block">
              {data?.subDescription}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* image */}
            <Box
              sx={{
                backgroundColor: data?.backgroundColor,
                width: "120%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                height:'60%',
                borderRadius:'52%'
              }}
            >
              {data?.Icon}
            </Box>
          </Box>
        </Box>
      </Grid>
     )}
    </Grid>
  );
};

export default StatesticCards;
