import { Box, Button, Modal, Typography } from "@mui/material";
import { borderRadius } from "@mui/system";
import React from "react";

const RejectedReasonPopUp = ({ open, handleClose }) => {
  // console.log("open---->", open);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius:'20px'
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Reason for Rejection :
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, color:'grey', fontWeight:'bold' }}>
          Due to very high consultancy amount that has been rejected.
        </Typography>
        <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2em",
            }}
          >
            <Button
              onClick={() => handleClose()}
              sx={{
                backgroundColor: "#1976d2",
                color: "#fff",
                "&.MuiButtonBase-root:hover": {
                  backgroundColor: "#1976d2",
                },
                width:'20%'
              }}
            >
              Close
            </Button>
          </Box>
      </Box>
    </Modal>
  );
};

export default RejectedReasonPopUp;
