import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decodeJWT } from "../constants/StoredToken";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useAuth = () => {
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");

    if (token) {
      const decodedToken = decodeJWT(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken && decodedToken.exp < currentTime) {
        setIsTokenExpired(true);
        toast.error("Your session has expired. Please log in again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        localStorage.removeItem("jwtToken");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleLogout = () => {
    setIsTokenExpired(false);
    navigate("/");
  };

  return { isTokenExpired, handleLogout };
};

export default useAuth;
