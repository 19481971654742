import { APPROVING_ROLES } from "../../constants/Constant";

export const pageTitle = (action) => {
  switch (action) {
    case "edit":
      return "Edit Form";
    case "view":
      return "View Form";
    case "approve":
      return "Form";
    case "reject":
      return "Form";
    default:
      return "Add Project";
  }
};

export const handleButtonText = (action, role, selectedTab) => {
  const pageTitleValue = pageTitle(action);
  if (role === "register") {
    if (
      (pageTitleValue == "Add Project" || pageTitleValue == "Edit Form") &&
      (selectedTab === "projectDetails" || selectedTab === "basicDetails")
    ) {
      return "Save & Continue";
    } else if (
      pageTitleValue == "Add Project" &&
      selectedTab === "financialDetails"
    ) {
      return "Send for Approval";
    } else if (
      pageTitleValue == "Edit Form" &&
      selectedTab === "financialDetails"
    ) {
      return "Send for Approval";
    } else if (
      pageTitleValue == "View Form" &&
      (selectedTab === "basicDetails" || selectedTab === "financialDetails")
    ) {
      return "Continue";
    } else if (
      pageTitleValue == "View Form" &&
      selectedTab === "approvalComment"
    ) {
      return "Back to Home";
    }
  }

  if (APPROVING_ROLES.includes(role)) {
    if (
      pageTitleValue == "View Form" &&
      (selectedTab === "financialDetails" || selectedTab === "basicDetails")
    ) {
      return "Continue";
    } else if (
      pageTitleValue == "View Form" &&
      selectedTab === "approvalComment"
    ) {
      return "Back to Home";
    }
    if (
      pageTitleValue == "Form" &&
      (selectedTab === "projectDetails" || selectedTab === "basicDetails")
    ) {
      return "Continue";
    } else if (pageTitleValue == "Form" && selectedTab === "financialDetails") {
      return "Approve";
    }
  }
};

export const formsTitle = (action, role, consultancyNo) => {
  const pageTitleValue = pageTitle(action);
  if (pageTitleValue == "Add Project" && role === "register") {
    return "Add Project";
  } else if (pageTitleValue == "Edit Form" && role === "register") {
    return `Update Form: ${consultancyNo}`;
  } else if (pageTitleValue == "View Form" && role === "register") {
    return `View Form: ${consultancyNo}`;
  } else {
    return `Form: ${consultancyNo}`;
  }
};

export const getTotalPercentage = (data) => {
  const percentageMap = {
    noInstituteFacilities: 25,
    instituteFacilities: 30,
    testingAndInterpretation: 30,
  };

  return data.reduce((total, item) => {
    return total + (percentageMap[item] || 0);
  }, 0);
};

export const handleCancel = (navigate) => {
  navigate("/projects");
};

export const showCancelBtn = (action, selectedTab) => {
  if (action === "approve" && selectedTab === "projectDetails") {
    return true;
  } else {
    return false;
  }
};
