import React, { useRef, useState } from "react";
import Layout from "../../../components/layout/layout";
import { useLocation, useNavigate } from "react-router-dom";
import GeneralButton from "../../../components/button/GeneralButton";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

import BasicDetails from ".././BasicDetails";
import { Box } from "@mui/system";
import { BUTTON_COLOUR } from "../../../constants/Constant";
import "../../../components/projectDetails/projectDetails.css";
import FinancialDetails from ".././FinancialDetails";
import ApprovedComments from "../../../components/approvedComments/approvedComments";

function EditMain(props) {
  console.log("edit props---->", props);
  const location = useLocation();
  const { action, data, projectId } = location.state || {};
  console.log("projectId---->", projectId);

  const formTitleValue = action === "view" ? "View Project" : "Update Project";
  const [tabValue, setTabValue] = useState("basicDetails");
  const basicFormRef = useRef(null);
  const editBasicFormRef = useRef(null);
  const financialFormRef = useRef(null);
  const [selectRecommendation, setSelectRecommendation] = useState(false);

  console.log("edit props----->", data);

  const navigate = useNavigate();

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (e, newValue) => {
    setTabValue(newValue)
  }

  const handleClick = (buttonName) => {
    console.log("buttonName---->", buttonName);

    if (buttonName === "Save & Continue") {
      basicFormRef.current.requestSubmit();
    }
    if (buttonName === "Send for Approval") {
      financialFormRef.current.requestSubmit();
    }
  };

  const saveContinueBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick("Save & Continue"),
    name: "Save & Continue",
  };

  const sendApprovalBtnProps = {
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => handleClick("Send for Approval"),
    name: "Send for Approval",
    disable: !selectRecommendation
  };

  return (
    <Layout componentName={formTitleValue}>
      <TabContext value={tabValue}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              width: "99%",
              marginBottom: "1rem",
              marginLeft: "5px",
              marginRight: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: "16px",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="form tabs"
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                "& .MuiTab-root": {
                  minWidth: "auto",
                },
              }}
            >
              <Tab
                label="Basic Details"
                value="basicDetails"
                sx={{ fontWeight: "500" }}
              />
              <Tab
                label="Financial Details"
                value="financialDetails"
                sx={{ fontWeight: "500" }}
              />
            </TabList>
          </Box>
        </Box>
        <Box>
          <TabPanel
            value="basicDetails"
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              width: "97%",
            }}
          >
            <BasicDetails
              basicFormRef={basicFormRef}
              formTitleValue={formTitleValue}
              id={projectId}
              mode={action}
              data={data}
              handleTabChange={handleTabChange}
            />
          </TabPanel>
          <TabPanel
            value="financialDetails"
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              width: "97%",
            }}
          >
            <FinancialDetails
              financialFormRef={financialFormRef}
              formTitleValue={formTitleValue}
              id={projectId}
              mode={action}
              selectRecommendation={selectRecommendation}
              setSelectRecommendation={setSelectRecommendation}
            />
          </TabPanel>
        </Box>
      </TabContext>

      <div className="btn_alignment">
        <Box>
          {tabValue === "basicDetails" && (
            <GeneralButton {...saveContinueBtnProps} />
          )}
          {tabValue === "financialDetails" && (
            <GeneralButton {...sendApprovalBtnProps} />
          )}
        </Box>
      </div>
    </Layout>
  );
}

export default EditMain;
