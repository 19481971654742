import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Tab,
  Grid,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CustomTabList from "../../components/TabList/CustomTabList";
import Layout from "../../components/layout/layout";
import { APPROVING_ROLES, BUTTON_COLOUR } from "../../constants/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import ClosureDetailsForm from "../../components/closureDetails/closureDetails";
import AccountDetailsForm from "../../components/accountDetails/accountDetails";
import GeneralButton from "../../components/button/GeneralButton";
import { project_list } from "../../constants/data";
import "./closureForm.css";
import { TabScrollButton } from "@mui/material";
import ScrollButtonTopAndDown from "../../components/button/ScrollButtonTopAndDown";
import { validateClosureForm } from "../../utils/validations";
import dayjs from "dayjs";

const ClosureForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const detailsOfForm = role === "director" ? true : false;
  const closureFormRef = useRef(null);

  const { action, data } = location.state || {};
  // console.log(data, action);
  const [selectedTab, setSelectedTab] = useState("closureDetails");

  const [errors, setErrors] = useState({});

  const fetchData = data
    ? data.closure_form_details.closure_details
      ? data.closure_form_details.closure_details
      : ""
    : "";

  const readOnlyMode = role === "director" ? true : false;
  const initialData = {
    typeOfClient: fetchData.typeOfClient,
    titleOfConsultancyProject: fetchData.titleOfConsultancyProject
      ? fetchData.titleOfConsultancyProject
      : "",
    nameofOtherConsultant: fetchData.nameofOtherConsultant
      ? fetchData.nameofOtherConsultant
      : "",
    nameOfConsultantInCharge: fetchData.nameOfConsultantInCharge
      ? fetchData.nameOfConsultantInCharge
      : "",
    nameOfClientAndAddress: fetchData.nameOfClientAndAddress
      ? fetchData.nameOfClientAndAddress
      : "",
    grossConsultantCharges: fetchData.grossConsultantCharges
      ? fetchData.grossConsultantCharges
      : "",
    dateOfCompletationOfWork: fetchData?.dateOfCompletationOfWork
      ? dayjs(fetchData.dateOfCompletationOfWork)
      : null,
    dateOfCommencementOfWork: fetchData?.dateOfCommencementOfWork
      ? dayjs(fetchData.dateOfCommencementOfWork)
      : null,
  };

  const [value, setValue] = useState();



  const handleTabChange = (event, newValue, currentValue) => {
    const normalizedTab = newValue;
    setSelectedTab(newValue);
  };

  const buttonText = () => {
    if (APPROVING_ROLES.includes(role)) {
      if (selectedTab === "closureDetails") {
        return "Continue";
      } else {
        return "Approve";
      }
    } else {
      if (selectedTab === "closureDetails") {
        return "Save and Continue";
      } else if (selectedTab === "accountDetails") {
        return "Send for Approval";
      }
    }
  };

  const tabList = [
    {
      label: "Closure Details",
      value: "closureDetails",
    },
    {
      label: "Account Details",
      value: "accountDetails",
    },
  ];

  const handleClick = () => {
    if (buttonText() === "Continue") {
      setSelectedTab("accountDetails");
    } else if (buttonText() === "Approve") {
      // console.log("clicked approve");
    } else if (buttonText() === "Save and Continue") {
      if (closureFormRef.current) {
        closureFormRef.current.requestSubmit();
      }
    }
  };

  const showCancelBtn = () => {
    // console.log("selected tab---->", selectedTab);
    if (action === "approve" && selectedTab === "accountDetails") {
      // console.log("condition is true");
      return true;
    } else {
      // console.log("condition is false");
      return false;
    }
  };

  const handleCancel = () => {
    navigate("/projects");
  };

  const handleFinanceFormSubmission = (event) => {
    event.preventDefault();
    // console.log("closure form is submitted");
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());

    // console.log("formValues---->",formValues);

    const newErrors = validateClosureForm(formValues);
    // console.log("newErrors---->",newErrors);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0){
      setSelectedTab("accountDetails");
    }


  };

  return (
    <Layout componentName={"Closure Form"}>
      <Box>
        <TabContext value={selectedTab} TabScrollButton>
          <Box
            sx={{
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              width: "99%",
              marginBottom: "1rem",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <CustomTabList
              tabs={tabList}
              onChange={handleTabChange}
              aria-label="form tabs"
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                "& .MuiTab-root": {
                  minWidth: "auto",
                },
              }}
            />
          </Box>
          <Box scrollButtons>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TabPanel
                value="closureDetails"
                sx={{
                  boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "25px",
                  width: "95%",
                }}
              >
             <form ref={closureFormRef} onSubmit={handleFinanceFormSubmission}>
                <ClosureDetailsForm
                  action={action}
                  data={data}
                  readOnlyMode={readOnlyMode}
                  initialData={initialData}
                  value={value}
                  setValue={setValue}
                  errors={errors}
                  setErrors={setErrors}
                />
              </form>
              </TabPanel>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TabPanel
                value="accountDetails"
                sx={{
                  boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "25px",
                  width: "95%",
                }}
              >
                <AccountDetailsForm action={action} data={data} />
              </TabPanel>
            </div>
          </Box>
        </TabContext>
      </Box>
      <div className="btn_alignment">
        {selectedTab === "accountDetails" && role !== "register" ? (
          <GeneralButton
            name={"Reject"}
            type={"submit"}
            color={"#f8fafc"}
            borderRadius={"30px"}
            backgroundColor={"var(--Red-rose-600, rgba(225, 29, 72, 1))"}
            width={"220px"}
            height={"48px"}
            marginRight={"16px"}
            // buttonHandler={() => {
            //   handleReject();
            // }}
          />
        ) : null}
        {showCancelBtn &&
          (role === "register" ||
          (role === "director" && selectedTab !== "accountDetails") ? (
            <GeneralButton
              name={"cancel"}
              borderRadius={"30px"}
              width={"220px"}
              height={"48px"}
              marginRight={"16px"}
              color={"grey"}
              border={"1px solid grey"}
              buttonHandler={() => {
                handleCancel();
              }}
            />
          ) : (
            ""
          ))}

        <GeneralButton
          name={buttonText()}
          type={"submit"}
          color={"#f8fafc"}
          borderRadius={"30px"}
          backgroundColor={BUTTON_COLOUR}
          width={"220px"}
          height={"48px"}
          buttonHandler={() => {
            handleClick();
          }}
        />
      </div>
    </Layout>
  );
};

export default ClosureForm;
