export const decodeJWT = (token) => {
  try {
    const base64Url = token.split(".")[1];

    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((char) => `%${("00" + char.charCodeAt(0).toString(16)).slice(-2)}`)
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
};

export const GetDecodedToken = () => {
  const token = localStorage.getItem("jwtToken");
  if (token) {
    return decodeJWT(token);
  } else {
    console.log("No JWT token found in localStorage.");
    return null;
  }
};
