import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ScrollButtonTopAndDown from "../../components/button/ScrollButtonTopAndDown";
import { FormControl } from "@mui/base";
import deleteIcon from "../../assets/images/delete.png";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../src/components/projectDetails/projectDetails.css";
import { getTotalPercentage } from "./formUtils";
import {
  CreateProjectByProjectId,
  GetFinancialDetailsByProjectId,
  ProjectApproveByProjectId,
  ProjectRejectByProjectId,
  UpdateProjectByProjectId,
} from "../../api/ApiService";
import { category_wise } from "../../constants/data";
import Dropdown from "../../components/dropdown/dropdown";
import "../../components/projectDetails/projectDetails.css";
import PageLoader from "../../components/Loader/PageLoader";
import { GetDecodedToken } from "../../constants/StoredToken";
import NotifyMessage from "../../components/NotifyMessage/NotifyMessage";
import RejectedPopUp from "../../components/rejectedPopUp/rejectedPopUp";

function FinancialDetails(props) {
  const {
    financialFormRef,
    formTitleValue,
    id,
    mode,
    triggerFunction = () => {},
    triggerReject = () => {},
    selectRecommendation,
    setSelectRecommendation
  } = props;
  const projRevenueId = localStorage.getItem("projRevenueId");
  const projectId = id;
  // alert("api is render or not ", formTitleValue);
  const [totalSanctionAmount, setTotalSanctionAmount] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [otherExpenses, setOtherExpenses] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [calOverHeadAmount, setCalOverHeadAmount] = useState(0);
  const [totalExp, setTotalExp] = useState(0);
  const [addBudgetExpendature, setAddBudgetExpendature] = useState([]);
  const [selectCategory, setSelectCategory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [value43, setValue43] = useState(0);
  const [value52, setValue52] = useState(0);
  const location = useLocation();
  const role = localStorage.getItem("role");
  const readOnlyMode = mode === "view" || mode === "approve";

  const [selectGstNum, setSelectGstNum] = useState("no");
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const { action, data } = location.state || {};
  const [selectedTab, setSelectedTab] = useState(
    action === "reject" ? "financialDetails" : "basicDetails"
  );

  // const [selectRecommendation, setSelectRecommendation] = useState(
  //   data?.financialDetails?.recommendation
  //     ? data?.financialDetails?.recommendation
  //     : false
  // );
  const [extraExpDetails, setExtraExpDetails] = useState({
    id: 2,
    expenditureName: "",
    amount: "",
  });

  const [projectExpenditure, setProjectExpenditure] = useState(0);
  const [instituteShare, setInstituteShare] = useState("");
  const [totalExpenditure, settotalExpenditure] = useState("");
  const decodeTokenIs = GetDecodedToken();
  const [institutesShare, setInstitutesShare] = useState("");
  const [totalProjectExpenditure, setTotalProjectExpenditure] = useState("");
  const [files, setFiles] = useState([]);
  const [financeFormValues, setFinanceFormValues] = useState({
    consultancyFee: "",
    overheadAmount: "",
    gst: "",
    grossConsultancyOfCharges: "",
    totalExpenditure: "",
    instituteShare: "",
    otherExpenditure: "",
  });

  const [formValues, setFormValues] = useState({
    noInstituteFacilities: false,
    instituteFacilities: false,
    testingAndInterpretation: false,
    isGstNumAvailable: "",
    gstNumber: "",
    totalSanctionAmount: "",
    consultancyFee: "",
    overheadAmount: "",
    gst: "",
    grossConsultancyOfCharges: "",
  });
  const [fieldValues, setFieldValues] = useState({});
  console.log("fieldValues value iis ", fieldValues);
  const [selectCategoryOption, setSelectedCategoryOptions] = useState([]);
  const [notify, setNotify] = useState({ message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showRejected, setShowRejected] = useState(false);
  const [reasonText, setReasonText] = useState();
  const savedProjectId =  id || localStorage.getItem("projectId") ;
  console.log("savedProjectId---->", savedProjectId);
  const [isOpen, setIsOpen] = useState({
    project: false,
  });
  const facilitiesValues = {
    noInstituteFacilities: 0.25,
    instituteFacilities: 0.3,
    testingAndInterpretation: 0.35,
  };
  const handleSelectCategoryfuction = (e, newValue) => {
    setSelectedCategoryOptions(newValue);
  };

  useEffect(() => {
    const totalExpValue = parseFloat(financeFormValues.totalExpenditure) || 0;
    let percentage = 0;
    if (selectedOptions.includes("noInstituteFacilities")) {
      percentage = 0.25;
    } else if (selectedOptions.includes("instituteFacilities")) {
      percentage = 0.3;
    } else if (selectedOptions.includes("testingAndInterpretation")) {
      percentage = 0.35;
    }
    const calValue = percentage * totalExpValue;
    setCalOverHeadAmount(calValue);
  }, [selectedOptions, financeFormValues.totalExpenditure]);

  const recalculatetotalExpenditure = (budgetItems) => {
    const totalProjectExpenditure = budgetItems.reduce(
      (acc, item) => acc + item.amount,
      0
    );
    // const share1 = totalProjectExpenditure * 0.25;
    // const share2 = totalProjectExpenditure * 0.3;
    // const share3 = totalProjectExpenditure * 0.35;

    console.log("totalProjectExpenditure---->",totalProjectExpenditure);
 
    const gstPercentage = calculateFacilitiesPercentage(selectedOptions);
    // const totalInstituteShare = share1 + share2 + share3;
    const totalInstituteShare = totalProjectExpenditure * gstPercentage
    const finalTotalExpenditure = totalProjectExpenditure + totalInstituteShare;
    setProjectExpenditure(totalProjectExpenditure);
    setInstitutesShare(totalInstituteShare);
    setTotalProjectExpenditure(finalTotalExpenditure);
  };

  useEffect(() => {
    setFinanceFormValues((prevValues) => ({
      ...prevValues,
      totalExpenditure: totalExp,
      instituteShare: calOverHeadAmount,
      otherExpenditure: totalExp + calOverHeadAmount,
    }));
  }, [totalExp, calOverHeadAmount]);

  useEffect(() => {
    const totalExpValue = otherExpenses.reduce(
      (sum, expense) => sum + parseFloat(expense.amount || 0),
      0
    );
    const totalPercentage = getTotalPercentage(selectedOptions);
    const calValue = totalPercentage * 0.1 * totalExpValue;

    setTotalExp(totalExpValue);
    setCalOverHeadAmount(calValue);
    setFinanceFormValues((prevValues) => ({
      ...prevValues,
      totalExpenditure: totalExpValue,
      instituteShare: calValue,
      otherExpenditure: totalExpValue + calValue,
    }));
  }, [otherExpenses, selectedOptions]);

  useEffect(() => {
    const totalPercentage = getTotalPercentage(selectedOptions);
    const calValue = totalPercentage * 0.1 * totalExp;
    setCalOverHeadAmount(calValue);
  }, [selectedOptions, totalExp]);

  useEffect(() => {
    setFinanceFormValues((prevValues) => ({
      ...prevValues,
      instituteShare: calOverHeadAmount,
      otherExpenditure: totalExp + calOverHeadAmount,
    }));
  }, [totalExp, calOverHeadAmount]);

  useEffect(() => {
    if (mode === "approve") {
      triggerFunction(handleApprove);
    }
  }, [triggerFunction]);

  useEffect(() => {
    triggerReject(handleRejectProject);
  }, [triggerReject]);

  const handleChangeAmount = (event, id) => {
    const { name, value } = event.target;
    const amount = parseFloat(value);
    const numericValue = isNaN(amount) ? 0 : amount;
    setAddBudgetExpendature((prevState) => {
      const updatedExpenditure = prevState.map((item) =>
        item.id === id ? { ...item, [name]: numericValue } : item
      );
      recalculatetotalExpenditure(updatedExpenditure);
      return updatedExpenditure;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (name === "totalSanctionAmount") {
      const input = Math.round(value * 100) / 100
      setTotalSanctionAmount(input);
    } 
    // else if (name === "gstNumber") {
    //   setGstNumber(value);
    // }
  };
  const calculateFacilitiesPercentage = (selectedOptions) => {
    return selectedOptions.reduce((total, option) => {
      return total + (facilitiesValues[option] || 0);
    }, 0);
  }

  useEffect(() => {
    if(totalSanctionAmount > 0){
      const totalSanction = parseFloat(totalSanctionAmount) || 0;
      const gstPercentage = calculateFacilitiesPercentage(selectedOptions);

      const overheadAmount = totalSanction * gstPercentage;
      const consultancyFee = totalSanction + overheadAmount;

      const gst = consultancyFee * 0.18;
      const grossConsultancyOfCharges = consultancyFee + overheadAmount + gst;
  
      setFinanceFormValues((prevValues) => ({
        ...prevValues,
        consultancyFee :  Math.round(consultancyFee * 100) / 100  ,
        overheadAmount :  Math.round(overheadAmount * 100) / 100 ,
        gst :  Math.round(gst * 100) / 100 ,
        grossConsultancyOfCharges : Math.round(grossConsultancyOfCharges * 100) / 100  ,
      }));
    }
  }, [totalSanctionAmount , selectedOptions]);

  const handleGstAvailability = (e) => {
    const value = e.target.value;
    setSelectGstNum(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      isGstNumAvailable: value,
    }));
    if (value === "no") {
      setGstNumber("");
    }
  };

  const handleInstituteShareChange = (event) => {
    console.log("event--->",event.target.value);
    
    setInstituteShare(event.target.value);
  };

  const handletotalExpenditureChange = (event) => {
    console.log("event---->",event);
    
    settotalExpenditure(event.target.value);
  };

  const handleAddBudgetInput = () => {
    const newBudget = {
      id: new Date().getTime(),
      expenditureName: "",
      amount: 0,
      isSelected: addBudgetExpendature.length === 1,
    };

    setAddBudgetExpendature((prevState) => {
      const updatedExpenditure = [...prevState, newBudget];
      recalculatetotalExpenditure(updatedExpenditure);
      return updatedExpenditure;
    });
  };

  const handleDescriptionChange = (event, id) => {
    const { value } = event.target;
    setAddBudgetExpendature((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, expenditureName: value } : item
      )
    );
  };

  const initializeValue = () => {
    if (data?.financialDetails?.consultancyProject?.length > 0) {
      setSelectedOptions(data?.financialDetails?.consultancyProject);
    }
  };

  useEffect(() => {
    initializeValue();
  }, []);

  useEffect(() => {
    const { expenditureName, amount } = extraExpDetails;
    if (!!expenditureName && !!amount) {
      setOtherExpenses([...otherExpenses, extraExpDetails]);
      calculateTotal();
    }
  }, [extraExpDetails]);

  const calculateTotal = () => {
    const form = document.querySelector("form");
    const formData = new FormData(form);
    const amount = parseFloat(formData.get("otherExpenditure"));
    const value = otherExpenses.reduce(
      (total, expenditure) => total + parseFloat(expenditure.amount),
      0
    );
    const totalValue = value + amount;
    setTotalExp(totalValue);
    return value;
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value]
    );

    const prevValue = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];
    const totalPercentage = getTotalPercentage(prevValue);
    const calValue = totalPercentage * 0.1 * totalExp;
    setCalOverHeadAmount(calValue);
  };

  const handleSelectRecommendation = () => {
    setSelectRecommendation(!selectRecommendation);
  };

  const handleReject = () => {
    setShowRejected(true);
  };

  useEffect(() => {
    if (action === "reject") {
      handleReject();
    }
  }, [selectedTab]);

  const decodeTokenData = GetDecodedToken();
  console.log("decodeTokenData", decodeTokenData);

  const handleRejectProject = () => {
    setShowRejected(true);
  };

  const rejectHandler = async (projectId, reason) => {
    // console.log(
    //   "Reject handler called with IDs:",
    //   projectId,
    //   "and reason:",
    //   reason
    // );
    setLoading(true);
    const reqBody = {
      status: "REJECTED",
      approved_by: decodeTokenIs?.userId,
      role: decodeTokenIs?.roleType,
      reason: reason,
    };
    console.log("reqBody--->", reqBody);

    try {
      let response = await ProjectRejectByProjectId(projectId, reqBody);
      if (response?.status === 200) {
        setNotify({
          message: response.data.message || "Projects rejected successfully",
          type: "success",
        });
        setLoading(false);
        setTimeout(() => navigate("/projects"), 2000);
      }
    } catch (error) {
      setNotify({
        message: "An error occurred during rejection",
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleApprove = async () => {
    const roleWiseStatus = () => {
      if (decodeTokenData.roleType === "DRND") {
        return "PENDING_DEAN";
      } else if (decodeTokenData.roleType === "ARND") {
        return "PENDING_DARND";
      } else if (decodeTokenData.roleType === "DEAN") {
        return "APPROVED_DEAN";
      } else if (decodeTokenData.roleType === "DIRECTOR") {
        return "APPROVED_DIRECTOR";
      }
    };

    const status = roleWiseStatus();

    const reqBody = {
      status: status,
      approved_by: decodeTokenData.userId,
      role: decodeTokenData.roleType,
    };

    try {
      const response = await ProjectApproveByProjectId(savedProjectId, reqBody);
      if (response.status === 200) {
        setNotify({
          message: response.data.message || "Project approved successfully",
          type: "success",
        });
        setLoading(false);
        setTimeout(() => navigate("/projects"), 2000);
      } else {
        setNotify({ message: "Failed to approve project", type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setNotify({
        message: "An error occurred during approval",
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleUpdateFinancialDetails = async () => {
    console.log("update details");

    console.log("addBudgetExpendature--->", addBudgetExpendature);

    console.log("selectGstNum----->", selectGstNum);

    const payload = {
      id: projRevenueId,
      project_id: savedProjectId,
      consultancy_fee: financeFormValues?.consultancyFee || "",
      overhead_amount: financeFormValues?.overheadAmount || "",
      gst_number: selectGstNum === "yes" ? gstNumber : "",
      gst: financeFormValues?.gst || "",
      gross_consultancy_charges:
        financeFormValues?.grossConsultancyOfCharges || "",
      facilities_usage: {
        no_institute_facilities: selectedOptions.includes(
          "noInstituteFacilities"
        ),
        institute_facilities: selectedOptions?.includes("instituteFacilities"),
        primary_testing: selectedOptions?.includes("testingAndInterpretation"),
      },
      budget_expenditures: addBudgetExpendature?.map((item) => ({
        id: item?.id,
        expenditue: item?.expenditure,
        expense_category: item?.expense_category,
        description: item?.description,
        amount: item?.amount,
      })),
      total_expenditure: totalProjectExpenditure || "",
      institute_share: institutesShare || "",
      total_sanction_amount: totalSanctionAmount || "",
      recommended:selectRecommendation
    };

    console.log("payload---->", payload);
    try {
      const response = await UpdateProjectByProjectId(payload);
      if (response?.status === 200) {
        console.log("Project updated successfully");
      }

      console.log("updated response---->", response);
    } catch (error) {
      console.error("Error submitting financial details", error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (mode === "add") {
      handleCreateFinancialDetails();
    } else if (mode === "edit") {
      handleUpdateFinancialDetails();
    }
  };
  const projectApprovalHandler = async () => {
    const reqBody = {
      status: "PENDING_ARND",
      approved_by: decodeTokenData.userId,
      role: decodeTokenData.roleType,
    };
    try {
      const response = await ProjectApproveByProjectId(savedProjectId, reqBody);
      if (response.status === 200) {
        setNotify({
          message: response.data.message || "Project approved successfully",
          type: "success",
        });
        setLoading(false);
        setTimeout(() => navigate("/projects"), 2000);
      } else {
        setNotify({ message: "Failed to approve project", type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setNotify({
        message: "An error occurred during approval",
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleCreateFinancialDetails = async () => {
    const payload = {
      project_id: savedProjectId,
      consultancy_fee: financeFormValues?.consultancyFee || "",
      overhead_amount: financeFormValues?.overheadAmount || "",
      gst_number: selectGstNum === "yes" ? gstNumber : "",
      gst: financeFormValues?.gst || "",
      gross_consultancy_charges:
        financeFormValues?.grossConsultancyOfCharges || "",
      facilities_usage: {
        no_institute_facilities: selectedOptions.includes(
          "noInstituteFacilities"
        ),
        institute_facilities: selectedOptions?.includes("instituteFacilities"),
        primary_testing: selectedOptions?.includes("testingAndInterpretation"),
      },
      budget_expenditures: addBudgetExpendature?.map((expense) => ({
        id: null,
        expense_category: selectCategoryOption,
        description: expense?.expenditureName,
        amount: expense?.amount,
      })),
      total_expenditure: totalProjectExpenditure || "",
      institute_share: institutesShare || "",
      expenditue: totalExpenditure,
      total_sanction_amount: totalSanctionAmount || "",
      created_by: 1,
      updated_by: 1,
      recommended:selectRecommendation
    };
    try {
      const response = await CreateProjectByProjectId(payload);
      if (response?.status === 200) {
        projectApprovalHandler();
        console.log("Project created successfully");
      }

      // console.log(response.data);
    } catch (error) {
      console.error("Error submitting financial details", error);
    }
  };

  useEffect(() => {
    const getFinancialDetails = async () => {
      setIsLoading(true);
      try {
        const response = await GetFinancialDetailsByProjectId(savedProjectId);
        console.log("get api response --->>", response);
        const details = response?.data;
        console.log("get api response details--->>", details);

        setFormValues({
          noInstituteFacilities:
            details?.facilities_usage?.no_institute_facilities || false,
          instituteFacilities:
            details?.facilities_usage?.institute_facilities || false,
          testingAndInterpretation:
            details?.facilities_usage?.primary_testing || false,
          gstNumber: details?.gst_number || "",
          totalSanctionAmount: details?.total_sanction_amount || "",
          consultancyFee: details?.consultancy_fee || "",
          overheadAmount: details?.overhead_amount || "",
          gst: details?.gst || "",
          grossConsultancyOfCharges: details?.gross_consultancy_charges || "",
        });

        setSelectedOptions(
          [
            details?.facilities_usage?.no_institute_facilities
              ? "noInstituteFacilities"
              : "",
            details?.facilities_usage?.institute_facilities
              ? "instituteFacilities"
              : "",
            details?.facilities_usage?.primary_testing
              ? "testingAndInterpretation"
              : "",
          ].filter(Boolean)
        );
        const updatedExpenditures = (details?.budget_expenditures || []).map(
          (item) => ({
            ...item,
            expenseCategory: item.expense_category,
          })
        );

        setAddBudgetExpendature(updatedExpenditures);

        setFinanceFormValues({
          consultancyFee: details?.consultancy_fee || "",
          overheadAmount: details?.overhead_amount || "",
          gst: details?.gst || "",
          grossConsultancyOfCharges: details?.gross_consultancy_charges || "",
          totalExpenditure: details?.total_expenditure || "",
          instituteShare: details?.institute_share || "",
          otherExpenditure:
            (details?.total_expenditure || 0) + (details?.institute_share || 0),
        });

        setProjectExpenditure(details?.total_expenditure || 0);
        setInstitutesShare(details?.institute_share || 0);
        setTotalProjectExpenditure(
          (details?.total_expenditure || 0) + (details?.institute_share || 0)
        );

        setSelectRecommendation(details?.recommended || false);

        setFieldValues({
          ...details,
          category_wise:
            details?.budget_expenditures?.map(
              (item) => item.expense_category
            ) || [],
          description:
            details?.budget_expenditures?.map((item) => item.description) || [],
          amount:
            details?.budget_expenditures?.map((item) => item.amount) || [],

        });
        console.log("gst num---->", details?.gst_number);
        console.log("cond---->", !!details?.gst_number);

        const isGstAvailable = !!details?.gst_number ? "yes" : "no";
        console.log("isGstAvailable---->", isGstAvailable);

        setSelectGstNum(isGstAvailable);
        setGstNumber(details?.gst_number || "");
        setTotalSanctionAmount(details?.total_sanction_amount || "");
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching financial details", error);
      }
    };
    if (
      formTitleValue === "Update Project" ||
      formTitleValue === "View Project"
    ) {
      getFinancialDetails();
    }
  }, []);

  const handleCloseRejected = () => {
    setShowRejected(false);
  };

  const rejectedProps = {
    showRejected: showRejected,
    handleCloseRejected: handleCloseRejected,
    title: "Reject",
    description: "Please enter the reason for rejection?",
    setReasonText: setReasonText,
    reasonText: reasonText,
    handleYes: () => {
      // handleRejectProject(isOpen.id, reasonText);
      rejectHandler(savedProjectId, reasonText);
    },
    handleNo: () => {
      setShowRejected(false);
      setReasonText("");
    },
  };

  console.log("selected options----->",selectedOptions);
  

  return (
    <form ref={financialFormRef} onSubmit={submitHandler}>
      {isLoading && <PageLoader loadingStatus={isLoading} />}
      {notify.message && (
        <NotifyMessage message={notify.message} type={notify.type} />
      )}
      <RejectedPopUp {...rejectedProps} />
      {
        <>
          <Box className="form_container scroll-container">
            <Typography
              sx={{
                width: "auto",
                paddingTop: "8px",
                fontWeight: "700",
                fontSize: "16px",
              }}
              id="top"
            >
              1. THE CONSULTANCY PROJECT INVOLVES (Tick appropriate one)
            </Typography>
            <Box style={{ marginLeft: "16px" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    width: "45.5%",
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  1.1 Use of no Institute facilities
                </Typography>
                <FormControlLabel
                  name="noInstituteFacilities"
                  value="noInstituteFacilities"
                  control={
                    <Checkbox
                      defaultValue={fieldValues.noInstituteFacilities}
                      value="noInstituteFacilities"
                      checked={selectedOptions.includes(
                        "noInstituteFacilities"
                      )}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                  }
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    width: "45.5%",
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  1.2 Use of Institute facilities such as Laboratory and
                  computer
                </Typography>
                <FormControlLabel
                  name="instituteFacilities"
                  value="instituteFacilities"
                  control={
                    <Checkbox
                      defaultValue={fieldValues.instituteFacilities}
                      value="instituteFacilities"
                      checked={selectedOptions.includes("instituteFacilities")}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                  }
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    width: "45.5%",
                    fontSize: "16px",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  1.3 Primarily testing & Interpretation
                </Typography>
                <FormControlLabel
                  name="testingAndInterpretation"
                  value="testingAndInterpretation"
                  control={
                    <Checkbox
                      defaultValue={fieldValues.testingAndInterpretation}
                      value="testingAndInterpretation"
                      checked={selectedOptions.includes(
                        "testingAndInterpretation"
                      )}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                  }
                />
              </Box>
              {errors?.consultancyProject && (
                <FormHelperText
                  error="true"
                  sx={{
                    width: "57%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {errors?.consultancyProject}
                </FormHelperText>
              )}
            </Box>
            <Box className="field_container">
              <Typography sx={{ width: "36%", paddingTop: "8px" }}>
                <span style={{ fontWeight: "bold" }}>2.</span> Is GST number
                available ?
              </Typography>
              <span className="field_align">:</span>
              <span className="field_align"></span>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="is-gst-num-available"
                  name="isGstNumAvailable"
                  onClick={handleGstAvailability}
                  value={selectGstNum}
                  defaultValue={selectGstNum}
                >
                  <FormControlLabel
                    value={"yes"}
                    control={<Radio />}
                    label="Yes"
                    disabled={readOnlyMode}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="No"
                    disabled={readOnlyMode}
                  />
                </RadioGroup>
                {errors?.isGstNumAvailable && (
                  <FormHelperText error="true">
                    {errors?.isGstNumAvailable}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            {selectGstNum === "yes" && (
              <div className="field_container">
                <Typography sx={{ width: "36%", paddingTop: "8px" }}>
                  {"\u00A0\u00A0\u00A0\u00A0\u00A0"}
                  {"2.1. GST number"}
                </Typography>
                <span className="field_align">:</span>
                <span className="field_align"></span>
                <TextField
                  name="gstNumber"
                  error={!!errors?.gstNumber}
                  helperText={errors?.gstNumber}
                  defaultValue={fieldValues.gstNumber}
                  sx={{
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  }}
                  type="text"
                  value={gstNumber}
                  onChange={(e) => setGstNumber(e.target.value)}
                  disabled={readOnlyMode}
                />
              </div>
            )}
            <div className="field_container">
              <Typography sx={{ width: "36%", paddingTop: "8px" }}>
                <span style={{ fontWeight: "bold" }}>3.</span> Total sanction
                amount
              </Typography>
              <span className="field_align">:</span>
              <span className="field_align">Rs</span>
              <TextField
                name="totalSanctionAmount"
                defaultValue={fieldValues.totalSanctionAmount}
                sx={{
                  width: "350px",
                  marginBottom: "16px",
                  "& .MuiInputBase-root": {
                    height: "35px",
                    borderRadius: "15px",
                  },
                }}
                type="number"
                value={totalSanctionAmount}
                onChange={handleInputChange}
                disabled={readOnlyMode}
              />
            </div>
            <div className="field_container">
              <Typography
                sx={{
                  width: "36%",
                  paddingTop: "8px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                <span style={{ fontWeight: "bold" }}>4.</span>
                {"   4.1 Consultancy Fee"}
              </Typography>
              <span className="field_align">:</span>
              <span className="field_align">Rs</span>
              <TextField
                name="consultancyFee"
                type="number"
                defaultValue={fieldValues.consultancyFee}
                value={financeFormValues.consultancyFee}
                onChange={(e) => {setFinanceFormValues({...financeFormValues, consultancyFee : e.target.value})}}
                sx={{
                  width: "350px",
                  marginBottom: "16px",
                  "& .MuiInputBase-root": {
                    height: "35px",
                    borderRadius: "15px",
                  },
                }}
                disabled={readOnlyMode}
              />
            </div>
            <div style={{ marginLeft: "24px" }}>
              <div className="field_container">
                <Typography
                  sx={{
                    width: "35%",
                    paddingTop: "8px",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {"4.2 Overhead amount"}
                </Typography>
                <span className="field_align">:</span>
                <span className="field_align">Rs</span>
                <TextField
                  name="overheadAmount"
                  type="number"
                  defaultValue={fieldValues.overheadAmount}
                  value={financeFormValues.overheadAmount}
                  onChange={(e) => {setFinanceFormValues({...financeFormValues, overheadAmount: e.target.value})}}
                  sx={{
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  }}
                  disabled={readOnlyMode}
                />
              </div>
            </div>
            <div style={{ marginLeft: "24px" }}>
              <div className="field_container">
                <Typography
                  sx={{
                    width: "35%",
                    paddingTop: "8px",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {"4.3 GST(18% of (a) above)"}
                  <br />
                  {"whenever application(see reverse page for details)"}
                </Typography>
                <span className="field_align">:</span>
                <span className="field_align">Rs</span>
                <TextField
                  name="gst"
                  type="number"
                  defaultValue={fieldValues.gst}
                  value={financeFormValues.gst}
                  sx={{
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  }}
                  onChange={(e) => setFinanceFormValues({...financeFormValues, gst: e.target.value})}
                  disabled={readOnlyMode}
                />
              </div>
            </div>
            <div style={{ marginLeft: "24px" }}>
              <div className="field_container">
                <Typography
                  sx={{
                    width: "35%",
                    paddingTop: "8px",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {"4.4 Gross Consultancy of Charges ( 4.1 + 4.2 + 4.3 )"}
                </Typography>
                <span className="field_align">:</span>
                <span className="field_align">Rs</span>
                <TextField
                  name="grossConsultancyOfCharges"
                  type="number"
                  defaultValue={fieldValues.grossConsultancyOfCharges}
                  value={financeFormValues.grossConsultancyOfCharges}
                  onChange={(e) => setFinanceFormValues({...financeFormValues, grossConsultancyOfCharges: e.target.value})}
                  sx={{
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  }}
                  disabled={readOnlyMode}
                />
              </div>
            </div>

            {/* ///////////////////////////////////////////////////////////// */}

            <Typography
              sx={{ width: "35%", fontWeight: "700", fontSize: "16px" }}
            >
              5. SHARING OF THE CONSULTANCY FEE
            </Typography>
            <Box>
              <Typography
                sx={{ width: "35%", fontWeight: "500", paddingTop: "8px" }}
              >
                Add Budget Expenditure
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <div className="field_container">
                  <Typography sx={{ ml: 1 }}>Category</Typography>
                  <Typography sx={{ ml: "370px" }}>Description</Typography>
                  <Typography sx={{ ml: "370px" }}>Amount</Typography>
                </div>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ display: "block" }}>
                    {addBudgetExpendature.map((file, index) => (
                      <Box sx={{ display: "flex" }} key={file.id}>
                        {console.log("file----->", file)}
                        <Box
                          sx={{
                            "& .MuiPaper-root": {
                              borderRadius: "20px",
                              width: "24%",
                            },
                          }}
                          PaperProps={{
                            component: "form",
                            onSubmit: (event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            },
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <div className="field_container">
                              <Dropdown
                                data={category_wise}
                                defaultValue={file?.expense_category}
                                // setSelectCategory={(category) =>
                                //   handleSelectCategory(category, file.id)
                                // }
                                selectCategory={file.expenseCategory}
                                handleSelectCategory={
                                  handleSelectCategoryfuction
                                }
                                sx={{
                                  ml: 5,
                                  marginBottom: "16px",
                                  "& .MuiInputBase-root": {
                                    borderRadius: "15px",
                                  },
                                }}
                                disabled={readOnlyMode}
                              />
                            </div>
                            <TextField
                              margin="dense"
                              id={`expenditureName-${file.id}`}
                              name="description"
                              defaultValue={file?.description}
                              label="Name"
                              type="text"
                              variant="outlined"
                              value={file.expenditureName}
                              onChange={(e) =>
                                handleDescriptionChange(e, file.id)
                              }
                              sx={{
                                ml: 5,
                                width: "400px",
                                marginBottom: "16px",
                                "& .MuiInputBase-root": {
                                  borderRadius: "15px",
                                },
                              }}
                              disabled={readOnlyMode}
                            />
                            <TextField
                              required
                              margin="dense"
                              id={`amount-${file.id}`}
                              name="amount"
                              label="Amount"
                              type="number"
                              defaultValue={file?.amount}
                              variant="outlined"
                              value={file.amount || ""}
                              sx={{
                                ml: 5,
                                width: "400px",
                                "& .MuiInputBase-root": {
                                  borderRadius: "15px",
                                },
                              }}
                              onChange={(e) => handleChangeAmount(e, file.id)}
                              disabled={readOnlyMode}
                            />
                          </Box>
                        </Box>
                        {index > 0 && !readOnlyMode && (
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setAddBudgetExpendature((prev) =>
                                prev.filter((item) => item.id !== file.id)
                              );
                            }}
                          >
                            <img src={deleteIcon} alt="delete" />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    color="primary"
                    aria-label="add"
                    size="large"
                    onClick={handleAddBudgetInput}
                    disabled={readOnlyMode}
                  >
                    <AddCircleIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            <Box sx={{ marginLeft: "16px" }}>
              <div className="field_container">
                <Typography sx={{ width: "35%", paddingTop: "8px" }}>
                  5.1 Project Expenditure
                </Typography>
                <span className="field_align">:</span>
                <span className="field_align">Rs</span>
                <TextField
                  name="totalExpenditure"
                  type="number"
                  // value={financeFormValues.totalExpenditure}
                  defaultValue={fieldValues.totalExpenditure}
                  value={projectExpenditure}
                  error={!!errors?.totalExpenditure}
                  helperText={errors?.totalExpenditure}
                  sx={{
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  }}
                  disabled={readOnlyMode}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFinanceFormValues((prev) => ({
                      ...prev,
                      totalExpenditure: value,
                    }));
                    setTotalExp(parseFloat(value) || 0);
                  }}
                />
              </div>
              <div className="field_container">
                <Typography sx={{ width: "35%", paddingTop: "8px" }}>
                  5.2 Institute's Share (% of 5.1)
                  <br />
                  (25% for 1.1, 30% for 1.2, and 35% for 1.3)
                </Typography>
                <span className="field_align">:</span>
                <span className="field_align">Rs</span>
                <TextField
                  name="instituteShare"
                  type="number"
                  defaultValue={fieldValues.number}
                  value={institutesShare}
                  error={!!errors?.instituteShare}
                  helperText={errors?.instituteShare}
                  sx={{
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  }}
                  onChange={(e) => {
                    setInstitutesShare(e.target.value)
                  }}
                  disabled={readOnlyMode}
                />
              </div>
              <div className="field_container">
                <Typography
                  sx={{
                    width: "35%",
                    paddingTop: "8px",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {"\u00A0\u00A0\u00A0\u00A0\u00A0"}5.3 Total Project
                  Expenditure
                </Typography>
                <span className="field_align">:</span>
                <span className="field_align">Rs</span>
                <TextField
                  name="otherExpenditure"
                  type="number"
                  // value={financeFormValues.otherExpenditure}
                  value={totalProjectExpenditure}
                  error={!!errors?.otherExpenditure}
                  helperText={errors?.otherExpenditure}
                  defaultValue={fieldValues.totalProjectExpenditure}
                  sx={{
                    width: "350px",
                    marginBottom: "16px",
                    "& .MuiInputBase-root": {
                      height: "35px",
                      borderRadius: "15px",
                    },
                  }}
                  disabled={readOnlyMode}
                  onChange={handletotalExpenditureChange}
                />
              </div>
              <div id="down">
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  RECOMMENDATION OF THE HOD/HOC/COORDINATOR
                </Typography>

                <Box
                  className="recommendation_cont"
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <Radio
                    checked={!!selectRecommendation}
                    onClick={handleSelectRecommendation}
                    value={selectRecommendation}
                    name="recommendation"
                    inputProps={{ "aria-label": "A" }}
                    sx={{ width: "8%" }}
                    disabled={readOnlyMode}
                    defaultValue={fieldValues.recommendation}
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      width: "70vw",
                    }}
                  >
                    This is to recommend that the consultancy Project belongs to
                    the category 1.1/1.2/1.3 (please strike the ones that are
                    not applicable) and Consultant(s) would be given necessary
                    facilities including leave of one day / week provided the
                    Consultant(s) make(s) necessary arrangement for the class
                    loads assigned to them
                  </Typography>
                </Box>
                {errors?.recommendation && (
                  <FormHelperText error="true">
                    {errors?.recommendation}
                  </FormHelperText>
                )}
              </div>
            </Box>
          </Box>

          <Box
            sx={{
              position: "sticky",
              display: "flex",
              justifyContent: "right",
              backgroundColor: "white",
              bottom: "0",
              left: "0",
            }}
          >
            <ScrollButtonTopAndDown />
          </Box>
        </>
      }
    </form>
  );
}

export default FinancialDetails;
