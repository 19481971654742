import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/iam/login/login";
import ProjectList from "./pages/projectList/projectList";
import AddNewProjectForm from "./pages/newProjectForm/AddFormMain";
import ClosureForm from "./pages/closureForm/closureForm";
import EmployeeList from "./pages/employeeList/EmployeeList";
import AddEmployee from "./pages/addEmployee/addEmployee";
import Dashboard from "./pages/dashboard/dashboard";
import PersonalInfoTab from "./components/PersonalInfo/tabNavigation/PersonalInfoTab";
import SuccessPDF from "./pages/newProjectForm/SuccessPDF";
import Financial_Dtls_Hoc from "./pages/Financial_Details/Financial_Dtls_Hoc";
import EditMain from "./pages/newProjectForm/edit/EditMain";

import AddFormMain from "./pages/newProjectForm/AddFormMain";
import EditEmployee from "./pages/editEmployee/EditEmployee";
import ViewEmployee from "./pages/viewEmployee/ViewEmployee";
import ViewMain from "./pages/newProjectForm/view/ViewMain";
import ApproveProjectMain from "./pages/newProjectForm/approveProject/ApproveProjectMain";

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      {/* <Route path="/PersonalInfo" element={<PersonalInfoTab />} /> */}
      <Route exact path="/PersonalInfo" element={<PersonalInfoTab />} />
      <Route exact path="/projects" element={<ProjectList />} />
      <Route exact path="/form" element={<AddFormMain />} />
      <Route exact path="/success" element={<SuccessPDF />} />
      <Route exact path="/closureForm" element={<ClosureForm />} />
      <Route exact path="/employeeList" element={<EmployeeList />} />
      <Route exact path="/employee" element={<AddEmployee />} />
      <Route path="/editEmployee/:id" element={<EditEmployee />} />
      <Route path="/viewEmployee/:id" element={<ViewEmployee />} />
      <Route exact path="/dashboard" element={<Dashboard />} />
      <Route exact path="/financial" element={<Financial_Dtls_Hoc />} />
      <Route exact path="/edit" element={<EditMain />} />
      <Route exact path="/view" element={<ViewMain />} />
      {/* <Route exact path="/view" element={<EditMain />} /> */}
      {/* <Route exact path="/view" element={<ViewEmployee />} /> */}
      {/* <Route exact path="/approve" element={<ViewEmployee />} /> */}
    </Routes>
  );
};

export default AppRoutes;
