import { Box, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React from "react";
import TablePieChart from "../../components/pieChart/pieChart";
import SummaryFilters from "./summaryFilters";
import { mileStoneData } from "../../constants/data";
import MilestoneFilters from "./milestoneFilters";
import MileStonePieChart from "./mileStonePieChart";

const MileStoneProgress = () => {
  const columns = [
    {
      field: "projectName",
      headerName: "Project Name",
      filterable: false,
      flex: 1,
      minWidth: 150,
      maxWidth: 400,
      sortable: false,
    },
    {
      field: "milestone",
      headerName: "Milestone",
      flex: 1,
      minWidth: 200,
      maxWidth: 400,
      sortable: false,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      minWidth: 200,
      maxWidth: 400,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      filterable: false,
      flex: 1,
      minWidth: 200,
      maxWidth: 400,
      sortable: false,
      renderCell: (params) => (
        <div>
          <FiberManualRecordIcon
            sx={{
              fontSize: "12px",
              marginRight: "5px",
              color: params?.row?.colorCode,
            }}
          />
          {params?.row?.status}
        </div>
      ),
    },
    {
      field: "progress",
      headerName: "Progress",
      flex: 1,
      minWidth: 200,
      maxWidth: 400,
      sortable: false,
      renderCell: (params) => (
        <TablePieChart
          id={params?.id}
          data={params?.row?.progress}
          color={params?.row?.colorCode}
        />
      ),
    },
  ];

  return (
    <Grid container mt={2} sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }}>
      <Grid
        item
        lg={8.7}
        sx={{
          height: "410px",
          boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
          padding: "1.5rem",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Milestone Progress
          </Typography>
          <MilestoneFilters />
        </Box>
        <Box sx={{ marginTop: "1rem" }}>
          <DataGrid
            rows={mileStoneData}
            columns={columns}
            hideFooterPagination={true}
          />
        </Box>
      </Grid>
      <Grid
        item
        lg={3}
        sx={{
          height: "410px",
          boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
          padding: "1.5rem",
          borderRadius: "10px",
        }}
      >
        <MileStonePieChart />
      </Grid>
    </Grid>
  );
};

export default MileStoneProgress;
