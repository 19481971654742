import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import GeneralButton from "../../components/button/GeneralButton";
import PageLoader from "../../components/Loader/PageLoader";
import NotifyMessage from "../../components/NotifyMessage/NotifyMessage";
import validatin from "../../utils/EmployeeValidation";
import {
  GetAssignedDesignation,
  GetDepartment,
  GetDesignation,
  UpdateEmployee,
} from "../../api/ApiService";
import { BUTTON_COLOUR } from "../../constants/Constant";

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    width: "80%",
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EmployeeOtherDetails = ({ data = {}, setOtherDetails }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [notify, setNotify] = useState({ message: "", type: "" });
  const [departmentData, setDepartmentData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [assignedDesignationData, setAssignedDesignationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [departmentId, setDepartmentId] = useState(null);
  const [designationId, setDesignationId] = useState(null);
  const [assignedDesignationId, setAssignedDesignationId] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setOtherDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "department") {
      const selectedDepartment = departmentData.find(
        (dept) => dept.fieldValue === value
      );
      if (selectedDepartment) {
        localStorage.setItem("departmentId", selectedDepartment.id);
        setDepartmentId(selectedDepartment.id);
      }
    } else if (name === "designation") {
      const selectedDesignation = designationData.find(
        (desig) => desig.fieldValue === value
      );
      if (selectedDesignation) {
        localStorage.setItem("designationId", selectedDesignation.id);
        setDesignationId(selectedDesignation.id);
      }
    } else if (name === "assignedDesignation") {
      const selectedAssignedDesignation = assignedDesignationData.find(
        (assignDesig) => assignDesig.fieldValue === value
      );
      if (selectedAssignedDesignation) {
        localStorage.setItem(
          "assignedDesignationId",
          selectedAssignedDesignation.id
        );
        setAssignedDesignationId(selectedAssignedDesignation.id);
      }
    }
  };

  const submitBtnProps = {
    name: "Save",
    type: "submit",
    color: "#f8fafc",
    borderRadius: "30px",
    backgroundColor: BUTTON_COLOUR,
    width: "220px",
    height: "48px",
    buttonHandler: () => {
      updateEmployee();
    },
  };

  const cancelBtnProps = {
    name: "Cancel",
    borderRadius: "30px",
    width: "220px",
    height: "48px",
    marginRight: "16px",
    color: "grey",
    border: "1px solid grey",
    buttonHandler: () => {
      navigate("/employeeList");
    },
  };

  useEffect(() => {
    Promise.all([
      fetchDepartmentData(),
      fetchDesignationData(),
      fetchAssignedDesignationData(),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setLoading(false);
      });
  }, []);

  const fetchDepartmentData = async () => {
    try {
      const response = await GetDepartment();
      if (response && response.status === 200) {
        setDepartmentData(response.data);
      } else {
        console.error("Failed to fetch department data");
      }
    } catch (err) {
      console.error("Error fetching department data:", err);
    }
  };

  const fetchDesignationData = async () => {
    try {
      const response = await GetDesignation();
      if (response && response.status === 200) {
        setDesignationData(response.data);
      } else {
        console.error("Failed to fetch designation data");
      }
    } catch (err) {
      console.error("Error fetching designation data:", err);
    }
  };

  const fetchAssignedDesignationData = async () => {
    try {
      const response = await GetAssignedDesignation();
      if (response && response.status === 200) {
        setAssignedDesignationData(response.data);
      } else {
        console.error("Failed to fetch assigned designation data");
      }
    } catch (err) {
      console.error("Error fetching assigned designation data:", err);
    }
  };

  const updateEmployee = async () => {
    try {
      const employeeData = {
        // firstName: "",
        // lastName: "",
        // mobileNumber: "",
        userDetails: [
          departmentId && {
            userDetailId: departmentId,
            filedId: localStorage.getItem("departmentId"),
            status: true,
          },
          designationId && {
            userDetailId: designationId,
            filedId: localStorage.getItem("designationId"),
            status: true,
          },
          assignedDesignationId && {
            userDetailId: assignedDesignationId,
            filedId: localStorage.getItem("assignedDesignationId"),
            status: true,
          },
        ],
      };

      const response = await UpdateEmployee(id, employeeData);
      if (response) {
        setNotify({
          message: "Employee Other Detail updated successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate("/employeeList");
          // onUpdateSuccess();
        }, 2000);
      } else {
        setNotify({
          message: "Employee Other Detail error .",
          type: "error",
        });
        console.log("Notify state updated:", notify);
      }
    } catch (err) {
      console.error("Error updating employee:", err);
    }
  };

  return (
    <Box sx={{ width: "100%", padding: "1rem", marginTop: "0.5rem" }}>
      <PageLoader loadingStatus={loading} />
      {notify.message && (
        <>
          {console.log("NotifyMessage should render now")}
          <NotifyMessage
            message={notify.message}
            type={notify.type}
            theme="light"
          />
        </>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal" sx={textFieldStyle}>
            <InputLabel id="department-label">Department Name</InputLabel>
            <Select
              labelId="department-label"
              id="department-select"
              name="department"
              value={data.department || ""}
              onChange={handleChange}
              input={<OutlinedInput label="Department Name" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="">
                <em>Select Department</em>
              </MenuItem>
              {departmentData.map((department) => (
                <MenuItem key={department.id} value={department.fieldValue}>
                  {department.fieldValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal" sx={textFieldStyle}>
            <InputLabel id="designation-label">Designation</InputLabel>
            <Select
              labelId="designation-label"
              id="designation-select"
              name="designation"
              value={data.designation || ""}
              onChange={handleChange}
              input={<OutlinedInput label="Designation" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="">
                <em>Select Designation</em>
              </MenuItem>
              {designationData.map((designation) => (
                <MenuItem key={designation.id} value={designation.fieldValue}>
                  {designation.fieldValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal" sx={textFieldStyle}>
            <InputLabel id="assignedDesignation-label">
              Assigned Designation
            </InputLabel>
            <Select
              labelId="assignedDesignation-label"
              id="assignedDesignation-select"
              name="assignedDesignation"
              value={data.assignedDesignation || ""}
              onChange={handleChange}
              input={<OutlinedInput label="Assigned Designation" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="">
                <em>Select Assigned Designation</em>
              </MenuItem>
              {assignedDesignationData.map((assignedDesignation) => (
                <MenuItem
                  key={assignedDesignation.id}
                  value={assignedDesignation.fieldValue}
                >
                  {assignedDesignation.fieldValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "450px",
          padding: "15px",
          gap: "20px",
        }}
      >
        <GeneralButton {...cancelBtnProps} />
        <GeneralButton {...submitBtnProps} />
      </Box>
    </Box>
  );
};

export default EmployeeOtherDetails;
