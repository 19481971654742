import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import arrowImage from "../../assets/images/arrow.svg";
import { hover } from "@testing-library/user-event/dist/hover";

const ScrollButtonTopAndDown = ({ upId, downId, isScrolledToBottom }) => {
  const [activeUp, setActiveUp] = useState(false);
  const [activeDown, setActiveDown] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  // console.log("isScrolled------->123 " + isScrolledToBottom);

  const styles = {
    image: {
      border: isFocused ? "2px solid blue" : "2px solid transparent",
      outline: "none",
    },
  };

  useEffect(() => {
    if (isScrolledToBottom) {
      setActiveUp(true);
      setActiveDown(false);
    } else {
      setActiveUp(false);
      setActiveDown(true);
    }
  }, [isScrolledToBottom]);

  return (
    <Box sx={{ display: "flex" }}>
      {activeDown ? (
        <Box
          sx={{
            position: "static",
          }}
        >
          <IconButton>
            <img
              src={arrowImage}
              height={`45px`}
              onClick={() => {
                document.getElementById(downId).scrollIntoView({
                  behavior: "smooth",
                });
                setActiveUp(!activeUp);
                setActiveDown(!activeDown);
              }}
              alt="arrowDown"
            />
          </IconButton>
        </Box>
      ) : null}
      {activeUp ? (
        <Box
          sx={{
            position: "static",
            transform: "rotate(180deg)",
          }}
        >
          <IconButton>
            <img
              src={arrowImage}
              onClick={() => {
                document.getElementById(upId).scrollIntoView({
                  behavior: "smooth",
                });
                setActiveDown(!activeDown);
                setActiveUp(!activeUp);
              }}
              height={`45px`}
              alt="arrowDown"
            />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default ScrollButtonTopAndDown;
