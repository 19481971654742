import * as React from "react";
import TextField from "@mui/material/TextField";
import { category_wise } from "../../constants/data";
import Dropdown from "../dropdown/dropdown";
import "../projectDetails/projectDetails.css";
import { Box } from "@mui/system";
// import { Typography } from "antd";

const ExpenditureForm = ({
  showExpForm,
  handleClose,
  setExtraExpDetails,
  setOtherExpenses,
  otherExpenses,
  selectCategory,
  setSelectCategory,
  handleSelectCategory,
}) => {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  
  const [selectCategoryOption, setSelectedCategoryOptions] = React.useState([]);
  const handleSelectCategoryfuction = (e, newValue) => {
    setSelectedCategoryOptions(newValue);
  };
  // console.log(selectCategoryOption);

  return (
    <>
      <Box
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
            width: "24%",
          },
        }}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            event.stopPropagation();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const amount = formJson.amount;
            const name = formJson?.expenditureName
              ? formJson?.expenditureName
              : null;

            const expName = `${selectCategory} ${name ? ` / ${name}` : ""}`;

            setExtraExpDetails((prev) => ({
              id: prev?.id + 1,
              expenditureName: expName,
              amount: amount,
            }));

            setSelectCategory("");
            handleClose();
          },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <div className="field_container">
            <Dropdown
              data={category_wise}
              setSelectCategory={selectCategoryOption}
              selectCategory={selectCategoryOption}
              handleSelectCategory={handleSelectCategoryfuction}
              sx={{
                ml: 5,
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  borderRadius: "15px",
                },
              }}
            />
          </div>
          <TextField
            margin="dense"
            id="expenditureName"
            name="expenditureName"
            label="Name"
            type="text"
            variant="outlined"
            sx={{
              ml: 5,
              width: "400px",
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "15px",
              },
            }}
          />
          <TextField
            // autoFocus
            required
            margin="dense"
            id="amount"
            name="amount"
            label="amount"
            type="number"
            variant="outlined"
            sx={{
              ml: 5,
              width: "400px",
              "& .MuiInputBase-root": {
                borderRadius: "15px",
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ExpenditureForm;
