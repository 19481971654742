export const BUTTON_COLOUR = "#6366F1";
export const ARRND = "ARRnd";

//BASE URL
export const BASIC_API_URL = "http://13.60.84.80:8080";

export const USER_PROFILE = "/users/profile";

// AUTH
export const SIGNIN_ENDPOINT = "/users/login";
export const RESET_PASSWORD = "/users/resetPassword";

// DROP-DOWNS
export const GENDER = "/users/details/4";
export const DEPARTMENT = "/users/details/5";
export const DESIGNATION = "/users/details/6";
export const AssignedDesignation = "/users/details/7"; //HERE WE HAVE TO PASS THIS fieldCode ID
export const GET_CLIENTS = `${BASIC_API_URL}/clients`;

// EMPLOYEE
export const EMPLOYEE = "/users/user";

// PROJECT
export const CREATE_FINANCIAL = "/projects/financial";
export const GET_PROJECT_BY_ID = `${BASIC_API_URL}/projects/`;
export const SAVE_CONSULTANTS = `${BASIC_API_URL}/consultants/update/`
export const UPDATE_PROJECT = `${BASIC_API_URL}/projects/`;

export const GET_FINANCIAL_BY_ID = `/projects/financial/`;
export const GET_PROJECT_STATUS = `${BASIC_API_URL}/projects/`
export const STATUS = `/status`

//GET ALL PROJECT
export const PROJECT = "/projects";

export const PROJECT_LIST_FOR_PI = "/projects/projectListByPi"


//PROJECT APPROVAL

export const PROJECT_APPROVAL ="/projects/approve/"

//PROJECT REJECT
export const PROJECT_REJECT ="/projects/reject/"
export const DELETE_PROJECT ="/projects/"

//FILES 
export const DOWNLOAD_FILES =`${BASIC_API_URL}/api/files/download/`



export const LOGIN_ROLES = [
  "register",
  "arrnd",
  "drrnd",
  "associatedeanrnnd",
  "director",
];

export const APPROVING_ROLES = [
  "arrnd",
  "drrnd",
  "associatedeanrnnd",
  "director",
];
export const DELETE_ROLES = ["register", "associatedeanrnnd"];

export const REGISTER_CRED = {
  email: "sales@areteminds.com",
  password: "Sales@123",
};

export const ARND_CRED = { email: "arnd@areteminds.com", password: "Arnd@123" };

export const DRND_CRED = { email: "drnd@areteminds.com", password: "Drnd@123" };

export const DEAN_CRED = { email: "dean@areteminds.com", password: "Dean@123" };

export const DIRECTOR_CRED = {
  email: "director@areteminds.com",
  password: "Director@123",
};
